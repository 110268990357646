$ng-select-highlight: $white;
$ng-select-primary-text: $body-color;
$ng-select-disabled-text: $white;
$ng-select-border: $border-color;
$ng-select-border-radius: $border-radius;
$ng-select-bg: $white;
$ng-select-selected: tint-color($primary, 95);
$ng-select-marked: tint-color($primary, 95);
$ng-select-box-shadow: none;
$ng-select-placeholder: tint-color($ng-select-primary-text, 46);
$ng-select-height: $input-height;
$ng-select-value-padding-left: $form-select-padding-x * 0.5;
$ng-select-value-font-size: $form-select-font-size;

@import "node_modules/@ng-select/ng-select/scss/default.theme";

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: initial;
}
.ng-select:not(.ng-select-multiple) .ng-value { width: 100%; }

.ng-select .ng-select-container { color: $body-color; }
.ng-select.ng-select-multiple .ng-select-container { color: $primary; }
.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container { border-color: $border-color; }

.is-invalid {
  .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border-color: map_get(map_get($form-validation-states, invalid), color);
  }

  .ng-select .ng-select-container {
    border-color: map_get(map_get($form-validation-states, invalid), color);

    padding-right: $input-height-inner;
    background-image: escape-svg(map_get(map_get($form-validation-states, invalid), icon));
    background-repeat: no-repeat;
    background-position: right $input-height-inner-quarter center;
    background-size: $input-height-inner-half $input-height-inner-half;

    &:focus {
      border-color: map_get(map_get($form_validation-states, invalid), color);
      box-shadow: 0 0 0 $input-focus-width rgba(map_get(map_get($form_validation-states, invalid), color), .25);
    }
  }
}

.input-group > .ng-select:not(:last-child) .ng-select-container,
.input-group *:not(:last-child) > .ng-select .ng-select-container
{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .ng-select:not(:first-child) .ng-select-container,
.input-group *:not(:first-child) > .ng-select .ng-select-container {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ng-select {
    display: flex;
    flex-grow: 1;
    .ng-select-container {
        min-width: 100px;
        width: auto;
    }

    .ng-dropdown-panel {
        min-width: 100%;
        width: auto;
    }
}

@each $color-name, $color-value in $theme-colors {
  .modal-#{$color-name} {
    .ng-select-container,
    .ng-select.ng-select-opened > .ng-select-container,
    .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container,
    .ng-dropdown-panel {
      border-color: $color-value
    }
  }
}

@media all and (max-width: $sm) {
    .ng-value-container {
        .ng-value {
            white-space: normal !important;
            max-width: 100%;
            height: 25px;
        }
    }
}

select-article .ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 440px;
}
