.progress {
  &__section {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__indic {
    color: #cccccc;
    top: 0;
    width: 1px;
    height: 130%;
    background-color: #cccccc;
  }

  &__value {
    top: 150%;
    left: 0;
    transform: translateX(-50%);
    font-size: 1rem;
  }

  &__text {
    padding-top: 0.2em;
  }
}

ngb-progressbar-stacked ngb-progressbar:last-child .progress-bar {
    border-bottom-right-radius: $progress-border-radius;
    border-top-right-radius: $progress-border-radius;
}
