.btn {
  font-weight: 700;
  text-transform: uppercase;
  &:focus, .focus {
    box-shadow: none;
  }
}

.btn-secondary {
  @include button-variant($gray-200, $gray-200);
}
