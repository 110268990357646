@each $color-name, $color-value in $theme-colors {
  .modal-#{$color-name} {

    .modal-header {
      color: $color-value;
      border-left: 5px solid $color-value;
      border-right: 5px solid $color-value;
      border-top: 5px solid $color-value;
      border-bottom-color: $color-value;
    }

    .modal-body {
      border-left: 5px solid $color-value;
      border-right: 5px solid $color-value;
    }

    .modal-footer {
      border-top-color: $color-value;
      border-left: 5px solid $color-value;
      border-right: 5px solid $color-value;
      border-bottom: 5px solid $color-value;
    }

    .form-file label,
    input {
      border-color: $color-value;
    }

    .input-group-text {
      border-color: $color-value;
    }

    .btn {
      border-color: $color-value;
    }
  }
}
