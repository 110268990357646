@charset "UTF-8";
/*!
 * Font Awesome Pro 6.7.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700&display=swap");
:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../webfonts/fa-regular-400.woff2") format("woff2"), url("../webfonts/fa-regular-400.ttf") format("truetype");
}
.far,
.fa-regular {
  font-weight: 400;
}

/*!
 * Font Awesome Pro 6.7.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../webfonts/fa-solid-900.woff2") format("woff2"), url("../webfonts/fa-solid-900.ttf") format("truetype");
}
.fas,
.fa-solid {
  font-weight: 900;
}

/*!
 * Font Awesome Pro 6.7.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-brands: "Font Awesome 6 Brands";
  --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
}

@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../webfonts/fa-brands-400.woff2") format("woff2"), url("../webfonts/fa-brands-400.ttf") format("truetype");
}
.fab,
.fa-brands {
  font-weight: 400;
}

.fa-monero {
  --fa: "";
}

.fa-hooli {
  --fa: "";
}

.fa-yelp {
  --fa: "";
}

.fa-cc-visa {
  --fa: "";
}

.fa-lastfm {
  --fa: "";
}

.fa-shopware {
  --fa: "";
}

.fa-creative-commons-nc {
  --fa: "";
}

.fa-aws {
  --fa: "";
}

.fa-redhat {
  --fa: "";
}

.fa-yoast {
  --fa: "";
}

.fa-cloudflare {
  --fa: "";
}

.fa-ups {
  --fa: "";
}

.fa-pixiv {
  --fa: "";
}

.fa-wpexplorer {
  --fa: "";
}

.fa-dyalog {
  --fa: "";
}

.fa-bity {
  --fa: "";
}

.fa-stackpath {
  --fa: "";
}

.fa-buysellads {
  --fa: "";
}

.fa-first-order {
  --fa: "";
}

.fa-modx {
  --fa: "";
}

.fa-guilded {
  --fa: "";
}

.fa-vnv {
  --fa: "";
}

.fa-square-js {
  --fa: "";
}

.fa-js-square {
  --fa: "";
}

.fa-microsoft {
  --fa: "";
}

.fa-qq {
  --fa: "";
}

.fa-orcid {
  --fa: "";
}

.fa-java {
  --fa: "";
}

.fa-invision {
  --fa: "";
}

.fa-creative-commons-pd-alt {
  --fa: "";
}

.fa-centercode {
  --fa: "";
}

.fa-glide-g {
  --fa: "";
}

.fa-drupal {
  --fa: "";
}

.fa-jxl {
  --fa: "";
}

.fa-dart-lang {
  --fa: "";
}

.fa-hire-a-helper {
  --fa: "";
}

.fa-creative-commons-by {
  --fa: "";
}

.fa-unity {
  --fa: "";
}

.fa-whmcs {
  --fa: "";
}

.fa-rocketchat {
  --fa: "";
}

.fa-vk {
  --fa: "";
}

.fa-untappd {
  --fa: "";
}

.fa-mailchimp {
  --fa: "";
}

.fa-css3-alt {
  --fa: "";
}

.fa-square-reddit {
  --fa: "";
}

.fa-reddit-square {
  --fa: "";
}

.fa-vimeo-v {
  --fa: "";
}

.fa-contao {
  --fa: "";
}

.fa-square-font-awesome {
  --fa: "";
}

.fa-deskpro {
  --fa: "";
}

.fa-brave {
  --fa: "";
}

.fa-sistrix {
  --fa: "";
}

.fa-square-instagram {
  --fa: "";
}

.fa-instagram-square {
  --fa: "";
}

.fa-battle-net {
  --fa: "";
}

.fa-the-red-yeti {
  --fa: "";
}

.fa-square-hacker-news {
  --fa: "";
}

.fa-hacker-news-square {
  --fa: "";
}

.fa-edge {
  --fa: "";
}

.fa-threads {
  --fa: "";
}

.fa-napster {
  --fa: "";
}

.fa-square-snapchat {
  --fa: "";
}

.fa-snapchat-square {
  --fa: "";
}

.fa-google-plus-g {
  --fa: "";
}

.fa-artstation {
  --fa: "";
}

.fa-markdown {
  --fa: "";
}

.fa-sourcetree {
  --fa: "";
}

.fa-google-plus {
  --fa: "";
}

.fa-diaspora {
  --fa: "";
}

.fa-foursquare {
  --fa: "";
}

.fa-stack-overflow {
  --fa: "";
}

.fa-github-alt {
  --fa: "";
}

.fa-phoenix-squadron {
  --fa: "";
}

.fa-pagelines {
  --fa: "";
}

.fa-algolia {
  --fa: "";
}

.fa-red-river {
  --fa: "";
}

.fa-creative-commons-sa {
  --fa: "";
}

.fa-safari {
  --fa: "";
}

.fa-google {
  --fa: "";
}

.fa-square-font-awesome-stroke {
  --fa: "";
}

.fa-font-awesome-alt {
  --fa: "";
}

.fa-atlassian {
  --fa: "";
}

.fa-linkedin-in {
  --fa: "";
}

.fa-digital-ocean {
  --fa: "";
}

.fa-nimblr {
  --fa: "";
}

.fa-chromecast {
  --fa: "";
}

.fa-evernote {
  --fa: "";
}

.fa-hacker-news {
  --fa: "";
}

.fa-creative-commons-sampling {
  --fa: "";
}

.fa-adversal {
  --fa: "";
}

.fa-creative-commons {
  --fa: "";
}

.fa-watchman-monitoring {
  --fa: "";
}

.fa-fonticons {
  --fa: "";
}

.fa-weixin {
  --fa: "";
}

.fa-shirtsinbulk {
  --fa: "";
}

.fa-codepen {
  --fa: "";
}

.fa-git-alt {
  --fa: "";
}

.fa-lyft {
  --fa: "";
}

.fa-rev {
  --fa: "";
}

.fa-windows {
  --fa: "";
}

.fa-wizards-of-the-coast {
  --fa: "";
}

.fa-square-viadeo {
  --fa: "";
}

.fa-viadeo-square {
  --fa: "";
}

.fa-meetup {
  --fa: "";
}

.fa-centos {
  --fa: "";
}

.fa-adn {
  --fa: "";
}

.fa-cloudsmith {
  --fa: "";
}

.fa-opensuse {
  --fa: "";
}

.fa-pied-piper-alt {
  --fa: "";
}

.fa-square-dribbble {
  --fa: "";
}

.fa-dribbble-square {
  --fa: "";
}

.fa-codiepie {
  --fa: "";
}

.fa-node {
  --fa: "";
}

.fa-mix {
  --fa: "";
}

.fa-steam {
  --fa: "";
}

.fa-cc-apple-pay {
  --fa: "";
}

.fa-scribd {
  --fa: "";
}

.fa-debian {
  --fa: "";
}

.fa-openid {
  --fa: "";
}

.fa-instalod {
  --fa: "";
}

.fa-files-pinwheel {
  --fa: "";
}

.fa-expeditedssl {
  --fa: "";
}

.fa-sellcast {
  --fa: "";
}

.fa-square-twitter {
  --fa: "";
}

.fa-twitter-square {
  --fa: "";
}

.fa-r-project {
  --fa: "";
}

.fa-delicious {
  --fa: "";
}

.fa-freebsd {
  --fa: "";
}

.fa-vuejs {
  --fa: "";
}

.fa-accusoft {
  --fa: "";
}

.fa-ioxhost {
  --fa: "";
}

.fa-fonticons-fi {
  --fa: "";
}

.fa-app-store {
  --fa: "";
}

.fa-cc-mastercard {
  --fa: "";
}

.fa-itunes-note {
  --fa: "";
}

.fa-golang {
  --fa: "";
}

.fa-kickstarter {
  --fa: "";
}

.fa-square-kickstarter {
  --fa: "";
}

.fa-grav {
  --fa: "";
}

.fa-weibo {
  --fa: "";
}

.fa-uncharted {
  --fa: "";
}

.fa-firstdraft {
  --fa: "";
}

.fa-square-youtube {
  --fa: "";
}

.fa-youtube-square {
  --fa: "";
}

.fa-wikipedia-w {
  --fa: "";
}

.fa-wpressr {
  --fa: "";
}

.fa-rendact {
  --fa: "";
}

.fa-angellist {
  --fa: "";
}

.fa-galactic-republic {
  --fa: "";
}

.fa-nfc-directional {
  --fa: "";
}

.fa-skype {
  --fa: "";
}

.fa-joget {
  --fa: "";
}

.fa-fedora {
  --fa: "";
}

.fa-stripe-s {
  --fa: "";
}

.fa-meta {
  --fa: "";
}

.fa-laravel {
  --fa: "";
}

.fa-hotjar {
  --fa: "";
}

.fa-bluetooth-b {
  --fa: "";
}

.fa-square-letterboxd {
  --fa: "";
}

.fa-sticker-mule {
  --fa: "";
}

.fa-creative-commons-zero {
  --fa: "";
}

.fa-hips {
  --fa: "";
}

.fa-css {
  --fa: "";
}

.fa-behance {
  --fa: "";
}

.fa-reddit {
  --fa: "";
}

.fa-discord {
  --fa: "";
}

.fa-chrome {
  --fa: "";
}

.fa-app-store-ios {
  --fa: "";
}

.fa-cc-discover {
  --fa: "";
}

.fa-wpbeginner {
  --fa: "";
}

.fa-confluence {
  --fa: "";
}

.fa-shoelace {
  --fa: "";
}

.fa-mdb {
  --fa: "";
}

.fa-dochub {
  --fa: "";
}

.fa-accessible-icon {
  --fa: "";
}

.fa-ebay {
  --fa: "";
}

.fa-amazon {
  --fa: "";
}

.fa-unsplash {
  --fa: "";
}

.fa-yarn {
  --fa: "";
}

.fa-square-steam {
  --fa: "";
}

.fa-steam-square {
  --fa: "";
}

.fa-500px {
  --fa: "";
}

.fa-square-vimeo {
  --fa: "";
}

.fa-vimeo-square {
  --fa: "";
}

.fa-asymmetrik {
  --fa: "";
}

.fa-font-awesome {
  --fa: "";
}

.fa-font-awesome-flag {
  --fa: "";
}

.fa-font-awesome-logo-full {
  --fa: "";
}

.fa-gratipay {
  --fa: "";
}

.fa-apple {
  --fa: "";
}

.fa-hive {
  --fa: "";
}

.fa-gitkraken {
  --fa: "";
}

.fa-keybase {
  --fa: "";
}

.fa-apple-pay {
  --fa: "";
}

.fa-padlet {
  --fa: "";
}

.fa-amazon-pay {
  --fa: "";
}

.fa-square-github {
  --fa: "";
}

.fa-github-square {
  --fa: "";
}

.fa-stumbleupon {
  --fa: "";
}

.fa-fedex {
  --fa: "";
}

.fa-phoenix-framework {
  --fa: "";
}

.fa-shopify {
  --fa: "";
}

.fa-neos {
  --fa: "";
}

.fa-square-threads {
  --fa: "";
}

.fa-hackerrank {
  --fa: "";
}

.fa-researchgate {
  --fa: "";
}

.fa-swift {
  --fa: "";
}

.fa-angular {
  --fa: "";
}

.fa-speakap {
  --fa: "";
}

.fa-angrycreative {
  --fa: "";
}

.fa-y-combinator {
  --fa: "";
}

.fa-empire {
  --fa: "";
}

.fa-envira {
  --fa: "";
}

.fa-google-scholar {
  --fa: "";
}

.fa-square-gitlab {
  --fa: "";
}

.fa-gitlab-square {
  --fa: "";
}

.fa-studiovinari {
  --fa: "";
}

.fa-pied-piper {
  --fa: "";
}

.fa-wordpress {
  --fa: "";
}

.fa-product-hunt {
  --fa: "";
}

.fa-firefox {
  --fa: "";
}

.fa-linode {
  --fa: "";
}

.fa-goodreads {
  --fa: "";
}

.fa-square-odnoklassniki {
  --fa: "";
}

.fa-odnoklassniki-square {
  --fa: "";
}

.fa-jsfiddle {
  --fa: "";
}

.fa-sith {
  --fa: "";
}

.fa-themeisle {
  --fa: "";
}

.fa-page4 {
  --fa: "";
}

.fa-hashnode {
  --fa: "";
}

.fa-react {
  --fa: "";
}

.fa-cc-paypal {
  --fa: "";
}

.fa-squarespace {
  --fa: "";
}

.fa-cc-stripe {
  --fa: "";
}

.fa-creative-commons-share {
  --fa: "";
}

.fa-bitcoin {
  --fa: "";
}

.fa-keycdn {
  --fa: "";
}

.fa-opera {
  --fa: "";
}

.fa-itch-io {
  --fa: "";
}

.fa-umbraco {
  --fa: "";
}

.fa-galactic-senate {
  --fa: "";
}

.fa-ubuntu {
  --fa: "";
}

.fa-draft2digital {
  --fa: "";
}

.fa-stripe {
  --fa: "";
}

.fa-houzz {
  --fa: "";
}

.fa-gg {
  --fa: "";
}

.fa-dhl {
  --fa: "";
}

.fa-square-pinterest {
  --fa: "";
}

.fa-pinterest-square {
  --fa: "";
}

.fa-xing {
  --fa: "";
}

.fa-blackberry {
  --fa: "";
}

.fa-creative-commons-pd {
  --fa: "";
}

.fa-playstation {
  --fa: "";
}

.fa-quinscape {
  --fa: "";
}

.fa-less {
  --fa: "";
}

.fa-blogger-b {
  --fa: "";
}

.fa-opencart {
  --fa: "";
}

.fa-vine {
  --fa: "";
}

.fa-signal-messenger {
  --fa: "";
}

.fa-paypal {
  --fa: "";
}

.fa-gitlab {
  --fa: "";
}

.fa-typo3 {
  --fa: "";
}

.fa-reddit-alien {
  --fa: "";
}

.fa-yahoo {
  --fa: "";
}

.fa-dailymotion {
  --fa: "";
}

.fa-affiliatetheme {
  --fa: "";
}

.fa-pied-piper-pp {
  --fa: "";
}

.fa-bootstrap {
  --fa: "";
}

.fa-odnoklassniki {
  --fa: "";
}

.fa-nfc-symbol {
  --fa: "";
}

.fa-mintbit {
  --fa: "";
}

.fa-ethereum {
  --fa: "";
}

.fa-speaker-deck {
  --fa: "";
}

.fa-creative-commons-nc-eu {
  --fa: "";
}

.fa-patreon {
  --fa: "";
}

.fa-avianex {
  --fa: "";
}

.fa-ello {
  --fa: "";
}

.fa-gofore {
  --fa: "";
}

.fa-bimobject {
  --fa: "";
}

.fa-brave-reverse {
  --fa: "";
}

.fa-facebook-f {
  --fa: "";
}

.fa-square-google-plus {
  --fa: "";
}

.fa-google-plus-square {
  --fa: "";
}

.fa-web-awesome {
  --fa: "";
}

.fa-mandalorian {
  --fa: "";
}

.fa-first-order-alt {
  --fa: "";
}

.fa-osi {
  --fa: "";
}

.fa-google-wallet {
  --fa: "";
}

.fa-d-and-d-beyond {
  --fa: "";
}

.fa-periscope {
  --fa: "";
}

.fa-fulcrum {
  --fa: "";
}

.fa-cloudscale {
  --fa: "";
}

.fa-forumbee {
  --fa: "";
}

.fa-mizuni {
  --fa: "";
}

.fa-schlix {
  --fa: "";
}

.fa-square-xing {
  --fa: "";
}

.fa-xing-square {
  --fa: "";
}

.fa-bandcamp {
  --fa: "";
}

.fa-wpforms {
  --fa: "";
}

.fa-cloudversify {
  --fa: "";
}

.fa-usps {
  --fa: "";
}

.fa-megaport {
  --fa: "";
}

.fa-magento {
  --fa: "";
}

.fa-spotify {
  --fa: "";
}

.fa-optin-monster {
  --fa: "";
}

.fa-fly {
  --fa: "";
}

.fa-square-bluesky {
  --fa: "";
}

.fa-aviato {
  --fa: "";
}

.fa-itunes {
  --fa: "";
}

.fa-cuttlefish {
  --fa: "";
}

.fa-blogger {
  --fa: "";
}

.fa-flickr {
  --fa: "";
}

.fa-viber {
  --fa: "";
}

.fa-soundcloud {
  --fa: "";
}

.fa-digg {
  --fa: "";
}

.fa-tencent-weibo {
  --fa: "";
}

.fa-letterboxd {
  --fa: "";
}

.fa-symfony {
  --fa: "";
}

.fa-maxcdn {
  --fa: "";
}

.fa-etsy {
  --fa: "";
}

.fa-facebook-messenger {
  --fa: "";
}

.fa-audible {
  --fa: "";
}

.fa-think-peaks {
  --fa: "";
}

.fa-bilibili {
  --fa: "";
}

.fa-erlang {
  --fa: "";
}

.fa-x-twitter {
  --fa: "";
}

.fa-cotton-bureau {
  --fa: "";
}

.fa-dashcube {
  --fa: "";
}

.fa-42-group {
  --fa: "";
}

.fa-innosoft {
  --fa: "";
}

.fa-stack-exchange {
  --fa: "";
}

.fa-elementor {
  --fa: "";
}

.fa-square-pied-piper {
  --fa: "";
}

.fa-pied-piper-square {
  --fa: "";
}

.fa-creative-commons-nd {
  --fa: "";
}

.fa-palfed {
  --fa: "";
}

.fa-superpowers {
  --fa: "";
}

.fa-resolving {
  --fa: "";
}

.fa-xbox {
  --fa: "";
}

.fa-square-web-awesome-stroke {
  --fa: "";
}

.fa-searchengin {
  --fa: "";
}

.fa-tiktok {
  --fa: "";
}

.fa-square-facebook {
  --fa: "";
}

.fa-facebook-square {
  --fa: "";
}

.fa-renren {
  --fa: "";
}

.fa-linux {
  --fa: "";
}

.fa-glide {
  --fa: "";
}

.fa-linkedin {
  --fa: "";
}

.fa-hubspot {
  --fa: "";
}

.fa-deploydog {
  --fa: "";
}

.fa-twitch {
  --fa: "";
}

.fa-flutter {
  --fa: "";
}

.fa-ravelry {
  --fa: "";
}

.fa-mixer {
  --fa: "";
}

.fa-square-lastfm {
  --fa: "";
}

.fa-lastfm-square {
  --fa: "";
}

.fa-vimeo {
  --fa: "";
}

.fa-mendeley {
  --fa: "";
}

.fa-uniregistry {
  --fa: "";
}

.fa-figma {
  --fa: "";
}

.fa-creative-commons-remix {
  --fa: "";
}

.fa-cc-amazon-pay {
  --fa: "";
}

.fa-dropbox {
  --fa: "";
}

.fa-instagram {
  --fa: "";
}

.fa-cmplid {
  --fa: "";
}

.fa-upwork {
  --fa: "";
}

.fa-facebook {
  --fa: "";
}

.fa-gripfire {
  --fa: "";
}

.fa-jedi-order {
  --fa: "";
}

.fa-uikit {
  --fa: "";
}

.fa-fort-awesome-alt {
  --fa: "";
}

.fa-phabricator {
  --fa: "";
}

.fa-ussunnah {
  --fa: "";
}

.fa-earlybirds {
  --fa: "";
}

.fa-trade-federation {
  --fa: "";
}

.fa-autoprefixer {
  --fa: "";
}

.fa-whatsapp {
  --fa: "";
}

.fa-square-upwork {
  --fa: "";
}

.fa-slideshare {
  --fa: "";
}

.fa-google-play {
  --fa: "";
}

.fa-viadeo {
  --fa: "";
}

.fa-line {
  --fa: "";
}

.fa-google-drive {
  --fa: "";
}

.fa-servicestack {
  --fa: "";
}

.fa-simplybuilt {
  --fa: "";
}

.fa-bitbucket {
  --fa: "";
}

.fa-imdb {
  --fa: "";
}

.fa-deezer {
  --fa: "";
}

.fa-raspberry-pi {
  --fa: "";
}

.fa-jira {
  --fa: "";
}

.fa-docker {
  --fa: "";
}

.fa-screenpal {
  --fa: "";
}

.fa-bluetooth {
  --fa: "";
}

.fa-gitter {
  --fa: "";
}

.fa-d-and-d {
  --fa: "";
}

.fa-microblog {
  --fa: "";
}

.fa-cc-diners-club {
  --fa: "";
}

.fa-gg-circle {
  --fa: "";
}

.fa-pied-piper-hat {
  --fa: "";
}

.fa-kickstarter-k {
  --fa: "";
}

.fa-yandex {
  --fa: "";
}

.fa-readme {
  --fa: "";
}

.fa-html5 {
  --fa: "";
}

.fa-sellsy {
  --fa: "";
}

.fa-square-web-awesome {
  --fa: "";
}

.fa-sass {
  --fa: "";
}

.fa-wirsindhandwerk {
  --fa: "";
}

.fa-wsh {
  --fa: "";
}

.fa-buromobelexperte {
  --fa: "";
}

.fa-salesforce {
  --fa: "";
}

.fa-octopus-deploy {
  --fa: "";
}

.fa-medapps {
  --fa: "";
}

.fa-ns8 {
  --fa: "";
}

.fa-pinterest-p {
  --fa: "";
}

.fa-apper {
  --fa: "";
}

.fa-fort-awesome {
  --fa: "";
}

.fa-waze {
  --fa: "";
}

.fa-bluesky {
  --fa: "";
}

.fa-cc-jcb {
  --fa: "";
}

.fa-snapchat {
  --fa: "";
}

.fa-snapchat-ghost {
  --fa: "";
}

.fa-fantasy-flight-games {
  --fa: "";
}

.fa-rust {
  --fa: "";
}

.fa-wix {
  --fa: "";
}

.fa-square-behance {
  --fa: "";
}

.fa-behance-square {
  --fa: "";
}

.fa-supple {
  --fa: "";
}

.fa-webflow {
  --fa: "";
}

.fa-rebel {
  --fa: "";
}

.fa-css3 {
  --fa: "";
}

.fa-staylinked {
  --fa: "";
}

.fa-kaggle {
  --fa: "";
}

.fa-space-awesome {
  --fa: "";
}

.fa-deviantart {
  --fa: "";
}

.fa-cpanel {
  --fa: "";
}

.fa-goodreads-g {
  --fa: "";
}

.fa-square-git {
  --fa: "";
}

.fa-git-square {
  --fa: "";
}

.fa-square-tumblr {
  --fa: "";
}

.fa-tumblr-square {
  --fa: "";
}

.fa-trello {
  --fa: "";
}

.fa-creative-commons-nc-jp {
  --fa: "";
}

.fa-get-pocket {
  --fa: "";
}

.fa-perbyte {
  --fa: "";
}

.fa-grunt {
  --fa: "";
}

.fa-weebly {
  --fa: "";
}

.fa-connectdevelop {
  --fa: "";
}

.fa-leanpub {
  --fa: "";
}

.fa-black-tie {
  --fa: "";
}

.fa-themeco {
  --fa: "";
}

.fa-python {
  --fa: "";
}

.fa-android {
  --fa: "";
}

.fa-bots {
  --fa: "";
}

.fa-free-code-camp {
  --fa: "";
}

.fa-hornbill {
  --fa: "";
}

.fa-js {
  --fa: "";
}

.fa-ideal {
  --fa: "";
}

.fa-git {
  --fa: "";
}

.fa-dev {
  --fa: "";
}

.fa-sketch {
  --fa: "";
}

.fa-yandex-international {
  --fa: "";
}

.fa-cc-amex {
  --fa: "";
}

.fa-uber {
  --fa: "";
}

.fa-github {
  --fa: "";
}

.fa-php {
  --fa: "";
}

.fa-alipay {
  --fa: "";
}

.fa-youtube {
  --fa: "";
}

.fa-skyatlas {
  --fa: "";
}

.fa-firefox-browser {
  --fa: "";
}

.fa-replyd {
  --fa: "";
}

.fa-suse {
  --fa: "";
}

.fa-jenkins {
  --fa: "";
}

.fa-twitter {
  --fa: "";
}

.fa-rockrms {
  --fa: "";
}

.fa-pinterest {
  --fa: "";
}

.fa-buffer {
  --fa: "";
}

.fa-npm {
  --fa: "";
}

.fa-yammer {
  --fa: "";
}

.fa-btc {
  --fa: "";
}

.fa-dribbble {
  --fa: "";
}

.fa-stumbleupon-circle {
  --fa: "";
}

.fa-internet-explorer {
  --fa: "";
}

.fa-stubber {
  --fa: "";
}

.fa-telegram {
  --fa: "";
}

.fa-telegram-plane {
  --fa: "";
}

.fa-old-republic {
  --fa: "";
}

.fa-odysee {
  --fa: "";
}

.fa-square-whatsapp {
  --fa: "";
}

.fa-whatsapp-square {
  --fa: "";
}

.fa-node-js {
  --fa: "";
}

.fa-edge-legacy {
  --fa: "";
}

.fa-slack {
  --fa: "";
}

.fa-slack-hash {
  --fa: "";
}

.fa-medrt {
  --fa: "";
}

.fa-usb {
  --fa: "";
}

.fa-tumblr {
  --fa: "";
}

.fa-vaadin {
  --fa: "";
}

.fa-quora {
  --fa: "";
}

.fa-square-x-twitter {
  --fa: "";
}

.fa-reacteurope {
  --fa: "";
}

.fa-medium {
  --fa: "";
}

.fa-medium-m {
  --fa: "";
}

.fa-amilia {
  --fa: "";
}

.fa-mixcloud {
  --fa: "";
}

.fa-flipboard {
  --fa: "";
}

.fa-viacoin {
  --fa: "";
}

.fa-critical-role {
  --fa: "";
}

.fa-sitrox {
  --fa: "";
}

.fa-discourse {
  --fa: "";
}

.fa-joomla {
  --fa: "";
}

.fa-mastodon {
  --fa: "";
}

.fa-airbnb {
  --fa: "";
}

.fa-wolf-pack-battalion {
  --fa: "";
}

.fa-buy-n-large {
  --fa: "";
}

.fa-gulp {
  --fa: "";
}

.fa-creative-commons-sampling-plus {
  --fa: "";
}

.fa-strava {
  --fa: "";
}

.fa-ember {
  --fa: "";
}

.fa-canadian-maple-leaf {
  --fa: "";
}

.fa-teamspeak {
  --fa: "";
}

.fa-pushed {
  --fa: "";
}

.fa-wordpress-simple {
  --fa: "";
}

.fa-nutritionix {
  --fa: "";
}

.fa-wodu {
  --fa: "";
}

.fa-google-pay {
  --fa: "";
}

.fa-intercom {
  --fa: "";
}

.fa-zhihu {
  --fa: "";
}

.fa-korvue {
  --fa: "";
}

.fa-pix {
  --fa: "";
}

.fa-steam-symbol {
  --fa: "";
}

/*!
 * Font Awesome Pro 6.7.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900);
}

.fas,
.fass,
.far,
.fasr,
.fal,
.fasl,
.fat,
.fast,
.fad,
.fadr,
.fadl,
.fadt,
.fasds,
.fasdr,
.fasdl,
.fasdt,
.fab,
.fa-solid,
.fa-regular,
.fa-light,
.fa-thin,
.fa-brands,
.fa-classic,
.fa-duotone,
.fa-sharp,
.fa-sharp-duotone,
.fa {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}

.fas::before,
.fass::before,
.far::before,
.fasr::before,
.fal::before,
.fasl::before,
.fat::before,
.fast::before,
.fad::before,
.fadr::before,
.fadl::before,
.fadt::before,
.fasds::before,
.fasdr::before,
.fasdl::before,
.fasdt::before,
.fab::before,
.fa-solid::before,
.fa-regular::before,
.fa-light::before,
.fa-thin::before,
.fa-brands::before,
.fa-classic::before,
.fa-duotone::before,
.fa-sharp::before,
.fa-sharp-duotone::before,
.fa::before {
  content: var(--fa);
}

.fad::after,
.fa-duotone.fa-solid::after,
.fa-duotone::after,
.fadr::after,
.fa-duotone.fa-regular::after,
.fadl::after,
.fa-duotone.fa-light::after,
.fadt::after,
.fa-duotone.fa-thin::after,
.fasds::after,
.fa-sharp-duotone.fa-solid::after,
.fa-sharp-duotone::after,
.fasdr::after,
.fa-sharp-duotone.fa-regular::after,
.fasdl::after,
.fa-sharp-duotone.fa-light::after,
.fasdt::after,
.fa-sharp-duotone.fa-thin::after {
  content: var(--fa--fa);
}

.fa-classic.fa-duotone {
  font-family: "Font Awesome 6 Duotone";
}

.fass,
.fa-sharp {
  font-weight: 900;
}

.fad,
.fa-duotone {
  font-weight: 900;
}

.fasds,
.fa-sharp-duotone {
  font-weight: 900;
}

.fa-classic,
.fas,
.fa-solid,
.far,
.fa-regular,
.fal,
.fa-light,
.fat,
.fa-thin {
  font-family: "Font Awesome 6 Pro";
}

.fa-duotone,
.fad,
.fadr,
.fadl,
.fadt {
  font-family: "Font Awesome 6 Duotone";
}

.fa-brands,
.fab {
  font-family: "Font Awesome 6 Brands";
}

.fa-sharp,
.fass,
.fasr,
.fasl,
.fast {
  font-family: "Font Awesome 6 Sharp";
}

.fa-sharp-duotone,
.fasds,
.fasdr,
.fasdl,
.fasdt {
  font-family: "Font Awesome 6 Sharp Duotone";
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em;
}

.fa-xs {
  font-size: 0.75em;
  line-height: 0.08333em;
  vertical-align: 0.125em;
}

.fa-sm {
  font-size: 0.875em;
  line-height: 0.07143em;
  vertical-align: 0.05357em;
}

.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em;
}

.fa-xl {
  font-size: 1.5em;
  line-height: 0.04167em;
  vertical-align: -0.125em;
}

.fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(-1 * var(--fa-li-width, 2em));
  position: absolute;
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
}

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, 0.08em);
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, 0.3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, 0.3em);
}

.fa-beat {
  animation-name: fa-beat;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  animation-name: fa-bounce;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
}

.fa-fade {
  animation-name: fa-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-beat-fade {
  animation-name: fa-beat-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-flip {
  animation-name: fa-flip;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  animation-name: fa-shake;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin {
  animation-name: fa-spin;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 2s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse,
.fa-spin-pulse {
  animation-name: fa-spin;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat,
  .fa-bounce,
  .fa-fade,
  .fa-beat-fade,
  .fa-flip,
  .fa-pulse,
  .fa-shake,
  .fa-spin,
  .fa-spin-pulse {
    animation-delay: -1ms;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    transition-delay: 0s;
    transition-duration: 0s;
  }
}
@keyframes fa-beat {
  0%, 90% {
    transform: scale(1);
  }
  45% {
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}
@keyframes fa-bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
  }
  30% {
    transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
  }
  50% {
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4);
  }
}
@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}
@keyframes fa-flip {
  50% {
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}
@keyframes fa-shake {
  0% {
    transform: rotate(-15deg);
  }
  4% {
    transform: rotate(15deg);
  }
  8%, 24% {
    transform: rotate(-18deg);
  }
  12%, 28% {
    transform: rotate(18deg);
  }
  16% {
    transform: rotate(-22deg);
  }
  20% {
    transform: rotate(22deg);
  }
  32% {
    transform: rotate(-12deg);
  }
  36% {
    transform: rotate(12deg);
  }
  40%, 100% {
    transform: rotate(0deg);
  }
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  transform: rotate(90deg);
}

.fa-rotate-180 {
  transform: rotate(180deg);
}

.fa-rotate-270 {
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  transform: scale(1, -1);
}

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1, -1);
}

.fa-rotate-by {
  transform: rotate(var(--fa-rotate-angle, 0));
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: var(--fa-stack-z-index, auto);
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-0 {
  --fa: "0";
  --fa--fa: "00";
}

.fa-1 {
  --fa: "1";
  --fa--fa: "11";
}

.fa-2 {
  --fa: "2";
  --fa--fa: "22";
}

.fa-3 {
  --fa: "3";
  --fa--fa: "33";
}

.fa-4 {
  --fa: "4";
  --fa--fa: "44";
}

.fa-5 {
  --fa: "5";
  --fa--fa: "55";
}

.fa-6 {
  --fa: "6";
  --fa--fa: "66";
}

.fa-7 {
  --fa: "7";
  --fa--fa: "77";
}

.fa-8 {
  --fa: "8";
  --fa--fa: "88";
}

.fa-9 {
  --fa: "9";
  --fa--fa: "99";
}

.fa-fill-drip {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-to-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-chevron-right {
  --fa: "";
  --fa--fa: "";
}

.fa-chevron-circle-right {
  --fa: "";
  --fa--fa: "";
}

.fa-wagon-covered {
  --fa: "";
  --fa--fa: "";
}

.fa-line-height {
  --fa: "";
  --fa--fa: "";
}

.fa-bagel {
  --fa: "";
  --fa--fa: "";
}

.fa-transporter-7 {
  --fa: "";
  --fa--fa: "";
}

.fa-at {
  --fa: "@";
  --fa--fa: "@@";
}

.fa-rectangles-mixed {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-arrow-up-right {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-outgoing {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-can {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-l {
  --fa: "";
  --fa--fa: "";
}

.fa-head-side-goggles {
  --fa: "";
  --fa--fa: "";
}

.fa-head-vr {
  --fa: "";
  --fa--fa: "";
}

.fa-text-height {
  --fa: "";
  --fa--fa: "";
}

.fa-user-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-user-times {
  --fa: "";
  --fa--fa: "";
}

.fa-face-hand-yawn {
  --fa: "";
  --fa--fa: "";
}

.fa-gauge-simple-min {
  --fa: "";
  --fa--fa: "";
}

.fa-tachometer-slowest {
  --fa: "";
  --fa--fa: "";
}

.fa-stethoscope {
  --fa: "";
  --fa--fa: "";
}

.fa-coffin {
  --fa: "";
  --fa--fa: "";
}

.fa-message {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-salad {
  --fa: "";
  --fa--fa: "";
}

.fa-bowl-salad {
  --fa: "";
  --fa--fa: "";
}

.fa-info {
  --fa: "";
  --fa--fa: "";
}

.fa-robot-astromech {
  --fa: "";
  --fa--fa: "";
}

.fa-ring-diamond {
  --fa: "";
  --fa--fa: "";
}

.fa-fondue-pot {
  --fa: "";
  --fa--fa: "";
}

.fa-theta {
  --fa: "";
  --fa--fa: "";
}

.fa-face-hand-peeking {
  --fa: "";
  --fa--fa: "";
}

.fa-square-user {
  --fa: "";
  --fa--fa: "";
}

.fa-down-left-and-up-right-to-center {
  --fa: "";
  --fa--fa: "";
}

.fa-compress-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-explosion {
  --fa: "";
  --fa--fa: "";
}

.fa-file-lines {
  --fa: "";
  --fa--fa: "";
}

.fa-file-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-file-text {
  --fa: "";
  --fa--fa: "";
}

.fa-wave-square {
  --fa: "";
  --fa--fa: "";
}

.fa-ring {
  --fa: "";
  --fa--fa: "";
}

.fa-building-un {
  --fa: "";
  --fa--fa: "";
}

.fa-dice-three {
  --fa: "";
  --fa--fa: "";
}

.fa-tire-pressure-warning {
  --fa: "";
  --fa--fa: "";
}

.fa-wifi-fair {
  --fa: "";
  --fa--fa: "";
}

.fa-wifi-2 {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-days {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-mp3-player {
  --fa: "";
  --fa--fa: "";
}

.fa-anchor-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-tally-4 {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-history {
  --fa: "";
  --fa--fa: "";
}

.fa-building-circle-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-volleyball {
  --fa: "";
  --fa--fa: "";
}

.fa-volleyball-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-sun-haze {
  --fa: "";
  --fa--fa: "";
}

.fa-text-size {
  --fa: "";
  --fa--fa: "";
}

.fa-ufo {
  --fa: "";
  --fa--fa: "";
}

.fa-fork {
  --fa: "";
  --fa--fa: "";
}

.fa-utensil-fork {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-up-to-line {
  --fa: "";
  --fa--fa: "";
}

.fa-mobile-signal {
  --fa: "";
  --fa--fa: "";
}

.fa-barcode-scan {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-down {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-desc {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-download {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-minus-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-face-icicles {
  --fa: "";
  --fa--fa: "";
}

.fa-shovel {
  --fa: "";
  --fa--fa: "";
}

.fa-door-open {
  --fa: "";
  --fa--fa: "";
}

.fa-films {
  --fa: "";
  --fa--fa: "";
}

.fa-right-from-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-sign-out-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-face-glasses {
  --fa: "";
  --fa--fa: "";
}

.fa-nfc {
  --fa: "";
  --fa--fa: "";
}

.fa-atom {
  --fa: "";
  --fa--fa: "";
}

.fa-soap {
  --fa: "";
  --fa--fa: "";
}

.fa-icons {
  --fa: "";
  --fa--fa: "";
}

.fa-heart-music-camera-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-microphone-lines-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-microphone-alt-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-closed-captioning-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-calculator-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-calculator-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-bridge-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-sliders-up {
  --fa: "";
  --fa--fa: "";
}

.fa-sliders-v {
  --fa: "";
  --fa--fa: "";
}

.fa-location-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-map-marker-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-pump-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-fingerprint {
  --fa: "";
  --fa--fa: "";
}

.fa-ski-boot {
  --fa: "";
  --fa--fa: "";
}

.fa-standard-definition {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-sd {
  --fa: "";
  --fa--fa: "";
}

.fa-h1 {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-point-right {
  --fa: "";
  --fa--fa: "";
}

.fa-magnifying-glass-location {
  --fa: "";
  --fa--fa: "";
}

.fa-search-location {
  --fa: "";
  --fa--fa: "";
}

.fa-message-bot {
  --fa: "";
  --fa--fa: "";
}

.fa-forward-step {
  --fa: "";
  --fa--fa: "";
}

.fa-step-forward {
  --fa: "";
  --fa--fa: "";
}

.fa-face-smile-beam {
  --fa: "";
  --fa--fa: "";
}

.fa-smile-beam {
  --fa: "";
  --fa--fa: "";
}

.fa-light-ceiling {
  --fa: "";
  --fa--fa: "";
}

.fa-message-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-alt-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-bowl-scoop {
  --fa: "";
  --fa--fa: "";
}

.fa-bowl-shaved-ice {
  --fa: "";
  --fa--fa: "";
}

.fa-square-x {
  --fa: "";
  --fa--fa: "";
}

.fa-building-memo {
  --fa: "";
  --fa--fa: "";
}

.fa-utility-pole-double {
  --fa: "";
  --fa--fa: "";
}

.fa-flag-checkered {
  --fa: "";
  --fa--fa: "";
}

.fa-chevrons-up {
  --fa: "";
  --fa--fa: "";
}

.fa-chevron-double-up {
  --fa: "";
  --fa--fa: "";
}

.fa-football {
  --fa: "";
  --fa--fa: "";
}

.fa-football-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-user-vneck {
  --fa: "";
  --fa--fa: "";
}

.fa-school-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-crop {
  --fa: "";
  --fa--fa: "";
}

.fa-angles-down {
  --fa: "";
  --fa--fa: "";
}

.fa-angle-double-down {
  --fa: "";
  --fa--fa: "";
}

.fa-users-rectangle {
  --fa: "";
  --fa--fa: "";
}

.fa-people-roof {
  --fa: "";
  --fa--fa: "";
}

.fa-square-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-square-right {
  --fa: "";
  --fa--fa: "";
}

.fa-location-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-map-marker-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-lightbulb-exclamation-on {
  --fa: "";
  --fa--fa: "";
}

.fa-people-line {
  --fa: "";
  --fa--fa: "";
}

.fa-beer-mug-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-beer {
  --fa: "";
  --fa--fa: "";
}

.fa-carpool {
  --fa: "";
  --fa--fa: "";
}

.fa-car-people {
  --fa: "";
  --fa--fa: "";
}

.fa-crate-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-diagram-predecessor {
  --fa: "";
  --fa--fa: "";
}

.fa-transporter {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-circle-user {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-long {
  --fa: "";
  --fa--fa: "";
}

.fa-long-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-person-carry-box {
  --fa: "";
  --fa--fa: "";
}

.fa-person-carry {
  --fa: "";
  --fa--fa: "";
}

.fa-fire-flame-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-burn {
  --fa: "";
  --fa--fa: "";
}

.fa-person {
  --fa: "";
  --fa--fa: "";
}

.fa-male {
  --fa: "";
  --fa--fa: "";
}

.fa-laptop {
  --fa: "";
  --fa--fa: "";
}

.fa-file-csv {
  --fa: "";
  --fa--fa: "";
}

.fa-menorah {
  --fa: "";
  --fa--fa: "";
}

.fa-union {
  --fa: "";
  --fa--fa: "";
}

.fa-chevrons-left {
  --fa: "";
  --fa--fa: "";
}

.fa-chevron-double-left {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-heart-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-plane {
  --fa: "";
  --fa--fa: "";
}

.fa-record-vinyl {
  --fa: "";
  --fa--fa: "";
}

.fa-bring-forward {
  --fa: "";
  --fa--fa: "";
}

.fa-square-p {
  --fa: "";
  --fa--fa: "";
}

.fa-face-grin-stars {
  --fa: "";
  --fa--fa: "";
}

.fa-grin-stars {
  --fa: "";
  --fa--fa: "";
}

.fa-sigma {
  --fa: "";
  --fa--fa: "";
}

.fa-camera-movie {
  --fa: "";
  --fa--fa: "";
}

.fa-bong {
  --fa: "";
  --fa--fa: "";
}

.fa-clarinet {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-flatbed {
  --fa: "";
  --fa--fa: "";
}

.fa-spaghetti-monster-flying {
  --fa: "";
  --fa--fa: "";
}

.fa-pastafarianism {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-up-across-line {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-rotate-reverse {
  --fa: "";
  --fa--fa: "";
}

.fa-leaf-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-house-building {
  --fa: "";
  --fa--fa: "";
}

.fa-cheese-swiss {
  --fa: "";
  --fa--fa: "";
}

.fa-spoon {
  --fa: "";
  --fa--fa: "";
}

.fa-utensil-spoon {
  --fa: "";
  --fa--fa: "";
}

.fa-jar-wheat {
  --fa: "";
  --fa--fa: "";
}

.fa-envelopes-bulk {
  --fa: "";
  --fa--fa: "";
}

.fa-mail-bulk {
  --fa: "";
  --fa--fa: "";
}

.fa-file-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-bow-arrow {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-hexagon-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-times-hexagon {
  --fa: "";
  --fa--fa: "";
}

.fa-xmark-hexagon {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-h {
  --fa: "";
  --fa--fa: "";
}

.fa-hospital-symbol {
  --fa: "";
  --fa--fa: "";
}

.fa-merge {
  --fa: "";
  --fa--fa: "";
}

.fa-pager {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-address-book {
  --fa: "";
  --fa--fa: "";
}

.fa-contact-book {
  --fa: "";
  --fa--fa: "";
}

.fa-pan-frying {
  --fa: "";
  --fa--fa: "";
}

.fa-grid {
  --fa: "";
  --fa--fa: "";
}

.fa-grid-3 {
  --fa: "";
  --fa--fa: "";
}

.fa-football-helmet {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-love {
  --fa: "";
  --fa--fa: "";
}

.fa-trees {
  --fa: "";
  --fa--fa: "";
}

.fa-strikethrough {
  --fa: "";
  --fa--fa: "";
}

.fa-page {
  --fa: "";
  --fa--fa: "";
}

.fa-k {
  --fa: "K";
  --fa--fa: "KK";
}

.fa-diagram-previous {
  --fa: "";
  --fa--fa: "";
}

.fa-gauge-min {
  --fa: "";
  --fa--fa: "";
}

.fa-tachometer-alt-slowest {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-grid {
  --fa: "";
  --fa--fa: "";
}

.fa-eggplant {
  --fa: "";
  --fa--fa: "";
}

.fa-excavator {
  --fa: "";
  --fa--fa: "";
}

.fa-ram {
  --fa: "";
  --fa--fa: "";
}

.fa-landmark-flag {
  --fa: "";
  --fa--fa: "";
}

.fa-lips {
  --fa: "";
  --fa--fa: "";
}

.fa-pencil {
  --fa: "";
  --fa--fa: "";
}

.fa-pencil-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-backward {
  --fa: "";
  --fa--fa: "";
}

.fa-caret-right {
  --fa: "";
  --fa--fa: "";
}

.fa-comments {
  --fa: "";
  --fa--fa: "";
}

.fa-paste {
  --fa: "";
  --fa--fa: "";
}

.fa-file-clipboard {
  --fa: "";
  --fa--fa: "";
}

.fa-desktop-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-code-pull-request {
  --fa: "";
  --fa--fa: "";
}

.fa-pumpkin {
  --fa: "";
  --fa--fa: "";
}

.fa-clipboard-list {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-field {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-sine {
  --fa: "";
  --fa--fa: "";
}

.fa-blueberries {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-ramp-box {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-loading {
  --fa: "";
  --fa--fa: "";
}

.fa-note {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-to-square {
  --fa: "";
  --fa--fa: "";
}

.fa-user-check {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-vial-virus {
  --fa: "";
  --fa--fa: "";
}

.fa-book-blank {
  --fa: "";
  --fa--fa: "";
}

.fa-book-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-golf-flag-hole {
  --fa: "";
  --fa--fa: "";
}

.fa-message-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-alt-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-face-unamused {
  --fa: "";
  --fa--fa: "";
}

.fa-sheet-plastic {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-9 {
  --fa: "";
  --fa--fa: "";
}

.fa-blog {
  --fa: "";
  --fa--fa: "";
}

.fa-user-ninja {
  --fa: "";
  --fa--fa: "";
}

.fa-pencil-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-bowling-pins {
  --fa: "";
  --fa--fa: "";
}

.fa-person-arrow-up-from-line {
  --fa: "";
  --fa--fa: "";
}

.fa-down-right {
  --fa: "";
  --fa--fa: "";
}

.fa-scroll-torah {
  --fa: "";
  --fa--fa: "";
}

.fa-torah {
  --fa: "";
  --fa--fa: "";
}

.fa-webhook {
  --fa: "";
  --fa--fa: "";
}

.fa-blinds-open {
  --fa: "";
  --fa--fa: "";
}

.fa-fence {
  --fa: "";
  --fa--fa: "";
}

.fa-up {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-alt-up {
  --fa: "";
  --fa--fa: "";
}

.fa-broom-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-quidditch {
  --fa: "";
  --fa--fa: "";
}

.fa-quidditch-broom-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-drumstick {
  --fa: "";
  --fa--fa: "";
}

.fa-square-v {
  --fa: "";
  --fa--fa: "";
}

.fa-face-awesome {
  --fa: "";
  --fa--fa: "";
}

.fa-gave-dandy {
  --fa: "";
  --fa--fa: "";
}

.fa-dial-off {
  --fa: "";
  --fa--fa: "";
}

.fa-toggle-off {
  --fa: "";
  --fa--fa: "";
}

.fa-face-smile-horns {
  --fa: "";
  --fa--fa: "";
}

.fa-box-archive {
  --fa: "";
  --fa--fa: "";
}

.fa-archive {
  --fa: "";
  --fa--fa: "";
}

.fa-grapes {
  --fa: "";
  --fa--fa: "";
}

.fa-person-drowning {
  --fa: "";
  --fa--fa: "";
}

.fa-dial-max {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-m {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-image {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-caret-down {
  --fa: "";
  --fa--fa: "";
}

.fa-caret-circle-down {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-9-1 {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-numeric-desc {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-numeric-down-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-face-grin-tongue-squint {
  --fa: "";
  --fa--fa: "";
}

.fa-grin-tongue-squint {
  --fa: "";
  --fa--fa: "";
}

.fa-shish-kebab {
  --fa: "";
  --fa--fa: "";
}

.fa-spray-can {
  --fa: "";
  --fa--fa: "";
}

.fa-alarm-snooze {
  --fa: "";
  --fa--fa: "";
}

.fa-scarecrow {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-monster {
  --fa: "";
  --fa--fa: "";
}

.fa-gift-card {
  --fa: "";
  --fa--fa: "";
}

.fa-w {
  --fa: "W";
  --fa--fa: "WW";
}

.fa-code-pull-request-draft {
  --fa: "";
  --fa--fa: "";
}

.fa-square-b {
  --fa: "";
  --fa--fa: "";
}

.fa-elephant {
  --fa: "";
  --fa--fa: "";
}

.fa-earth-africa {
  --fa: "";
  --fa--fa: "";
}

.fa-globe-africa {
  --fa: "";
  --fa--fa: "";
}

.fa-rainbow {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-notch {
  --fa: "";
  --fa--fa: "";
}

.fa-tablet-screen-button {
  --fa: "";
  --fa--fa: "";
}

.fa-tablet-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-paw {
  --fa: "";
  --fa--fa: "";
}

.fa-message-question {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud {
  --fa: "";
  --fa--fa: "";
}

.fa-trowel-bricks {
  --fa: "";
  --fa--fa: "";
}

.fa-square-3 {
  --fa: "";
  --fa--fa: "";
}

.fa-face-flushed {
  --fa: "";
  --fa--fa: "";
}

.fa-flushed {
  --fa: "";
  --fa--fa: "";
}

.fa-hospital-user {
  --fa: "";
  --fa--fa: "";
}

.fa-microwave {
  --fa: "";
  --fa--fa: "";
}

.fa-chf-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-tent-arrow-left-right {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-circle-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-clock {
  --fa: "";
  --fa--fa: "";
}

.fa-reflect-both {
  --fa: "";
  --fa--fa: "";
}

.fa-gavel {
  --fa: "";
  --fa--fa: "";
}

.fa-legal {
  --fa: "";
  --fa--fa: "";
}

.fa-sprinkler-ceiling {
  --fa: "";
  --fa--fa: "";
}

.fa-browsers {
  --fa: "";
  --fa--fa: "";
}

.fa-trillium {
  --fa: "";
  --fa--fa: "";
}

.fa-table-cells-unlock {
  --fa: "";
  --fa--fa: "";
}

.fa-music-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-ramp {
  --fa: "";
  --fa--fa: "";
}

.fa-binoculars {
  --fa: "";
  --fa--fa: "";
}

.fa-microphone-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-box-tissue {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-c {
  --fa: "";
  --fa--fa: "";
}

.fa-star-christmas {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-bullet {
  --fa: "";
  --fa--fa: "";
}

.fa-motorcycle {
  --fa: "";
  --fa--fa: "";
}

.fa-tree-christmas {
  --fa: "";
  --fa--fa: "";
}

.fa-tire-flat {
  --fa: "";
  --fa--fa: "";
}

.fa-sunglasses {
  --fa: "";
  --fa--fa: "";
}

.fa-badge {
  --fa: "";
  --fa--fa: "";
}

.fa-message-pen {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-alt-edit {
  --fa: "";
  --fa--fa: "";
}

.fa-message-edit {
  --fa: "";
  --fa--fa: "";
}

.fa-bell-concierge {
  --fa: "";
  --fa--fa: "";
}

.fa-concierge-bell {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-ruler {
  --fa: "";
  --fa--fa: "";
}

.fa-pencil-ruler {
  --fa: "";
  --fa--fa: "";
}

.fa-file-mp3 {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-progress {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-rook-piece {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-rook-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-square-root {
  --fa: "";
  --fa--fa: "";
}

.fa-album-collection-circle-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-people-arrows {
  --fa: "";
  --fa--fa: "";
}

.fa-people-arrows-left-right {
  --fa: "";
  --fa--fa: "";
}

.fa-sign-post {
  --fa: "";
  --fa--fa: "";
}

.fa-face-angry-horns {
  --fa: "";
  --fa--fa: "";
}

.fa-mars-and-venus-burst {
  --fa: "";
  --fa--fa: "";
}

.fa-tombstone {
  --fa: "";
  --fa--fa: "";
}

.fa-square-caret-right {
  --fa: "";
  --fa--fa: "";
}

.fa-caret-square-right {
  --fa: "";
  --fa--fa: "";
}

.fa-scissors {
  --fa: "";
  --fa--fa: "";
}

.fa-cut {
  --fa: "";
  --fa--fa: "";
}

.fa-list-music {
  --fa: "";
  --fa--fa: "";
}

.fa-sun-plant-wilt {
  --fa: "";
  --fa--fa: "";
}

.fa-toilets-portable {
  --fa: "";
  --fa--fa: "";
}

.fa-hockey-puck {
  --fa: "";
  --fa--fa: "";
}

.fa-mustache {
  --fa: "";
  --fa--fa: "";
}

.fa-hyphen {
  --fa: "-";
  --fa--fa: "--";
}

.fa-table {
  --fa: "";
  --fa--fa: "";
}

.fa-user-chef {
  --fa: "";
  --fa--fa: "";
}

.fa-message-image {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-alt-image {
  --fa: "";
  --fa--fa: "";
}

.fa-users-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-sensor-triangle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-sensor-alert {
  --fa: "";
  --fa--fa: "";
}

.fa-magnifying-glass-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-tachograph-digital {
  --fa: "";
  --fa--fa: "";
}

.fa-digital-tachograph {
  --fa: "";
  --fa--fa: "";
}

.fa-face-mask {
  --fa: "";
  --fa--fa: "";
}

.fa-pickleball {
  --fa: "";
  --fa--fa: "";
}

.fa-star-sharp-half {
  --fa: "";
  --fa--fa: "";
}

.fa-users-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-clover {
  --fa: "";
  --fa--fa: "";
}

.fa-meat {
  --fa: "";
  --fa--fa: "";
}

.fa-reply {
  --fa: "";
  --fa--fa: "";
}

.fa-mail-reply {
  --fa: "";
  --fa--fa: "";
}

.fa-star-and-crescent {
  --fa: "";
  --fa--fa: "";
}

.fa-empty-set {
  --fa: "";
  --fa--fa: "";
}

.fa-house-fire {
  --fa: "";
  --fa--fa: "";
}

.fa-square-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-minus-square {
  --fa: "";
  --fa--fa: "";
}

.fa-helicopter {
  --fa: "";
  --fa--fa: "";
}

.fa-bird {
  --fa: "";
  --fa--fa: "";
}

.fa-compass {
  --fa: "";
  --fa--fa: "";
}

.fa-square-caret-down {
  --fa: "";
  --fa--fa: "";
}

.fa-caret-square-down {
  --fa: "";
  --fa--fa: "";
}

.fa-heart-half-stroke {
  --fa: "";
  --fa--fa: "";
}

.fa-heart-half-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-file-circle-question {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-utensils {
  --fa: "";
  --fa--fa: "";
}

.fa-laptop-code {
  --fa: "";
  --fa--fa: "";
}

.fa-joystick {
  --fa: "";
  --fa--fa: "";
}

.fa-grill-fire {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-vertical-history {
  --fa: "";
  --fa--fa: "";
}

.fa-swatchbook {
  --fa: "";
  --fa--fa: "";
}

.fa-prescription-bottle {
  --fa: "";
  --fa--fa: "";
}

.fa-bars {
  --fa: "";
  --fa--fa: "";
}

.fa-navicon {
  --fa: "";
  --fa--fa: "";
}

.fa-keyboard-left {
  --fa: "";
  --fa--fa: "";
}

.fa-people-group {
  --fa: "";
  --fa--fa: "";
}

.fa-hourglass-end {
  --fa: "";
  --fa--fa: "";
}

.fa-hourglass-3 {
  --fa: "";
  --fa--fa: "";
}

.fa-heart-crack {
  --fa: "";
  --fa--fa: "";
}

.fa-heart-broken {
  --fa: "";
  --fa--fa: "";
}

.fa-face-beam-hand-over-mouth {
  --fa: "";
  --fa--fa: "";
}

.fa-droplet-percent {
  --fa: "";
  --fa--fa: "";
}

.fa-humidity {
  --fa: "";
  --fa--fa: "";
}

.fa-square-up-right {
  --fa: "";
  --fa--fa: "";
}

.fa-external-link-square-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-face-kiss-beam {
  --fa: "";
  --fa--fa: "";
}

.fa-kiss-beam {
  --fa: "";
  --fa--fa: "";
}

.fa-corn {
  --fa: "";
  --fa--fa: "";
}

.fa-roller-coaster {
  --fa: "";
  --fa--fa: "";
}

.fa-photo-film-music {
  --fa: "";
  --fa--fa: "";
}

.fa-radar {
  --fa: "";
  --fa--fa: "";
}

.fa-sickle {
  --fa: "";
  --fa--fa: "";
}

.fa-film {
  --fa: "";
  --fa--fa: "";
}

.fa-coconut {
  --fa: "";
  --fa--fa: "";
}

.fa-ruler-horizontal {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-cross {
  --fa: "";
  --fa--fa: "";
}

.fa-cassette-tape {
  --fa: "";
  --fa--fa: "";
}

.fa-square-terminal {
  --fa: "";
  --fa--fa: "";
}

.fa-people-robbery {
  --fa: "";
  --fa--fa: "";
}

.fa-lightbulb {
  --fa: "";
  --fa--fa: "";
}

.fa-caret-left {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-middle {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-can-list {
  --fa: "";
  --fa--fa: "";
}

.fa-block {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-exclamation-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-school-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-right-from-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-sign-out {
  --fa: "";
  --fa--fa: "";
}

.fa-face-frown-slight {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-chevron-down {
  --fa: "";
  --fa--fa: "";
}

.fa-chevron-circle-down {
  --fa: "";
  --fa--fa: "";
}

.fa-sidebar-flip {
  --fa: "";
  --fa--fa: "";
}

.fa-unlock-keyhole {
  --fa: "";
  --fa--fa: "";
}

.fa-unlock-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-list {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-showers-heavy {
  --fa: "";
  --fa--fa: "";
}

.fa-headphones-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-headphones-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-sitemap {
  --fa: "";
  --fa--fa: "";
}

.fa-pipe-section {
  --fa: "";
  --fa--fa: "";
}

.fa-space-station-moon-construction {
  --fa: "";
  --fa--fa: "";
}

.fa-space-station-moon-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-dollar-to-slot {
  --fa: "";
  --fa--fa: "";
}

.fa-donate {
  --fa: "";
  --fa--fa: "";
}

.fa-memory {
  --fa: "";
  --fa--fa: "";
}

.fa-face-sleeping {
  --fa: "";
  --fa--fa: "";
}

.fa-road-spikes {
  --fa: "";
  --fa--fa: "";
}

.fa-fire-burner {
  --fa: "";
  --fa--fa: "";
}

.fa-squirrel {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-to-line {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-to-top {
  --fa: "";
  --fa--fa: "";
}

.fa-flag {
  --fa: "";
  --fa--fa: "";
}

.fa-face-cowboy-hat {
  --fa: "";
  --fa--fa: "";
}

.fa-hanukiah {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-scatter-3d {
  --fa: "";
  --fa--fa: "";
}

.fa-display-chart-up {
  --fa: "";
  --fa--fa: "";
}

.fa-square-code {
  --fa: "";
  --fa--fa: "";
}

.fa-feather {
  --fa: "";
  --fa--fa: "";
}

.fa-volume-low {
  --fa: "";
  --fa--fa: "";
}

.fa-volume-down {
  --fa: "";
  --fa--fa: "";
}

.fa-xmark-to-slot {
  --fa: "";
  --fa--fa: "";
}

.fa-times-to-slot {
  --fa: "";
  --fa--fa: "";
}

.fa-vote-nay {
  --fa: "";
  --fa--fa: "";
}

.fa-box-taped {
  --fa: "";
  --fa--fa: "";
}

.fa-box-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-swords {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-sun-rain {
  --fa: "";
  --fa--fa: "";
}

.fa-album {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-n {
  --fa: "";
  --fa--fa: "";
}

.fa-compress {
  --fa: "";
  --fa--fa: "";
}

.fa-wheat-awn {
  --fa: "";
  --fa--fa: "";
}

.fa-wheat-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-ankh {
  --fa: "";
  --fa--fa: "";
}

.fa-hands-holding-child {
  --fa: "";
  --fa--fa: "";
}

.fa-asterisk {
  --fa: "*";
  --fa--fa: "**";
}

.fa-key-skeleton-left-right {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-lines {
  --fa: "";
  --fa--fa: "";
}

.fa-luchador-mask {
  --fa: "";
  --fa--fa: "";
}

.fa-luchador {
  --fa: "";
  --fa--fa: "";
}

.fa-mask-luchador {
  --fa: "";
  --fa--fa: "";
}

.fa-square-check {
  --fa: "";
  --fa--fa: "";
}

.fa-check-square {
  --fa: "";
  --fa--fa: "";
}

.fa-shredder {
  --fa: "";
  --fa--fa: "";
}

.fa-book-open-cover {
  --fa: "";
  --fa--fa: "";
}

.fa-book-open-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-sandwich {
  --fa: "";
  --fa--fa: "";
}

.fa-peseta-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-square-parking-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-parking-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-train-tunnel {
  --fa: "";
  --fa--fa: "";
}

.fa-heading {
  --fa: "";
  --fa--fa: "";
}

.fa-header {
  --fa: "";
  --fa--fa: "";
}

.fa-ghost {
  --fa: "";
  --fa--fa: "";
}

.fa-face-anguished {
  --fa: "";
  --fa--fa: "";
}

.fa-hockey-sticks {
  --fa: "";
  --fa--fa: "";
}

.fa-abacus {
  --fa: "";
  --fa--fa: "";
}

.fa-film-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-film-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-list {
  --fa: "";
  --fa--fa: "";
}

.fa-list-squares {
  --fa: "";
  --fa--fa: "";
}

.fa-tree-palm {
  --fa: "";
  --fa--fa: "";
}

.fa-square-phone-flip {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-square-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-user-beard-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-gamepad {
  --fa: "";
  --fa--fa: "";
}

.fa-border-center-v {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-dot {
  --fa: "";
  --fa--fa: "";
}

.fa-dot-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-clipboard-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-face-dizzy {
  --fa: "";
  --fa--fa: "";
}

.fa-dizzy {
  --fa: "";
  --fa--fa: "";
}

.fa-egg {
  --fa: "";
  --fa--fa: "";
}

.fa-up-to-line {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-alt-to-top {
  --fa: "";
  --fa--fa: "";
}

.fa-house-medical-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-watch-fitness {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-nine-thirty {
  --fa: "";
  --fa--fa: "";
}

.fa-campground {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-jug {
  --fa: "";
  --fa--fa: "";
}

.fa-futbol {
  --fa: "";
  --fa--fa: "";
}

.fa-futbol-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-soccer-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-snow-blowing {
  --fa: "";
  --fa--fa: "";
}

.fa-paintbrush {
  --fa: "";
  --fa--fa: "";
}

.fa-paint-brush {
  --fa: "";
  --fa--fa: "";
}

.fa-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-from-line {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-from-top {
  --fa: "";
  --fa--fa: "";
}

.fa-gas-pump {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-bars-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-alt-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-monkey {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-pro {
  --fa: "";
  --fa--fa: "";
}

.fa-pro {
  --fa: "";
  --fa--fa: "";
}

.fa-house-night {
  --fa: "";
  --fa--fa: "";
}

.fa-hot-tub-person {
  --fa: "";
  --fa--fa: "";
}

.fa-hot-tub {
  --fa: "";
  --fa--fa: "";
}

.fa-globe-pointer {
  --fa: "";
  --fa--fa: "";
}

.fa-blanket {
  --fa: "";
  --fa--fa: "";
}

.fa-map-location {
  --fa: "";
  --fa--fa: "";
}

.fa-map-marked {
  --fa: "";
  --fa--fa: "";
}

.fa-house-flood-water {
  --fa: "";
  --fa--fa: "";
}

.fa-comments-question-check {
  --fa: "";
  --fa--fa: "";
}

.fa-tree {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-cross {
  --fa: "";
  --fa--fa: "";
}

.fa-backpack {
  --fa: "";
  --fa--fa: "";
}

.fa-square-small {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-upload {
  --fa: "";
  --fa--fa: "";
}

.fa-bridge-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-crosshairs-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-sack-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-to-square {
  --fa: "";
  --fa--fa: "";
}

.fa-edit {
  --fa: "";
  --fa--fa: "";
}

.fa-square-sliders {
  --fa: "";
  --fa--fa: "";
}

.fa-sliders-h-square {
  --fa: "";
  --fa--fa: "";
}

.fa-car-side {
  --fa: "";
  --fa--fa: "";
}

.fa-message-middle-top {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-middle-top-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-lightbulb-on {
  --fa: "";
  --fa--fa: "";
}

.fa-knife {
  --fa: "";
  --fa--fa: "";
}

.fa-utensil-knife {
  --fa: "";
  --fa--fa: "";
}

.fa-share-nodes {
  --fa: "";
  --fa--fa: "";
}

.fa-share-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-display-chart-up-circle-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-wave-sine {
  --fa: "";
  --fa--fa: "";
}

.fa-heart-circle-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-w {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-calendar {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-hourglass-half {
  --fa: "";
  --fa--fa: "";
}

.fa-hourglass-2 {
  --fa: "";
  --fa--fa: "";
}

.fa-microscope {
  --fa: "";
  --fa--fa: "";
}

.fa-sunset {
  --fa: "";
  --fa--fa: "";
}

.fa-sink {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-container-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-bag-shopping {
  --fa: "";
  --fa--fa: "";
}

.fa-shopping-bag {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-z-a {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-alpha-desc {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-alpha-down-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-mitten {
  --fa: "";
  --fa--fa: "";
}

.fa-reply-clock {
  --fa: "";
  --fa--fa: "";
}

.fa-reply-time {
  --fa: "";
  --fa--fa: "";
}

.fa-person-rays {
  --fa: "";
  --fa--fa: "";
}

.fa-right {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-alt-right {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-f {
  --fa: "";
  --fa--fa: "";
}

.fa-users {
  --fa: "";
  --fa--fa: "";
}

.fa-face-pleading {
  --fa: "";
  --fa--fa: "";
}

.fa-eye-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-flask-vial {
  --fa: "";
  --fa--fa: "";
}

.fa-police-box {
  --fa: "";
  --fa--fa: "";
}

.fa-cucumber {
  --fa: "";
  --fa--fa: "";
}

.fa-head-side-brain {
  --fa: "";
  --fa--fa: "";
}

.fa-hand {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-paper {
  --fa: "";
  --fa--fa: "";
}

.fa-person-biking-mountain {
  --fa: "";
  --fa--fa: "";
}

.fa-biking-mountain {
  --fa: "";
  --fa--fa: "";
}

.fa-utensils-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-print-magnifying-glass {
  --fa: "";
  --fa--fa: "";
}

.fa-print-search {
  --fa: "";
  --fa--fa: "";
}

.fa-turn-right {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-bookmark {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-turn-left-down {
  --fa: "";
  --fa--fa: "";
}

.fa-om {
  --fa: "";
  --fa--fa: "";
}

.fa-pi {
  --fa: "";
  --fa--fa: "";
}

.fa-flask-round-potion {
  --fa: "";
  --fa--fa: "";
}

.fa-flask-potion {
  --fa: "";
  --fa--fa: "";
}

.fa-face-shush {
  --fa: "";
  --fa--fa: "";
}

.fa-worm {
  --fa: "";
  --fa--fa: "";
}

.fa-house-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-plug {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-square-i {
  --fa: "";
  --fa--fa: "";
}

.fa-chevron-up {
  --fa: "";
  --fa--fa: "";
}

.fa-face-saluting {
  --fa: "";
  --fa--fa: "";
}

.fa-gauge-simple-low {
  --fa: "";
  --fa--fa: "";
}

.fa-tachometer-slow {
  --fa: "";
  --fa--fa: "";
}

.fa-face-persevering {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-camera {
  --fa: "";
  --fa--fa: "";
}

.fa-camera-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-spock {
  --fa: "";
  --fa--fa: "";
}

.fa-spider-web {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-microphone {
  --fa: "";
  --fa--fa: "";
}

.fa-microphone-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-book-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-popsicle {
  --fa: "";
  --fa--fa: "";
}

.fa-command {
  --fa: "";
  --fa--fa: "";
}

.fa-blinds {
  --fa: "";
  --fa--fa: "";
}

.fa-stopwatch {
  --fa: "";
  --fa--fa: "";
}

.fa-saxophone {
  --fa: "";
  --fa--fa: "";
}

.fa-square-2 {
  --fa: "";
  --fa--fa: "";
}

.fa-field-hockey-stick-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-field-hockey {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-square-triangle {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-shapes-up-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-face-scream {
  --fa: "";
  --fa--fa: "";
}

.fa-square-m {
  --fa: "";
  --fa--fa: "";
}

.fa-camera-web {
  --fa: "";
  --fa--fa: "";
}

.fa-webcam {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-lightbulb-cfl {
  --fa: "";
  --fa--fa: "";
}

.fa-window-frame-open {
  --fa: "";
  --fa--fa: "";
}

.fa-face-kiss {
  --fa: "";
  --fa--fa: "";
}

.fa-kiss {
  --fa: "";
  --fa--fa: "";
}

.fa-bridge-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-period {
  --fa: ".";
  --fa--fa: "..";
}

.fa-face-grin-tongue {
  --fa: "";
  --fa--fa: "";
}

.fa-grin-tongue {
  --fa: "";
  --fa--fa: "";
}

.fa-up-to-dotted-line {
  --fa: "";
  --fa--fa: "";
}

.fa-thought-bubble {
  --fa: "";
  --fa--fa: "";
}

.fa-skeleton-ribs {
  --fa: "";
  --fa--fa: "";
}

.fa-raygun {
  --fa: "";
  --fa--fa: "";
}

.fa-flute {
  --fa: "";
  --fa--fa: "";
}

.fa-acorn {
  --fa: "";
  --fa--fa: "";
}

.fa-video-arrow-up-right {
  --fa: "";
  --fa--fa: "";
}

.fa-grate-droplet {
  --fa: "";
  --fa--fa: "";
}

.fa-seal-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-bishop {
  --fa: "";
  --fa--fa: "";
}

.fa-message-sms {
  --fa: "";
  --fa--fa: "";
}

.fa-coffee-beans {
  --fa: "";
  --fa--fa: "";
}

.fa-hat-witch {
  --fa: "";
  --fa--fa: "";
}

.fa-face-grin-wink {
  --fa: "";
  --fa--fa: "";
}

.fa-grin-wink {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-three-thirty {
  --fa: "";
  --fa--fa: "";
}

.fa-ear-deaf {
  --fa: "";
  --fa--fa: "";
}

.fa-deaf {
  --fa: "";
  --fa--fa: "";
}

.fa-deafness {
  --fa: "";
  --fa--fa: "";
}

.fa-hard-of-hearing {
  --fa: "";
  --fa--fa: "";
}

.fa-alarm-clock {
  --fa: "";
  --fa--fa: "";
}

.fa-eclipse {
  --fa: "";
  --fa--fa: "";
}

.fa-face-relieved {
  --fa: "";
  --fa--fa: "";
}

.fa-road-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-dice-five {
  --fa: "";
  --fa--fa: "";
}

.fa-octagon-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-minus-octagon {
  --fa: "";
  --fa--fa: "";
}

.fa-square-rss {
  --fa: "";
  --fa--fa: "";
}

.fa-rss-square {
  --fa: "";
  --fa--fa: "";
}

.fa-face-zany {
  --fa: "";
  --fa--fa: "";
}

.fa-tricycle {
  --fa: "";
  --fa--fa: "";
}

.fa-land-mine-on {
  --fa: "";
  --fa--fa: "";
}

.fa-square-arrow-up-left {
  --fa: "";
  --fa--fa: "";
}

.fa-i-cursor {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-mixed-up-circle-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-salt-shaker {
  --fa: "";
  --fa--fa: "";
}

.fa-stamp {
  --fa: "";
  --fa--fa: "";
}

.fa-file-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-draw-square {
  --fa: "";
  --fa--fa: "";
}

.fa-toilet-paper-under-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-toilet-paper-reverse-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-stairs {
  --fa: "";
  --fa--fa: "";
}

.fa-drone-front {
  --fa: "";
  --fa--fa: "";
}

.fa-drone-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-glass-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-dial-high {
  --fa: "";
  --fa--fa: "";
}

.fa-user-helmet-safety {
  --fa: "";
  --fa--fa: "";
}

.fa-user-construction {
  --fa: "";
  --fa--fa: "";
}

.fa-user-hard-hat {
  --fa: "";
  --fa--fa: "";
}

.fa-i {
  --fa: "I";
  --fa--fa: "II";
}

.fa-hryvnia-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-hryvnia {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-left-and-arrow-up-right-to-center {
  --fa: "";
  --fa--fa: "";
}

.fa-pills {
  --fa: "";
  --fa--fa: "";
}

.fa-face-grin-wide {
  --fa: "";
  --fa--fa: "";
}

.fa-grin-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-tooth {
  --fa: "";
  --fa--fa: "";
}

.fa-basketball-hoop {
  --fa: "";
  --fa--fa: "";
}

.fa-objects-align-bottom {
  --fa: "";
  --fa--fa: "";
}

.fa-v {
  --fa: "V";
  --fa--fa: "VV";
}

.fa-sparkles {
  --fa: "";
  --fa--fa: "";
}

.fa-squid {
  --fa: "";
  --fa--fa: "";
}

.fa-leafy-green {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-arrow-up-right {
  --fa: "";
  --fa--fa: "";
}

.fa-calendars {
  --fa: "";
  --fa--fa: "";
}

.fa-bangladeshi-taka-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-bicycle {
  --fa: "";
  --fa--fa: "";
}

.fa-hammer-war {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-d {
  --fa: "";
  --fa--fa: "";
}

.fa-spider-black-widow {
  --fa: "";
  --fa--fa: "";
}

.fa-staff-snake {
  --fa: "";
  --fa--fa: "";
}

.fa-rod-asclepius {
  --fa: "";
  --fa--fa: "";
}

.fa-rod-snake {
  --fa: "";
  --fa--fa: "";
}

.fa-staff-aesculapius {
  --fa: "";
  --fa--fa: "";
}

.fa-pear {
  --fa: "";
  --fa--fa: "";
}

.fa-head-side-cough-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-file-mov {
  --fa: "";
  --fa--fa: "";
}

.fa-triangle {
  --fa: "";
  --fa--fa: "";
}

.fa-apartment {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-ambulance {
  --fa: "";
  --fa--fa: "";
}

.fa-pepper {
  --fa: "";
  --fa--fa: "";
}

.fa-piano {
  --fa: "";
  --fa--fa: "";
}

.fa-gun-squirt {
  --fa: "";
  --fa--fa: "";
}

.fa-wheat-awn-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-snowman {
  --fa: "";
  --fa--fa: "";
}

.fa-user-alien {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-check {
  --fa: "";
  --fa--fa: "";
}

.fa-mortar-pestle {
  --fa: "";
  --fa--fa: "";
}

.fa-road-barrier {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-candlestick {
  --fa: "";
  --fa--fa: "";
}

.fa-briefcase-blank {
  --fa: "";
  --fa--fa: "";
}

.fa-school {
  --fa: "";
  --fa--fa: "";
}

.fa-igloo {
  --fa: "";
  --fa--fa: "";
}

.fa-bracket-round {
  --fa: "(";
  --fa--fa: "((";
}

.fa-parenthesis {
  --fa: "(";
  --fa--fa: "((";
}

.fa-joint {
  --fa: "";
  --fa--fa: "";
}

.fa-horse-saddle {
  --fa: "";
  --fa--fa: "";
}

.fa-mug-marshmallows {
  --fa: "";
  --fa--fa: "";
}

.fa-filters {
  --fa: "";
  --fa--fa: "";
}

.fa-bell-on {
  --fa: "";
  --fa--fa: "";
}

.fa-angle-right {
  --fa: "";
  --fa--fa: "";
}

.fa-dial-med {
  --fa: "";
  --fa--fa: "";
}

.fa-horse {
  --fa: "";
  --fa--fa: "";
}

.fa-q {
  --fa: "Q";
  --fa--fa: "QQ";
}

.fa-monitor-waveform {
  --fa: "";
  --fa--fa: "";
}

.fa-monitor-heart-rate {
  --fa: "";
  --fa--fa: "";
}

.fa-link-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-whistle {
  --fa: "";
  --fa--fa: "";
}

.fa-g {
  --fa: "G";
  --fa--fa: "GG";
}

.fa-wine-glass-crack {
  --fa: "";
  --fa--fa: "";
}

.fa-fragile {
  --fa: "";
  --fa--fa: "";
}

.fa-slot-machine {
  --fa: "";
  --fa--fa: "";
}

.fa-notes-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-car-wash {
  --fa: "";
  --fa--fa: "";
}

.fa-escalator {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-image {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-half {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-2 {
  --fa: "";
  --fa--fa: "";
}

.fa-thermometer-2 {
  --fa: "";
  --fa--fa: "";
}

.fa-thermometer-half {
  --fa: "";
  --fa--fa: "";
}

.fa-dong-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-donut {
  --fa: "";
  --fa--fa: "";
}

.fa-doughnut {
  --fa: "";
  --fa--fa: "";
}

.fa-capsules {
  --fa: "";
  --fa--fa: "";
}

.fa-poo-storm {
  --fa: "";
  --fa--fa: "";
}

.fa-poo-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-tally-1 {
  --fa: "";
  --fa--fa: "";
}

.fa-file-vector {
  --fa: "";
  --fa--fa: "";
}

.fa-face-frown-open {
  --fa: "";
  --fa--fa: "";
}

.fa-frown-open {
  --fa: "";
  --fa--fa: "";
}

.fa-square-dashed {
  --fa: "";
  --fa--fa: "";
}

.fa-bag-shopping-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-square-j {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-point-up {
  --fa: "";
  --fa--fa: "";
}

.fa-money-bill {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-big-small {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-size-up {
  --fa: "";
  --fa--fa: "";
}

.fa-barcode-read {
  --fa: "";
  --fa--fa: "";
}

.fa-baguette {
  --fa: "";
  --fa--fa: "";
}

.fa-bowl-soft-serve {
  --fa: "";
  --fa--fa: "";
}

.fa-face-holding-back-tears {
  --fa: "";
  --fa--fa: "";
}

.fa-square-up {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-alt-square-up {
  --fa: "";
  --fa--fa: "";
}

.fa-train-subway-tunnel {
  --fa: "";
  --fa--fa: "";
}

.fa-subway-tunnel {
  --fa: "";
  --fa--fa: "";
}

.fa-square-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-exclamation-square {
  --fa: "";
  --fa--fa: "";
}

.fa-semicolon {
  --fa: ";";
  --fa--fa: ";;";
}

.fa-bookmark {
  --fa: "";
  --fa--fa: "";
}

.fa-fan-table {
  --fa: "";
  --fa--fa: "";
}

.fa-align-justify {
  --fa: "";
  --fa--fa: "";
}

.fa-battery-low {
  --fa: "";
  --fa--fa: "";
}

.fa-battery-1 {
  --fa: "";
  --fa--fa: "";
}

.fa-credit-card-front {
  --fa: "";
  --fa--fa: "";
}

.fa-brain-arrow-curved-right {
  --fa: "";
  --fa--fa: "";
}

.fa-mind-share {
  --fa: "";
  --fa--fa: "";
}

.fa-umbrella-beach {
  --fa: "";
  --fa--fa: "";
}

.fa-helmet-un {
  --fa: "";
  --fa--fa: "";
}

.fa-location-smile {
  --fa: "";
  --fa--fa: "";
}

.fa-map-marker-smile {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-left-to-line {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-to-left {
  --fa: "";
  --fa--fa: "";
}

.fa-bullseye {
  --fa: "";
  --fa--fa: "";
}

.fa-sushi {
  --fa: "";
  --fa--fa: "";
}

.fa-nigiri {
  --fa: "";
  --fa--fa: "";
}

.fa-message-captions {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-alt-captions {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-list {
  --fa: "";
  --fa--fa: "";
}

.fa-bacon {
  --fa: "";
  --fa--fa: "";
}

.fa-option {
  --fa: "";
  --fa--fa: "";
}

.fa-raccoon {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-point-down {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-from-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-head-side-gear {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-file-cad {
  --fa: "";
  --fa--fa: "";
}

.fa-objects-align-top {
  --fa: "";
  --fa--fa: "";
}

.fa-folder {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-blank {
  --fa: "";
  --fa--fa: "";
}

.fa-face-anxious-sweat {
  --fa: "";
  --fa--fa: "";
}

.fa-credit-card-blank {
  --fa: "";
  --fa--fa: "";
}

.fa-file-waveform {
  --fa: "";
  --fa--fa: "";
}

.fa-file-medical-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-microchip-ai {
  --fa: "";
  --fa--fa: "";
}

.fa-mug {
  --fa: "";
  --fa--fa: "";
}

.fa-plane-up-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-radiation {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-bag-seedling {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-crutches {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-parking {
  --fa: "";
  --fa--fa: "";
}

.fa-parking-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-mars-stroke {
  --fa: "";
  --fa--fa: "";
}

.fa-leaf-oak {
  --fa: "";
  --fa--fa: "";
}

.fa-square-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-vial {
  --fa: "";
  --fa--fa: "";
}

.fa-gauge {
  --fa: "";
  --fa--fa: "";
}

.fa-dashboard {
  --fa: "";
  --fa--fa: "";
}

.fa-gauge-med {
  --fa: "";
  --fa--fa: "";
}

.fa-tachometer-alt-average {
  --fa: "";
  --fa--fa: "";
}

.fa-wand-magic-sparkles {
  --fa: "";
  --fa--fa: "";
}

.fa-magic-wand-sparkles {
  --fa: "";
  --fa--fa: "";
}

.fa-lambda {
  --fa: "";
  --fa--fa: "";
}

.fa-e {
  --fa: "E";
  --fa--fa: "EE";
}

.fa-pizza {
  --fa: "";
  --fa--fa: "";
}

.fa-bowl-chopsticks-noodles {
  --fa: "";
  --fa--fa: "";
}

.fa-h3 {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-clip {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-bridge-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-badge-percent {
  --fa: "";
  --fa--fa: "";
}

.fa-rotate-reverse {
  --fa: "";
  --fa--fa: "";
}

.fa-user {
  --fa: "";
  --fa--fa: "";
}

.fa-sensor {
  --fa: "";
  --fa--fa: "";
}

.fa-comma {
  --fa: ",";
  --fa--fa: ",,";
}

.fa-school-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-toilet-paper-under {
  --fa: "";
  --fa--fa: "";
}

.fa-toilet-paper-reverse {
  --fa: "";
  --fa--fa: "";
}

.fa-light-emergency {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-to-arc {
  --fa: "";
  --fa--fa: "";
}

.fa-dumpster {
  --fa: "";
  --fa--fa: "";
}

.fa-van-shuttle {
  --fa: "";
  --fa--fa: "";
}

.fa-shuttle-van {
  --fa: "";
  --fa--fa: "";
}

.fa-building-user {
  --fa: "";
  --fa--fa: "";
}

.fa-light-switch {
  --fa: "";
  --fa--fa: "";
}

.fa-square-caret-left {
  --fa: "";
  --fa--fa: "";
}

.fa-caret-square-left {
  --fa: "";
  --fa--fa: "";
}

.fa-highlighter {
  --fa: "";
  --fa--fa: "";
}

.fa-wave-pulse {
  --fa: "";
  --fa--fa: "";
}

.fa-heart-rate {
  --fa: "";
  --fa--fa: "";
}

.fa-key {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-left-to-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-hat-santa {
  --fa: "";
  --fa--fa: "";
}

.fa-tamale {
  --fa: "";
  --fa--fa: "";
}

.fa-box-check {
  --fa: "";
  --fa--fa: "";
}

.fa-bullhorn {
  --fa: "";
  --fa--fa: "";
}

.fa-steak {
  --fa: "";
  --fa--fa: "";
}

.fa-location-crosshairs-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-location-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-person-dolly {
  --fa: "";
  --fa--fa: "";
}

.fa-globe {
  --fa: "";
  --fa--fa: "";
}

.fa-synagogue {
  --fa: "";
  --fa--fa: "";
}

.fa-file-chart-column {
  --fa: "";
  --fa--fa: "";
}

.fa-file-chart-line {
  --fa: "";
  --fa--fa: "";
}

.fa-person-half-dress {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-image {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-pen {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-edit {
  --fa: "";
  --fa--fa: "";
}

.fa-road-bridge {
  --fa: "";
  --fa--fa: "";
}

.fa-face-smile-tear {
  --fa: "";
  --fa--fa: "";
}

.fa-message-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-alt-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-location-arrow {
  --fa: "";
  --fa--fa: "";
}

.fa-c {
  --fa: "C";
  --fa--fa: "CC";
}

.fa-tablet-button {
  --fa: "";
  --fa--fa: "";
}

.fa-person-dress-fairy {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-history-circle-user {
  --fa: "";
  --fa--fa: "";
}

.fa-building-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-line-up {
  --fa: "";
  --fa--fa: "";
}

.fa-mailbox {
  --fa: "";
  --fa--fa: "";
}

.fa-sign-posts {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-pizza-slice {
  --fa: "";
  --fa--fa: "";
}

.fa-money-bill-wave {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-area {
  --fa: "";
  --fa--fa: "";
}

.fa-area-chart {
  --fa: "";
  --fa--fa: "";
}

.fa-house-flag {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-three-quarters-stroke {
  --fa: "";
  --fa--fa: "";
}

.fa-person-circle-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-scalpel {
  --fa: "";
  --fa--fa: "";
}

.fa-ban {
  --fa: "";
  --fa--fa: "";
}

.fa-cancel {
  --fa: "";
  --fa--fa: "";
}

.fa-bell-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-bookmark {
  --fa: "";
  --fa--fa: "";
}

.fa-bookmark-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-egg-fried {
  --fa: "";
  --fa--fa: "";
}

.fa-face-weary {
  --fa: "";
  --fa--fa: "";
}

.fa-uniform-martial-arts {
  --fa: "";
  --fa--fa: "";
}

.fa-camera-rotate {
  --fa: "";
  --fa--fa: "";
}

.fa-sun-dust {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-text {
  --fa: "";
  --fa--fa: "";
}

.fa-spray-can-sparkles {
  --fa: "";
  --fa--fa: "";
}

.fa-air-freshener {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-bars {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-alt-4 {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-bars-strong {
  --fa: "";
  --fa--fa: "";
}

.fa-diamond-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-star {
  --fa: "";
  --fa--fa: "";
}

.fa-dial-min {
  --fa: "";
  --fa--fa: "";
}

.fa-repeat {
  --fa: "";
  --fa--fa: "";
}

.fa-cross {
  --fa: "";
  --fa--fa: "";
}

.fa-page-caret-down {
  --fa: "";
  --fa--fa: "";
}

.fa-file-caret-down {
  --fa: "";
  --fa--fa: "";
}

.fa-box {
  --fa: "";
  --fa--fa: "";
}

.fa-venus-mars {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-seven-thirty {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-pointer {
  --fa: "";
  --fa--fa: "";
}

.fa-mouse-pointer {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-four-thirty {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-bars-good {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-alt-3 {
  --fa: "";
  --fa--fa: "";
}

.fa-cactus {
  --fa: "";
  --fa--fa: "";
}

.fa-lightbulb-gear {
  --fa: "";
  --fa--fa: "";
}

.fa-maximize {
  --fa: "";
  --fa--fa: "";
}

.fa-expand-arrows-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-charging-station {
  --fa: "";
  --fa--fa: "";
}

.fa-shapes {
  --fa: "";
  --fa--fa: "";
}

.fa-triangle-circle-square {
  --fa: "";
  --fa--fa: "";
}

.fa-plane-tail {
  --fa: "";
  --fa--fa: "";
}

.fa-gauge-simple-max {
  --fa: "";
  --fa--fa: "";
}

.fa-tachometer-fastest {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-u {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-square-phone-hangup {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-square-down {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-left {
  --fa: "";
  --fa--fa: "";
}

.fa-transporter-1 {
  --fa: "";
  --fa--fa: "";
}

.fa-peanuts {
  --fa: "";
  --fa--fa: "";
}

.fa-shuffle {
  --fa: "";
  --fa--fa: "";
}

.fa-random {
  --fa: "";
  --fa--fa: "";
}

.fa-person-running {
  --fa: "";
  --fa--fa: "";
}

.fa-running {
  --fa: "";
  --fa--fa: "";
}

.fa-mobile-retro {
  --fa: "";
  --fa--fa: "";
}

.fa-grip-lines-vertical {
  --fa: "";
  --fa--fa: "";
}

.fa-bin-bottles-recycle {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-from-square {
  --fa: "";
  --fa--fa: "";
}

.fa-file-dashed-line {
  --fa: "";
  --fa--fa: "";
}

.fa-page-break {
  --fa: "";
  --fa--fa: "";
}

.fa-bracket-curly-right {
  --fa: "}";
  --fa--fa: "}}";
}

.fa-spider {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-three {
  --fa: "";
  --fa--fa: "";
}

.fa-hands-bound {
  --fa: "";
  --fa--fa: "";
}

.fa-scalpel-line-dashed {
  --fa: "";
  --fa--fa: "";
}

.fa-scalpel-path {
  --fa: "";
  --fa--fa: "";
}

.fa-file-invoice-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-pipe-smoking {
  --fa: "";
  --fa--fa: "";
}

.fa-face-astonished {
  --fa: "";
  --fa--fa: "";
}

.fa-window {
  --fa: "";
  --fa--fa: "";
}

.fa-plane-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-ear {
  --fa: "";
  --fa--fa: "";
}

.fa-file-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-diagram-venn {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-from-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-x-ray {
  --fa: "";
  --fa--fa: "";
}

.fa-goal-net {
  --fa: "";
  --fa--fa: "";
}

.fa-coffin-cross {
  --fa: "";
  --fa--fa: "";
}

.fa-octopus {
  --fa: "";
  --fa--fa: "";
}

.fa-spell-check {
  --fa: "";
  --fa--fa: "";
}

.fa-location-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-map-marker-times {
  --fa: "";
  --fa--fa: "";
}

.fa-map-marker-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-quarter-stroke {
  --fa: "";
  --fa--fa: "";
}

.fa-lasso {
  --fa: "";
  --fa--fa: "";
}

.fa-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-person-to-portal {
  --fa: "";
  --fa--fa: "";
}

.fa-portal-enter {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-star {
  --fa: "";
  --fa--fa: "";
}

.fa-computer-mouse {
  --fa: "";
  --fa--fa: "";
}

.fa-mouse {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-right-to-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-sign-in {
  --fa: "";
  --fa--fa: "";
}

.fa-pegasus {
  --fa: "";
  --fa--fa: "";
}

.fa-files-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-cannon {
  --fa: "";
  --fa--fa: "";
}

.fa-nfc-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-person-ski-lift {
  --fa: "";
  --fa--fa: "";
}

.fa-ski-lift {
  --fa: "";
  --fa--fa: "";
}

.fa-square-6 {
  --fa: "";
  --fa--fa: "";
}

.fa-shop-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-store-alt-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-wind-turbine {
  --fa: "";
  --fa--fa: "";
}

.fa-sliders-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-grid-round {
  --fa: "";
  --fa--fa: "";
}

.fa-badge-sheriff {
  --fa: "";
  --fa--fa: "";
}

.fa-server {
  --fa: "";
  --fa--fa: "";
}

.fa-virus-covid-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-intersection {
  --fa: "";
  --fa--fa: "";
}

.fa-shop-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-family {
  --fa: "";
  --fa--fa: "";
}

.fa-hourglass-start {
  --fa: "";
  --fa--fa: "";
}

.fa-hourglass-1 {
  --fa: "";
  --fa--fa: "";
}

.fa-user-hair-buns {
  --fa: "";
  --fa--fa: "";
}

.fa-blender-phone {
  --fa: "";
  --fa--fa: "";
}

.fa-hourglass-clock {
  --fa: "";
  --fa--fa: "";
}

.fa-person-seat-reclined {
  --fa: "";
  --fa--fa: "";
}

.fa-paper-plane-top {
  --fa: "";
  --fa--fa: "";
}

.fa-paper-plane-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-send {
  --fa: "";
  --fa--fa: "";
}

.fa-message-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-alt-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-lightbulb-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-layer-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-layer-group-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-pie-simple-circle-currency {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-e {
  --fa: "";
  --fa--fa: "";
}

.fa-building-wheat {
  --fa: "";
  --fa--fa: "";
}

.fa-gauge-max {
  --fa: "";
  --fa--fa: "";
}

.fa-tachometer-alt-fastest {
  --fa: "";
  --fa--fa: "";
}

.fa-person-breastfeeding {
  --fa: "";
  --fa--fa: "";
}

.fa-apostrophe {
  --fa: "'";
  --fa--fa: "''";
}

.fa-file-png {
  --fa: "";
  --fa--fa: "";
}

.fa-fire-hydrant {
  --fa: "";
  --fa--fa: "";
}

.fa-right-to-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-sign-in-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-video-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-square-right {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-alt-square-right {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-smile {
  --fa: "";
  --fa--fa: "";
}

.fa-venus {
  --fa: "";
  --fa--fa: "";
}

.fa-passport {
  --fa: "";
  --fa--fa: "";
}

.fa-thumbtack-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-thumb-tack-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-inbox-in {
  --fa: "";
  --fa--fa: "";
}

.fa-inbox-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-heart-pulse {
  --fa: "";
  --fa--fa: "";
}

.fa-heartbeat {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-8 {
  --fa: "";
  --fa--fa: "";
}

.fa-clouds-moon {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-ten-thirty {
  --fa: "";
  --fa--fa: "";
}

.fa-people-carry-box {
  --fa: "";
  --fa--fa: "";
}

.fa-people-carry {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-user {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-can-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-high {
  --fa: "";
  --fa--fa: "";
}

.fa-microchip {
  --fa: "";
  --fa--fa: "";
}

.fa-left-long-to-line {
  --fa: "";
  --fa--fa: "";
}

.fa-crown {
  --fa: "";
  --fa--fa: "";
}

.fa-weight-hanging {
  --fa: "";
  --fa--fa: "";
}

.fa-xmarks-lines {
  --fa: "";
  --fa--fa: "";
}

.fa-file-prescription {
  --fa: "";
  --fa--fa: "";
}

.fa-table-cells-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-range {
  --fa: "";
  --fa--fa: "";
}

.fa-flower-daffodil {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-back-point-up {
  --fa: "";
  --fa--fa: "";
}

.fa-weight-scale {
  --fa: "";
  --fa--fa: "";
}

.fa-weight {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-to-arc {
  --fa: "";
  --fa--fa: "";
}

.fa-star-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-books {
  --fa: "";
  --fa--fa: "";
}

.fa-user-group {
  --fa: "";
  --fa--fa: "";
}

.fa-user-friends {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-a-z {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-alpha-up {
  --fa: "";
  --fa--fa: "";
}

.fa-layer-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-layer-group-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-play-pause {
  --fa: "";
  --fa--fa: "";
}

.fa-block-question {
  --fa: "";
  --fa--fa: "";
}

.fa-snooze {
  --fa: "";
  --fa--fa: "";
}

.fa-zzz {
  --fa: "";
  --fa--fa: "";
}

.fa-scanner-image {
  --fa: "";
  --fa--fa: "";
}

.fa-tv-retro {
  --fa: "";
  --fa--fa: "";
}

.fa-square-t {
  --fa: "";
  --fa--fa: "";
}

.fa-farm {
  --fa: "";
  --fa--fa: "";
}

.fa-barn-silo {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-knight {
  --fa: "";
  --fa--fa: "";
}

.fa-bars-sort {
  --fa: "";
  --fa--fa: "";
}

.fa-pallet-boxes {
  --fa: "";
  --fa--fa: "";
}

.fa-palette-boxes {
  --fa: "";
  --fa--fa: "";
}

.fa-pallet-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-face-laugh-squint {
  --fa: "";
  --fa--fa: "";
}

.fa-laugh-squint {
  --fa: "";
  --fa--fa: "";
}

.fa-code-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-bolt-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-panel-fire {
  --fa: "";
  --fa--fa: "";
}

.fa-binary-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-burrito {
  --fa: "";
  --fa--fa: "";
}

.fa-violin {
  --fa: "";
  --fa--fa: "";
}

.fa-objects-column {
  --fa: "";
  --fa--fa: "";
}

.fa-square-chevron-down {
  --fa: "";
  --fa--fa: "";
}

.fa-chevron-square-down {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-triangle-instrument {
  --fa: "";
  --fa--fa: "";
}

.fa-triangle-music {
  --fa: "";
  --fa--fa: "";
}

.fa-wheelchair {
  --fa: "";
  --fa--fa: "";
}

.fa-user-pilot-tie {
  --fa: "";
  --fa--fa: "";
}

.fa-piano-keyboard {
  --fa: "";
  --fa--fa: "";
}

.fa-bed-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-circle-up {
  --fa: "";
  --fa--fa: "";
}

.fa-toggle-on {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-vertical {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-portrait {
  --fa: "";
  --fa--fa: "";
}

.fa-person-walking {
  --fa: "";
  --fa--fa: "";
}

.fa-walking {
  --fa: "";
  --fa--fa: "";
}

.fa-l {
  --fa: "L";
  --fa--fa: "LL";
}

.fa-signal-stream {
  --fa: "";
  --fa--fa: "";
}

.fa-down-to-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-z {
  --fa: "";
  --fa--fa: "";
}

.fa-stars {
  --fa: "";
  --fa--fa: "";
}

.fa-fire {
  --fa: "";
  --fa--fa: "";
}

.fa-bed-pulse {
  --fa: "";
  --fa--fa: "";
}

.fa-procedures {
  --fa: "";
  --fa--fa: "";
}

.fa-house-day {
  --fa: "";
  --fa--fa: "";
}

.fa-shuttle-space {
  --fa: "";
  --fa--fa: "";
}

.fa-space-shuttle {
  --fa: "";
  --fa--fa: "";
}

.fa-shirt-long-sleeve {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-pie-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-pie-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-face-laugh {
  --fa: "";
  --fa--fa: "";
}

.fa-laugh {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-open {
  --fa: "";
  --fa--fa: "";
}

.fa-album-collection-circle-user {
  --fa: "";
  --fa--fa: "";
}

.fa-candy {
  --fa: "";
  --fa--fa: "";
}

.fa-bowl-hot {
  --fa: "";
  --fa--fa: "";
}

.fa-soup {
  --fa: "";
  --fa--fa: "";
}

.fa-flatbread {
  --fa: "";
  --fa--fa: "";
}

.fa-heart-circle-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-code-fork {
  --fa: "";
  --fa--fa: "";
}

.fa-city {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-bars-weak {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-alt-1 {
  --fa: "";
  --fa--fa: "";
}

.fa-microphone-lines {
  --fa: "";
  --fa--fa: "";
}

.fa-microphone-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-twelve {
  --fa: "";
  --fa--fa: "";
}

.fa-pepper-hot {
  --fa: "";
  --fa--fa: "";
}

.fa-citrus-slice {
  --fa: "";
  --fa--fa: "";
}

.fa-sheep {
  --fa: "";
  --fa--fa: "";
}

.fa-unlock {
  --fa: "";
  --fa--fa: "";
}

.fa-colon-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-headset {
  --fa: "";
  --fa--fa: "";
}

.fa-badger-honey {
  --fa: "";
  --fa--fa: "";
}

.fa-h4 {
  --fa: "";
  --fa--fa: "";
}

.fa-store-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-road-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-user-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-mars-stroke-up {
  --fa: "";
  --fa--fa: "";
}

.fa-mars-stroke-v {
  --fa: "";
  --fa--fa: "";
}

.fa-champagne-glasses {
  --fa: "";
  --fa--fa: "";
}

.fa-glass-cheers {
  --fa: "";
  --fa--fa: "";
}

.fa-taco {
  --fa: "";
  --fa--fa: "";
}

.fa-hexagon-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-plus-hexagon {
  --fa: "";
  --fa--fa: "";
}

.fa-clipboard {
  --fa: "";
  --fa--fa: "";
}

.fa-house-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-file-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-file-upload {
  --fa: "";
  --fa--fa: "";
}

.fa-wifi {
  --fa: "";
  --fa--fa: "";
}

.fa-wifi-3 {
  --fa: "";
  --fa--fa: "";
}

.fa-wifi-strong {
  --fa: "";
  --fa--fa: "";
}

.fa-messages {
  --fa: "";
  --fa--fa: "";
}

.fa-comments-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-bath {
  --fa: "";
  --fa--fa: "";
}

.fa-bathtub {
  --fa: "";
  --fa--fa: "";
}

.fa-umbrella-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-umbrella-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-history-circle-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-underline {
  --fa: "";
  --fa--fa: "";
}

.fa-prescription-bottle-pill {
  --fa: "";
  --fa--fa: "";
}

.fa-user-pen {
  --fa: "";
  --fa--fa: "";
}

.fa-user-edit {
  --fa: "";
  --fa--fa: "";
}

.fa-binary-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-square-o {
  --fa: "";
  --fa--fa: "";
}

.fa-caduceus {
  --fa: "";
  --fa--fa: "";
}

.fa-signature {
  --fa: "";
  --fa--fa: "";
}

.fa-stroopwafel {
  --fa: "";
  --fa--fa: "";
}

.fa-bold {
  --fa: "";
  --fa--fa: "";
}

.fa-anchor-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-building-ngo {
  --fa: "";
  --fa--fa: "";
}

.fa-transporter-3 {
  --fa: "";
  --fa--fa: "";
}

.fa-engine-warning {
  --fa: "";
  --fa--fa: "";
}

.fa-engine-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-down-right {
  --fa: "";
  --fa--fa: "";
}

.fa-square-k {
  --fa: "";
  --fa--fa: "";
}

.fa-manat-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-money-check-pen {
  --fa: "";
  --fa--fa: "";
}

.fa-money-check-edit {
  --fa: "";
  --fa--fa: "";
}

.fa-not-equal {
  --fa: "";
  --fa--fa: "";
}

.fa-border-top-left {
  --fa: "";
  --fa--fa: "";
}

.fa-border-style {
  --fa: "";
  --fa--fa: "";
}

.fa-map-location-dot {
  --fa: "";
  --fa--fa: "";
}

.fa-map-marked-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-tilde {
  --fa: "~";
  --fa--fa: "~~";
}

.fa-jedi {
  --fa: "";
  --fa--fa: "";
}

.fa-square-poll-vertical {
  --fa: "";
  --fa--fa: "";
}

.fa-poll {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-square-triangle {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-shapes-down-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-mug-hot {
  --fa: "";
  --fa--fa: "";
}

.fa-dog-leashed {
  --fa: "";
  --fa--fa: "";
}

.fa-car-battery {
  --fa: "";
  --fa--fa: "";
}

.fa-battery-car {
  --fa: "";
  --fa--fa: "";
}

.fa-face-downcast-sweat {
  --fa: "";
  --fa--fa: "";
}

.fa-mailbox-flag-up {
  --fa: "";
  --fa--fa: "";
}

.fa-memo-circle-info {
  --fa: "";
  --fa--fa: "";
}

.fa-gift {
  --fa: "";
  --fa--fa: "";
}

.fa-dice-two {
  --fa: "";
  --fa--fa: "";
}

.fa-volume {
  --fa: "";
  --fa--fa: "";
}

.fa-volume-medium {
  --fa: "";
  --fa--fa: "";
}

.fa-transporter-5 {
  --fa: "";
  --fa--fa: "";
}

.fa-gauge-circle-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-coin-front {
  --fa: "";
  --fa--fa: "";
}

.fa-file-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-message-arrow-up-right {
  --fa: "";
  --fa--fa: "";
}

.fa-treasure-chest {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-queen {
  --fa: "";
  --fa--fa: "";
}

.fa-paintbrush-fine {
  --fa: "";
  --fa--fa: "";
}

.fa-paint-brush-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-paint-brush-fine {
  --fa: "";
  --fa--fa: "";
}

.fa-paintbrush-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-glasses {
  --fa: "";
  --fa--fa: "";
}

.fa-hood-cloak {
  --fa: "";
  --fa--fa: "";
}

.fa-square-quote {
  --fa: "";
  --fa--fa: "";
}

.fa-up-left {
  --fa: "";
  --fa--fa: "";
}

.fa-bring-front {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-board {
  --fa: "";
  --fa--fa: "";
}

.fa-burger-cheese {
  --fa: "";
  --fa--fa: "";
}

.fa-cheeseburger {
  --fa: "";
  --fa--fa: "";
}

.fa-building-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-repeat-1 {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-to-line {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-to-bottom {
  --fa: "";
  --fa--fa: "";
}

.fa-grid-5 {
  --fa: "";
  --fa--fa: "";
}

.fa-swap-arrows {
  --fa: "";
  --fa--fa: "";
}

.fa-right-long-to-line {
  --fa: "";
  --fa--fa: "";
}

.fa-person-chalkboard {
  --fa: "";
  --fa--fa: "";
}

.fa-mars-stroke-right {
  --fa: "";
  --fa--fa: "";
}

.fa-mars-stroke-h {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-back-fist {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-rock {
  --fa: "";
  --fa--fa: "";
}

.fa-grid-round-5 {
  --fa: "";
  --fa--fa: "";
}

.fa-tally {
  --fa: "";
  --fa--fa: "";
}

.fa-tally-5 {
  --fa: "";
  --fa--fa: "";
}

.fa-square-caret-up {
  --fa: "";
  --fa--fa: "";
}

.fa-caret-square-up {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-showers-water {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-bar {
  --fa: "";
  --fa--fa: "";
}

.fa-bar-chart {
  --fa: "";
  --fa--fa: "";
}

.fa-hands-bubbles {
  --fa: "";
  --fa--fa: "";
}

.fa-hands-wash {
  --fa: "";
  --fa--fa: "";
}

.fa-less-than-equal {
  --fa: "";
  --fa--fa: "";
}

.fa-train {
  --fa: "";
  --fa--fa: "";
}

.fa-up-from-dotted-line {
  --fa: "";
  --fa--fa: "";
}

.fa-eye-low-vision {
  --fa: "";
  --fa--fa: "";
}

.fa-low-vision {
  --fa: "";
  --fa--fa: "";
}

.fa-traffic-light-go {
  --fa: "";
  --fa--fa: "";
}

.fa-face-exhaling {
  --fa: "";
  --fa--fa: "";
}

.fa-sensor-fire {
  --fa: "";
  --fa--fa: "";
}

.fa-user-unlock {
  --fa: "";
  --fa--fa: "";
}

.fa-hexagon-divide {
  --fa: "";
  --fa--fa: "";
}

.fa-00 {
  --fa: "";
  --fa--fa: "";
}

.fa-crow {
  --fa: "";
  --fa--fa: "";
}

.fa-cassette-betamax {
  --fa: "";
  --fa--fa: "";
}

.fa-betamax {
  --fa: "";
  --fa--fa: "";
}

.fa-sailboat {
  --fa: "";
  --fa--fa: "";
}

.fa-window-restore {
  --fa: "";
  --fa--fa: "";
}

.fa-nfc-magnifying-glass {
  --fa: "";
  --fa--fa: "";
}

.fa-file-binary {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-v {
  --fa: "";
  --fa--fa: "";
}

.fa-square-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-plus-square {
  --fa: "";
  --fa--fa: "";
}

.fa-bowl-scoops {
  --fa: "";
  --fa--fa: "";
}

.fa-mistletoe {
  --fa: "";
  --fa--fa: "";
}

.fa-custard {
  --fa: "";
  --fa--fa: "";
}

.fa-lacrosse-stick {
  --fa: "";
  --fa--fa: "";
}

.fa-hockey-mask {
  --fa: "";
  --fa--fa: "";
}

.fa-sunrise {
  --fa: "";
  --fa--fa: "";
}

.fa-subtitles {
  --fa: "";
  --fa--fa: "";
}

.fa-panel-ews {
  --fa: "";
  --fa--fa: "";
}

.fa-torii-gate {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-message-lines {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-alt-lines {
  --fa: "";
  --fa--fa: "";
}

.fa-frog {
  --fa: "";
  --fa--fa: "";
}

.fa-bucket {
  --fa: "";
  --fa--fa: "";
}

.fa-floppy-disk-pen {
  --fa: "";
  --fa--fa: "";
}

.fa-image {
  --fa: "";
  --fa--fa: "";
}

.fa-window-frame {
  --fa: "";
  --fa--fa: "";
}

.fa-microphone {
  --fa: "";
  --fa--fa: "";
}

.fa-cow {
  --fa: "";
  --fa--fa: "";
}

.fa-file-zip {
  --fa: "";
  --fa--fa: "";
}

.fa-square-ring {
  --fa: "";
  --fa--fa: "";
}

.fa-down-from-line {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-alt-from-top {
  --fa: "";
  --fa--fa: "";
}

.fa-caret-up {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-times {
  --fa: "";
  --fa--fa: "";
}

.fa-screwdriver {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-sort-down {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-circle-down {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-closed {
  --fa: "";
  --fa--fa: "";
}

.fa-house-tsunami {
  --fa: "";
  --fa--fa: "";
}

.fa-square-nfi {
  --fa: "";
  --fa--fa: "";
}

.fa-forklift {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-from-ground-water {
  --fa: "";
  --fa--fa: "";
}

.fa-bracket-square-right {
  --fa: "]";
  --fa--fa: "]]";
}

.fa-martini-glass {
  --fa: "";
  --fa--fa: "";
}

.fa-glass-martini-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-square-binary {
  --fa: "";
  --fa--fa: "";
}

.fa-rotate-left {
  --fa: "";
  --fa--fa: "";
}

.fa-rotate-back {
  --fa: "";
  --fa--fa: "";
}

.fa-rotate-backward {
  --fa: "";
  --fa--fa: "";
}

.fa-undo-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-table-columns {
  --fa: "";
  --fa--fa: "";
}

.fa-columns {
  --fa: "";
  --fa--fa: "";
}

.fa-square-a {
  --fa: "";
  --fa--fa: "";
}

.fa-tick {
  --fa: "";
  --fa--fa: "";
}

.fa-lemon {
  --fa: "";
  --fa--fa: "";
}

.fa-head-side-mask {
  --fa: "";
  --fa--fa: "";
}

.fa-handshake {
  --fa: "";
  --fa--fa: "";
}

.fa-gem {
  --fa: "";
  --fa--fa: "";
}

.fa-dolly {
  --fa: "";
  --fa--fa: "";
}

.fa-dolly-box {
  --fa: "";
  --fa--fa: "";
}

.fa-smoking {
  --fa: "";
  --fa--fa: "";
}

.fa-minimize {
  --fa: "";
  --fa--fa: "";
}

.fa-compress-arrows-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-refrigerator {
  --fa: "";
  --fa--fa: "";
}

.fa-monument {
  --fa: "";
  --fa--fa: "";
}

.fa-octagon-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-times-octagon {
  --fa: "";
  --fa--fa: "";
}

.fa-xmark-octagon {
  --fa: "";
  --fa--fa: "";
}

.fa-align-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-snowplow {
  --fa: "";
  --fa--fa: "";
}

.fa-angles-right {
  --fa: "";
  --fa--fa: "";
}

.fa-angle-double-right {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-ramp-couch {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-couch {
  --fa: "";
  --fa--fa: "";
}

.fa-cannabis {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-play {
  --fa: "";
  --fa--fa: "";
}

.fa-play-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-right-and-arrow-down-left-from-center {
  --fa: "";
  --fa--fa: "";
}

.fa-location-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-tablets {
  --fa: "";
  --fa--fa: "";
}

.fa-360-degrees {
  --fa: "";
  --fa--fa: "";
}

.fa-ethernet {
  --fa: "";
  --fa--fa: "";
}

.fa-euro-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-eur {
  --fa: "";
  --fa--fa: "";
}

.fa-euro {
  --fa: "";
  --fa--fa: "";
}

.fa-chair {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-check-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-square-dashed-circle-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-holding-circle-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-money-simple-from-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-bat {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-stop {
  --fa: "";
  --fa--fa: "";
}

.fa-stop-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-head-side-headphones {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-rotary {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-to-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-compass-drafting {
  --fa: "";
  --fa--fa: "";
}

.fa-drafting-compass {
  --fa: "";
  --fa--fa: "";
}

.fa-plate-wheat {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-circle-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-chopsticks {
  --fa: "";
  --fa--fa: "";
}

.fa-car-wrench {
  --fa: "";
  --fa--fa: "";
}

.fa-car-mechanic {
  --fa: "";
  --fa--fa: "";
}

.fa-icicles {
  --fa: "";
  --fa--fa: "";
}

.fa-person-shelter {
  --fa: "";
  --fa--fa: "";
}

.fa-neuter {
  --fa: "";
  --fa--fa: "";
}

.fa-id-badge {
  --fa: "";
  --fa--fa: "";
}

.fa-kazoo {
  --fa: "";
  --fa--fa: "";
}

.fa-marker {
  --fa: "";
  --fa--fa: "";
}

.fa-bin-bottles {
  --fa: "";
  --fa--fa: "";
}

.fa-face-laugh-beam {
  --fa: "";
  --fa--fa: "";
}

.fa-laugh-beam {
  --fa: "";
  --fa--fa: "";
}

.fa-square-arrow-down-left {
  --fa: "";
  --fa--fa: "";
}

.fa-battery-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-tree-large {
  --fa: "";
  --fa--fa: "";
}

.fa-helicopter-symbol {
  --fa: "";
  --fa--fa: "";
}

.fa-aperture {
  --fa: "";
  --fa--fa: "";
}

.fa-universal-access {
  --fa: "";
  --fa--fa: "";
}

.fa-gear-complex {
  --fa: "";
  --fa--fa: "";
}

.fa-file-magnifying-glass {
  --fa: "";
  --fa--fa: "";
}

.fa-file-search {
  --fa: "";
  --fa--fa: "";
}

.fa-up-right {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-chevron-up {
  --fa: "";
  --fa--fa: "";
}

.fa-chevron-circle-up {
  --fa: "";
  --fa--fa: "";
}

.fa-user-police {
  --fa: "";
  --fa--fa: "";
}

.fa-lari-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-volcano {
  --fa: "";
  --fa--fa: "";
}

.fa-teddy-bear {
  --fa: "";
  --fa--fa: "";
}

.fa-stocking {
  --fa: "";
  --fa--fa: "";
}

.fa-person-walking-dashed-line-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-image-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-mask-snorkel {
  --fa: "";
  --fa--fa: "";
}

.fa-smoke {
  --fa: "";
  --fa--fa: "";
}

.fa-sterling-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-gbp {
  --fa: "";
  --fa--fa: "";
}

.fa-pound-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-battery-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-viruses {
  --fa: "";
  --fa--fa: "";
}

.fa-square-person-confined {
  --fa: "";
  --fa--fa: "";
}

.fa-user-tie {
  --fa: "";
  --fa--fa: "";
}

.fa-up-to-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-long {
  --fa: "";
  --fa--fa: "";
}

.fa-long-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-tent-arrow-down-to-line {
  --fa: "";
  --fa--fa: "";
}

.fa-certificate {
  --fa: "";
  --fa--fa: "";
}

.fa-crystal-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-reply-all {
  --fa: "";
  --fa--fa: "";
}

.fa-mail-reply-all {
  --fa: "";
  --fa--fa: "";
}

.fa-suitcase {
  --fa: "";
  --fa--fa: "";
}

.fa-person-skating {
  --fa: "";
  --fa--fa: "";
}

.fa-skating {
  --fa: "";
  --fa--fa: "";
}

.fa-star-shooting {
  --fa: "";
  --fa--fa: "";
}

.fa-binary-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-filter-circle-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-funnel-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-camera-retro {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-circle-down {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-pen {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-edit {
  --fa: "";
  --fa--fa: "";
}

.fa-file-import {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-right-to-file {
  --fa: "";
  --fa--fa: "";
}

.fa-banjo {
  --fa: "";
  --fa--fa: "";
}

.fa-square-arrow-up-right {
  --fa: "";
  --fa--fa: "";
}

.fa-external-link-square {
  --fa: "";
  --fa--fa: "";
}

.fa-light-emergency-on {
  --fa: "";
  --fa--fa: "";
}

.fa-kerning {
  --fa: "";
  --fa--fa: "";
}

.fa-box-open {
  --fa: "";
  --fa--fa: "";
}

.fa-square-f {
  --fa: "";
  --fa--fa: "";
}

.fa-scroll {
  --fa: "";
  --fa--fa: "";
}

.fa-spa {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-left-from-line {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-from-right {
  --fa: "";
  --fa--fa: "";
}

.fa-strawberry {
  --fa: "";
  --fa--fa: "";
}

.fa-location-pin-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-pause {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-eight-thirty {
  --fa: "";
  --fa--fa: "";
}

.fa-plane-engines {
  --fa: "";
  --fa--fa: "";
}

.fa-plane-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-hill-avalanche {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-0 {
  --fa: "";
  --fa--fa: "";
}

.fa-thermometer-0 {
  --fa: "";
  --fa--fa: "";
}

.fa-thermometer-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-bomb {
  --fa: "";
  --fa--fa: "";
}

.fa-gauge-low {
  --fa: "";
  --fa--fa: "";
}

.fa-tachometer-alt-slow {
  --fa: "";
  --fa--fa: "";
}

.fa-registered {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-can-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-address-card {
  --fa: "";
  --fa--fa: "";
}

.fa-contact-card {
  --fa: "";
  --fa--fa: "";
}

.fa-vcard {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-fft {
  --fa: "";
  --fa--fa: "";
}

.fa-scale-unbalanced-flip {
  --fa: "";
  --fa--fa: "";
}

.fa-balance-scale-right {
  --fa: "";
  --fa--fa: "";
}

.fa-globe-snow {
  --fa: "";
  --fa--fa: "";
}

.fa-subscript {
  --fa: "";
  --fa--fa: "";
}

.fa-diamond-turn-right {
  --fa: "";
  --fa--fa: "";
}

.fa-directions {
  --fa: "";
  --fa--fa: "";
}

.fa-integral {
  --fa: "";
  --fa--fa: "";
}

.fa-burst {
  --fa: "";
  --fa--fa: "";
}

.fa-house-laptop {
  --fa: "";
  --fa--fa: "";
}

.fa-laptop-house {
  --fa: "";
  --fa--fa: "";
}

.fa-face-tired {
  --fa: "";
  --fa--fa: "";
}

.fa-tired {
  --fa: "";
  --fa--fa: "";
}

.fa-money-bills {
  --fa: "";
  --fa--fa: "";
}

.fa-blinds-raised {
  --fa: "";
  --fa--fa: "";
}

.fa-smog {
  --fa: "";
  --fa--fa: "";
}

.fa-ufo-beam {
  --fa: "";
  --fa--fa: "";
}

.fa-hydra {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-caret-up {
  --fa: "";
  --fa--fa: "";
}

.fa-caret-circle-up {
  --fa: "";
  --fa--fa: "";
}

.fa-user-vneck-hair-long {
  --fa: "";
  --fa--fa: "";
}

.fa-square-a-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-crutch {
  --fa: "";
  --fa--fa: "";
}

.fa-gas-pump-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-upload {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-upload-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-palette {
  --fa: "";
  --fa--fa: "";
}

.fa-transporter-4 {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-mixed-up-circle-currency {
  --fa: "";
  --fa--fa: "";
}

.fa-objects-align-right {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-turn-right {
  --fa: "";
  --fa--fa: "";
}

.fa-vest {
  --fa: "";
  --fa--fa: "";
}

.fa-pig {
  --fa: "";
  --fa--fa: "";
}

.fa-inbox-full {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-envelope {
  --fa: "";
  --fa--fa: "";
}

.fa-envelope-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-triangle-person-digging {
  --fa: "";
  --fa--fa: "";
}

.fa-construction {
  --fa: "";
  --fa--fa: "";
}

.fa-ferry {
  --fa: "";
  --fa--fa: "";
}

.fa-bullseye-arrow {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-down-to-people {
  --fa: "";
  --fa--fa: "";
}

.fa-seedling {
  --fa: "";
  --fa--fa: "";
}

.fa-sprout {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-seven {
  --fa: "";
  --fa--fa: "";
}

.fa-left-right {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-alt-h {
  --fa: "";
  --fa--fa: "";
}

.fa-boxes-packing {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-arrow-left {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-circle-left {
  --fa: "";
  --fa--fa: "";
}

.fa-flashlight {
  --fa: "";
  --fa--fa: "";
}

.fa-file-jpg {
  --fa: "";
  --fa--fa: "";
}

.fa-group-arrows-rotate {
  --fa: "";
  --fa--fa: "";
}

.fa-bowl-food {
  --fa: "";
  --fa--fa: "";
}

.fa-square-9 {
  --fa: "";
  --fa--fa: "";
}

.fa-candy-cane {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-wide-short {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-amount-asc {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-amount-down {
  --fa: "";
  --fa--fa: "";
}

.fa-square-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-dollar-square {
  --fa: "";
  --fa--fa: "";
}

.fa-usd-square {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-holding-seedling {
  --fa: "";
  --fa--fa: "";
}

.fa-message-check {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-alt-check {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-thunderstorm {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-line-up-down {
  --fa: "";
  --fa--fa: "";
}

.fa-text-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-remove-format {
  --fa: "";
  --fa--fa: "";
}

.fa-watch {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-down-left {
  --fa: "";
  --fa--fa: "";
}

.fa-text {
  --fa: "";
  --fa--fa: "";
}

.fa-projector {
  --fa: "";
  --fa--fa: "";
}

.fa-face-smile-wink {
  --fa: "";
  --fa--fa: "";
}

.fa-smile-wink {
  --fa: "";
  --fa--fa: "";
}

.fa-tombstone-blank {
  --fa: "";
  --fa--fa: "";
}

.fa-tombstone-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-king-piece {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-king-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-6 {
  --fa: "";
  --fa--fa: "";
}

.fa-waves-sine {
  --fa: "";
  --fa--fa: "";
}

.fa-left {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-alt-left {
  --fa: "";
  --fa--fa: "";
}

.fa-file-word {
  --fa: "";
  --fa--fa: "";
}

.fa-file-powerpoint {
  --fa: "";
  --fa--fa: "";
}

.fa-square-down {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-alt-square-down {
  --fa: "";
  --fa--fa: "";
}

.fa-objects-align-center-vertical {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-left-right {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-h {
  --fa: "";
  --fa--fa: "";
}

.fa-house-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-download {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-download-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-wreath {
  --fa: "";
  --fa--fa: "";
}

.fa-children {
  --fa: "";
  --fa--fa: "";
}

.fa-meter-droplet {
  --fa: "";
  --fa--fa: "";
}

.fa-chalkboard {
  --fa: "";
  --fa--fa: "";
}

.fa-blackboard {
  --fa: "";
  --fa--fa: "";
}

.fa-user-large-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-user-alt-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-strong {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-4 {
  --fa: "";
  --fa--fa: "";
}

.fa-lollipop {
  --fa: "";
  --fa--fa: "";
}

.fa-lollypop {
  --fa: "";
  --fa--fa: "";
}

.fa-list-tree {
  --fa: "";
  --fa--fa: "";
}

.fa-envelope-open {
  --fa: "";
  --fa--fa: "";
}

.fa-draw-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-cat-space {
  --fa: "";
  --fa--fa: "";
}

.fa-handshake-simple-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-handshake-alt-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-rabbit-running {
  --fa: "";
  --fa--fa: "";
}

.fa-rabbit-fast {
  --fa: "";
  --fa--fa: "";
}

.fa-memo-pad {
  --fa: "";
  --fa--fa: "";
}

.fa-mattress-pillow {
  --fa: "";
  --fa--fa: "";
}

.fa-alarm-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-alicorn {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-question {
  --fa: "";
  --fa--fa: "";
}

.fa-gingerbread-man {
  --fa: "";
  --fa--fa: "";
}

.fa-guarani-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-burger-fries {
  --fa: "";
  --fa--fa: "";
}

.fa-mug-tea {
  --fa: "";
  --fa--fa: "";
}

.fa-border-top {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-rotate {
  --fa: "";
  --fa--fa: "";
}

.fa-refresh {
  --fa: "";
  --fa--fa: "";
}

.fa-sync {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-book-open {
  --fa: "";
  --fa--fa: "";
}

.fa-book-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-to-dotted-line {
  --fa: "";
  --fa--fa: "";
}

.fa-fire-extinguisher {
  --fa: "";
  --fa--fa: "";
}

.fa-magnifying-glass-arrows-rotate {
  --fa: "";
  --fa--fa: "";
}

.fa-garage-open {
  --fa: "";
  --fa--fa: "";
}

.fa-shelves-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-cruzeiro-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-watch-apple {
  --fa: "";
  --fa--fa: "";
}

.fa-watch-calculator {
  --fa: "";
  --fa--fa: "";
}

.fa-list-dropdown {
  --fa: "";
  --fa--fa: "";
}

.fa-cabinet-filing {
  --fa: "";
  --fa--fa: "";
}

.fa-burger-soda {
  --fa: "";
  --fa--fa: "";
}

.fa-square-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-square-up {
  --fa: "";
  --fa--fa: "";
}

.fa-greater-than-equal {
  --fa: "";
  --fa--fa: "";
}

.fa-pallet-box {
  --fa: "";
  --fa--fa: "";
}

.fa-face-confounded {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-halved {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-plow {
  --fa: "";
  --fa--fa: "";
}

.fa-book-atlas {
  --fa: "";
  --fa--fa: "";
}

.fa-atlas {
  --fa: "";
  --fa--fa: "";
}

.fa-virus {
  --fa: "";
  --fa--fa: "";
}

.fa-grid-round-2 {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-middle-top {
  --fa: "";
  --fa--fa: "";
}

.fa-wave {
  --fa: "";
  --fa--fa: "";
}

.fa-envelope-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-layer-group {
  --fa: "";
  --fa--fa: "";
}

.fa-restroom-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-to-dot {
  --fa: "";
  --fa--fa: "";
}

.fa-border-outer {
  --fa: "";
  --fa--fa: "";
}

.fa-hashtag-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-two-thirty {
  --fa: "";
  --fa--fa: "";
}

.fa-archway {
  --fa: "";
  --fa--fa: "";
}

.fa-heart-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-house-chimney-crack {
  --fa: "";
  --fa--fa: "";
}

.fa-house-damage {
  --fa: "";
  --fa--fa: "";
}

.fa-file-zipper {
  --fa: "";
  --fa--fa: "";
}

.fa-file-archive {
  --fa: "";
  --fa--fa: "";
}

.fa-ticket-perforated {
  --fa: "";
  --fa--fa: "";
}

.fa-heart-half {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-check {
  --fa: "";
  --fa--fa: "";
}

.fa-square {
  --fa: "";
  --fa--fa: "";
}

.fa-memo {
  --fa: "";
  --fa--fa: "";
}

.fa-martini-glass-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-glass-martini {
  --fa: "";
  --fa--fa: "";
}

.fa-couch {
  --fa: "";
  --fa--fa: "";
}

.fa-cedi-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-italic {
  --fa: "";
  --fa--fa: "";
}

.fa-glass-citrus {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-lines-pen {
  --fa: "";
  --fa--fa: "";
}

.fa-table-cells-column-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-church {
  --fa: "";
  --fa--fa: "";
}

.fa-person-snowmobiling {
  --fa: "";
  --fa--fa: "";
}

.fa-snowmobile {
  --fa: "";
  --fa--fa: "";
}

.fa-face-hushed {
  --fa: "";
  --fa--fa: "";
}

.fa-comments-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-tickets-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-pickaxe {
  --fa: "";
  --fa--fa: "";
}

.fa-link-simple-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-democrat {
  --fa: "";
  --fa--fa: "";
}

.fa-face-confused {
  --fa: "";
  --fa--fa: "";
}

.fa-pinball {
  --fa: "";
  --fa--fa: "";
}

.fa-z {
  --fa: "Z";
  --fa--fa: "ZZ";
}

.fa-person-skiing {
  --fa: "";
  --fa--fa: "";
}

.fa-skiing {
  --fa: "";
  --fa--fa: "";
}

.fa-deer {
  --fa: "";
  --fa--fa: "";
}

.fa-input-pipe {
  --fa: "";
  --fa--fa: "";
}

.fa-road-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-a {
  --fa: "A";
  --fa--fa: "AA";
}

.fa-bookmark-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-down {
  --fa: "";
  --fa--fa: "";
}

.fa-mace {
  --fa: "";
  --fa--fa: "";
}

.fa-feather-pointed {
  --fa: "";
  --fa--fa: "";
}

.fa-feather-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-sausage {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-can-clock {
  --fa: "";
  --fa--fa: "";
}

.fa-p {
  --fa: "P";
  --fa--fa: "PP";
}

.fa-broom-wide {
  --fa: "";
  --fa--fa: "";
}

.fa-snowflake {
  --fa: "";
  --fa--fa: "";
}

.fa-stomach {
  --fa: "";
  --fa--fa: "";
}

.fa-newspaper {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-ad {
  --fa: "";
  --fa--fa: "";
}

.fa-ad {
  --fa: "";
  --fa--fa: "";
}

.fa-guitar-electric {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-turn-down-right {
  --fa: "";
  --fa--fa: "";
}

.fa-moon-cloud {
  --fa: "";
  --fa--fa: "";
}

.fa-bread-slice-butter {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-circle-right {
  --fa: "";
  --fa--fa: "";
}

.fa-user-group-crown {
  --fa: "";
  --fa--fa: "";
}

.fa-users-crown {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-i {
  --fa: "";
  --fa--fa: "";
}

.fa-toilet-paper-check {
  --fa: "";
  --fa--fa: "";
}

.fa-filter-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-locust {
  --fa: "";
  --fa--fa: "";
}

.fa-sort {
  --fa: "";
  --fa--fa: "";
}

.fa-unsorted {
  --fa: "";
  --fa--fa: "";
}

.fa-list-ol {
  --fa: "";
  --fa--fa: "";
}

.fa-list-1-2 {
  --fa: "";
  --fa--fa: "";
}

.fa-list-numeric {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-waterfall {
  --fa: "";
  --fa--fa: "";
}

.fa-sparkle {
  --fa: "";
  --fa--fa: "";
}

.fa-face-party {
  --fa: "";
  --fa--fa: "";
}

.fa-kidneys {
  --fa: "";
  --fa--fa: "";
}

.fa-wifi-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-network {
  --fa: "";
  --fa--fa: "";
}

.fa-person-dress-burst {
  --fa: "";
  --fa--fa: "";
}

.fa-dice-d4 {
  --fa: "";
  --fa--fa: "";
}

.fa-money-check-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-money-check-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-vector-square {
  --fa: "";
  --fa--fa: "";
}

.fa-bread-slice {
  --fa: "";
  --fa--fa: "";
}

.fa-language {
  --fa: "";
  --fa--fa: "";
}

.fa-wheat-awn-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-face-kiss-wink-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-kiss-wink-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-dagger {
  --fa: "";
  --fa--fa: "";
}

.fa-podium {
  --fa: "";
  --fa--fa: "";
}

.fa-diamonds-4 {
  --fa: "";
  --fa--fa: "";
}

.fa-memo-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-route-highway {
  --fa: "";
  --fa--fa: "";
}

.fa-down-to-line {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-alt-to-bottom {
  --fa: "";
  --fa--fa: "";
}

.fa-filter {
  --fa: "";
  --fa--fa: "";
}

.fa-square-g {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-phone {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-clipboard-prescription {
  --fa: "";
  --fa--fa: "";
}

.fa-user-nurse-hair {
  --fa: "";
  --fa--fa: "";
}

.fa-question {
  --fa: "?";
  --fa--fa: "??";
}

.fa-file-signature {
  --fa: "";
  --fa--fa: "";
}

.fa-toggle-large-on {
  --fa: "";
  --fa--fa: "";
}

.fa-up-down-left-right {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-dryer-heat {
  --fa: "";
  --fa--fa: "";
}

.fa-dryer-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-house-chimney-user {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-holding-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-small-big {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-size-up-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-train-track {
  --fa: "";
  --fa--fa: "";
}

.fa-puzzle-piece {
  --fa: "";
  --fa--fa: "";
}

.fa-money-check {
  --fa: "";
  --fa--fa: "";
}

.fa-star-half-stroke {
  --fa: "";
  --fa--fa: "";
}

.fa-star-half-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-file-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-code {
  --fa: "";
  --fa--fa: "";
}

.fa-whiskey-glass {
  --fa: "";
  --fa--fa: "";
}

.fa-glass-whiskey {
  --fa: "";
  --fa--fa: "";
}

.fa-moon-stars {
  --fa: "";
  --fa--fa: "";
}

.fa-building-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-clothes-hanger {
  --fa: "";
  --fa--fa: "";
}

.fa-mobile-notch {
  --fa: "";
  --fa--fa: "";
}

.fa-mobile-iphone {
  --fa: "";
  --fa--fa: "";
}

.fa-magnifying-glass-chart {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-right-from-square {
  --fa: "";
  --fa--fa: "";
}

.fa-external-link {
  --fa: "";
  --fa--fa: "";
}

.fa-cubes-stacked {
  --fa: "";
  --fa--fa: "";
}

.fa-images-user {
  --fa: "";
  --fa--fa: "";
}

.fa-won-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-krw {
  --fa: "";
  --fa--fa: "";
}

.fa-won {
  --fa: "";
  --fa--fa: "";
}

.fa-image-polaroid-user {
  --fa: "";
  --fa--fa: "";
}

.fa-virus-covid {
  --fa: "";
  --fa--fa: "";
}

.fa-square-ellipsis {
  --fa: "";
  --fa--fa: "";
}

.fa-pie {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-knight-piece {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-knight-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-austral-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-f {
  --fa: "F";
  --fa--fa: "FF";
}

.fa-leaf {
  --fa: "";
  --fa--fa: "";
}

.fa-bed-bunk {
  --fa: "";
  --fa--fa: "";
}

.fa-road {
  --fa: "";
  --fa--fa: "";
}

.fa-taxi {
  --fa: "";
  --fa--fa: "";
}

.fa-cab {
  --fa: "";
  --fa--fa: "";
}

.fa-person-circle-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-pie {
  --fa: "";
  --fa--fa: "";
}

.fa-pie-chart {
  --fa: "";
  --fa--fa: "";
}

.fa-bolt-lightning {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-eight {
  --fa: "";
  --fa--fa: "";
}

.fa-sack-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-file-xls {
  --fa: "";
  --fa--fa: "";
}

.fa-file-excel {
  --fa: "";
  --fa--fa: "";
}

.fa-file-contract {
  --fa: "";
  --fa--fa: "";
}

.fa-fish-fins {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-q {
  --fa: "";
  --fa--fa: "";
}

.fa-building-flag {
  --fa: "";
  --fa--fa: "";
}

.fa-face-grin-beam {
  --fa: "";
  --fa--fa: "";
}

.fa-grin-beam {
  --fa: "";
  --fa--fa: "";
}

.fa-object-ungroup {
  --fa: "";
  --fa--fa: "";
}

.fa-face-disguise {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-arrow-down-right {
  --fa: "";
  --fa--fa: "";
}

.fa-alien-8bit {
  --fa: "";
  --fa--fa: "";
}

.fa-alien-monster {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-point-ribbon {
  --fa: "";
  --fa--fa: "";
}

.fa-poop {
  --fa: "";
  --fa--fa: "";
}

.fa-object-exclude {
  --fa: "";
  --fa--fa: "";
}

.fa-telescope {
  --fa: "";
  --fa--fa: "";
}

.fa-location-pin {
  --fa: "";
  --fa--fa: "";
}

.fa-map-marker {
  --fa: "";
  --fa--fa: "";
}

.fa-square-list {
  --fa: "";
  --fa--fa: "";
}

.fa-kaaba {
  --fa: "";
  --fa--fa: "";
}

.fa-toilet-paper {
  --fa: "";
  --fa--fa: "";
}

.fa-helmet-safety {
  --fa: "";
  --fa--fa: "";
}

.fa-hard-hat {
  --fa: "";
  --fa--fa: "";
}

.fa-hat-hard {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-code {
  --fa: "";
  --fa--fa: "";
}

.fa-sim-cards {
  --fa: "";
  --fa--fa: "";
}

.fa-starship {
  --fa: "";
  --fa--fa: "";
}

.fa-eject {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-right {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-alt-circle-right {
  --fa: "";
  --fa--fa: "";
}

.fa-plane-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-seal {
  --fa: "";
  --fa--fa: "";
}

.fa-user-cowboy {
  --fa: "";
  --fa--fa: "";
}

.fa-hexagon-vertical-nft {
  --fa: "";
  --fa--fa: "";
}

.fa-face-rolling-eyes {
  --fa: "";
  --fa--fa: "";
}

.fa-meh-rolling-eyes {
  --fa: "";
  --fa--fa: "";
}

.fa-bread-loaf {
  --fa: "";
  --fa--fa: "";
}

.fa-rings-wedding {
  --fa: "";
  --fa--fa: "";
}

.fa-object-group {
  --fa: "";
  --fa--fa: "";
}

.fa-french-fries {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-line {
  --fa: "";
  --fa--fa: "";
}

.fa-line-chart {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-download {
  --fa: "";
  --fa--fa: "";
}

.fa-send-back {
  --fa: "";
  --fa--fa: "";
}

.fa-mask-ventilator {
  --fa: "";
  --fa--fa: "";
}

.fa-tickets {
  --fa: "";
  --fa--fa: "";
}

.fa-signature-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-signs-post {
  --fa: "";
  --fa--fa: "";
}

.fa-map-signs {
  --fa: "";
  --fa--fa: "";
}

.fa-octagon-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-plus-octagon {
  --fa: "";
  --fa--fa: "";
}

.fa-cash-register {
  --fa: "";
  --fa--fa: "";
}

.fa-person-circle-question {
  --fa: "";
  --fa--fa: "";
}

.fa-melon-slice {
  --fa: "";
  --fa--fa: "";
}

.fa-space-station-moon {
  --fa: "";
  --fa--fa: "";
}

.fa-message-smile {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-alt-smile {
  --fa: "";
  --fa--fa: "";
}

.fa-cup-straw {
  --fa: "";
  --fa--fa: "";
}

.fa-left-from-line {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-alt-from-right {
  --fa: "";
  --fa--fa: "";
}

.fa-h {
  --fa: "H";
  --fa--fa: "HH";
}

.fa-basket-shopping-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-shopping-basket-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-hands-holding-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-hands-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-nine {
  --fa: "";
  --fa--fa: "";
}

.fa-hammer-brush {
  --fa: "";
  --fa--fa: "";
}

.fa-tarp {
  --fa: "";
  --fa--fa: "";
}

.fa-face-sleepy {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-horns {
  --fa: "";
  --fa--fa: "";
}

.fa-screwdriver-wrench {
  --fa: "";
  --fa--fa: "";
}

.fa-tools {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-to-eye {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-three-quarters {
  --fa: "";
  --fa--fa: "";
}

.fa-trophy-star {
  --fa: "";
  --fa--fa: "";
}

.fa-trophy-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-plug-circle-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-face-thermometer {
  --fa: "";
  --fa--fa: "";
}

.fa-grid-round-4 {
  --fa: "";
  --fa--fa: "";
}

.fa-sign-posts-wrench {
  --fa: "";
  --fa--fa: "";
}

.fa-shirt-running {
  --fa: "";
  --fa--fa: "";
}

.fa-book-circle-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-face-nauseated {
  --fa: "";
  --fa--fa: "";
}

.fa-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-file-chart-pie {
  --fa: "";
  --fa--fa: "";
}

.fa-mars-and-venus {
  --fa: "";
  --fa--fa: "";
}

.fa-house-user {
  --fa: "";
  --fa--fa: "";
}

.fa-home-user {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-arrow-down-left {
  --fa: "";
  --fa--fa: "";
}

.fa-dumpster-fire {
  --fa: "";
  --fa--fa: "";
}

.fa-hexagon-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-minus-hexagon {
  --fa: "";
  --fa--fa: "";
}

.fa-left-to-line {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-alt-to-left {
  --fa: "";
  --fa--fa: "";
}

.fa-house-crack {
  --fa: "";
  --fa--fa: "";
}

.fa-paw-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-paw-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-left-long-to-line {
  --fa: "";
  --fa--fa: "";
}

.fa-brackets-round {
  --fa: "";
  --fa--fa: "";
}

.fa-parentheses {
  --fa: "";
  --fa--fa: "";
}

.fa-martini-glass-citrus {
  --fa: "";
  --fa--fa: "";
}

.fa-cocktail {
  --fa: "";
  --fa--fa: "";
}

.fa-user-shakespeare {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-right-to-arc {
  --fa: "";
  --fa--fa: "";
}

.fa-face-surprise {
  --fa: "";
  --fa--fa: "";
}

.fa-surprise {
  --fa: "";
  --fa--fa: "";
}

.fa-bottle-water {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-pause {
  --fa: "";
  --fa--fa: "";
}

.fa-pause-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-gauge-circle-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-folders {
  --fa: "";
  --fa--fa: "";
}

.fa-angel {
  --fa: "";
  --fa--fa: "";
}

.fa-value-absolute {
  --fa: "";
  --fa--fa: "";
}

.fa-rabbit {
  --fa: "";
  --fa--fa: "";
}

.fa-toilet-paper-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-euro {
  --fa: "";
  --fa--fa: "";
}

.fa-apple-whole {
  --fa: "";
  --fa--fa: "";
}

.fa-apple-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-kitchen-set {
  --fa: "";
  --fa--fa: "";
}

.fa-diamond-half {
  --fa: "";
  --fa--fa: "";
}

.fa-lock-keyhole {
  --fa: "";
  --fa--fa: "";
}

.fa-lock-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-r {
  --fa: "R";
  --fa--fa: "RR";
}

.fa-temperature-quarter {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-1 {
  --fa: "";
  --fa--fa: "";
}

.fa-thermometer-1 {
  --fa: "";
  --fa--fa: "";
}

.fa-thermometer-quarter {
  --fa: "";
  --fa--fa: "";
}

.fa-square-info {
  --fa: "";
  --fa--fa: "";
}

.fa-info-square {
  --fa: "";
  --fa--fa: "";
}

.fa-wifi-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-toilet-paper-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-hands-holding-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-hands-usd {
  --fa: "";
  --fa--fa: "";
}

.fa-cube {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-triangle-square {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-shapes-down {
  --fa: "";
  --fa--fa: "";
}

.fa-bitcoin-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-shutters {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-dog {
  --fa: "";
  --fa--fa: "";
}

.fa-solar-panel {
  --fa: "";
  --fa--fa: "";
}

.fa-lock-open {
  --fa: "";
  --fa--fa: "";
}

.fa-table-tree {
  --fa: "";
  --fa--fa: "";
}

.fa-house-chimney-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-tally-3 {
  --fa: "";
  --fa--fa: "";
}

.fa-elevator {
  --fa: "";
  --fa--fa: "";
}

.fa-money-bill-transfer {
  --fa: "";
  --fa--fa: "";
}

.fa-money-bill-trend-up {
  --fa: "";
  --fa--fa: "";
}

.fa-house-flood-water-circle-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-square-poll-horizontal {
  --fa: "";
  --fa--fa: "";
}

.fa-poll-h {
  --fa: "";
  --fa--fa: "";
}

.fa-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-left-to-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-sword {
  --fa: "";
  --fa--fa: "";
}

.fa-backward-fast {
  --fa: "";
  --fa--fa: "";
}

.fa-fast-backward {
  --fa: "";
  --fa--fa: "";
}

.fa-recycle {
  --fa: "";
  --fa--fa: "";
}

.fa-user-astronaut {
  --fa: "";
  --fa--fa: "";
}

.fa-interrobang {
  --fa: "";
  --fa--fa: "";
}

.fa-plane-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-dashed {
  --fa: "";
  --fa--fa: "";
}

.fa-trademark {
  --fa: "";
  --fa--fa: "";
}

.fa-basketball {
  --fa: "";
  --fa--fa: "";
}

.fa-basketball-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-fork-knife {
  --fa: "";
  --fa--fa: "";
}

.fa-utensils-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-satellite-dish {
  --fa: "";
  --fa--fa: "";
}

.fa-badge-check {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-up {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-alt-circle-up {
  --fa: "";
  --fa--fa: "";
}

.fa-slider {
  --fa: "";
  --fa--fa: "";
}

.fa-mobile-screen-button {
  --fa: "";
  --fa--fa: "";
}

.fa-mobile-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-one-thirty {
  --fa: "";
  --fa--fa: "";
}

.fa-inbox-out {
  --fa: "";
  --fa--fa: "";
}

.fa-inbox-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-volume-high {
  --fa: "";
  --fa--fa: "";
}

.fa-volume-up {
  --fa: "";
  --fa--fa: "";
}

.fa-users-rays {
  --fa: "";
  --fa--fa: "";
}

.fa-wallet {
  --fa: "";
  --fa--fa: "";
}

.fa-octagon-check {
  --fa: "";
  --fa--fa: "";
}

.fa-flatbread-stuffed {
  --fa: "";
  --fa--fa: "";
}

.fa-clipboard-check {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-circle-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-clock {
  --fa: "";
  --fa--fa: "";
}

.fa-shipping-timed {
  --fa: "";
  --fa--fa: "";
}

.fa-pool-8-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-file-audio {
  --fa: "";
  --fa--fa: "";
}

.fa-turn-down-left {
  --fa: "";
  --fa--fa: "";
}

.fa-lock-hashtag {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-radar {
  --fa: "";
  --fa--fa: "";
}

.fa-staff {
  --fa: "";
  --fa--fa: "";
}

.fa-burger {
  --fa: "";
  --fa--fa: "";
}

.fa-hamburger {
  --fa: "";
  --fa--fa: "";
}

.fa-utility-pole {
  --fa: "";
  --fa--fa: "";
}

.fa-transporter-6 {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-turn-left {
  --fa: "";
  --fa--fa: "";
}

.fa-wrench {
  --fa: "";
  --fa--fa: "";
}

.fa-bugs {
  --fa: "";
  --fa--fa: "";
}

.fa-vector-polygon {
  --fa: "";
  --fa--fa: "";
}

.fa-diagram-nested {
  --fa: "";
  --fa--fa: "";
}

.fa-rupee-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-rupee {
  --fa: "";
  --fa--fa: "";
}

.fa-file-image {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-question {
  --fa: "";
  --fa--fa: "";
}

.fa-question-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-tickets-perforated {
  --fa: "";
  --fa--fa: "";
}

.fa-image-user {
  --fa: "";
  --fa--fa: "";
}

.fa-buoy {
  --fa: "";
  --fa--fa: "";
}

.fa-plane-departure {
  --fa: "";
  --fa--fa: "";
}

.fa-handshake-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-book-bookmark {
  --fa: "";
  --fa--fa: "";
}

.fa-border-center-h {
  --fa: "";
  --fa--fa: "";
}

.fa-can-food {
  --fa: "";
  --fa--fa: "";
}

.fa-typewriter {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-right-from-arc {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-k {
  --fa: "";
  --fa--fa: "";
}

.fa-face-hand-over-mouth {
  --fa: "";
  --fa--fa: "";
}

.fa-popcorn {
  --fa: "";
  --fa--fa: "";
}

.fa-house-water {
  --fa: "";
  --fa--fa: "";
}

.fa-house-flood {
  --fa: "";
  --fa--fa: "";
}

.fa-object-subtract {
  --fa: "";
  --fa--fa: "";
}

.fa-code-branch {
  --fa: "";
  --fa--fa: "";
}

.fa-warehouse-full {
  --fa: "";
  --fa--fa: "";
}

.fa-warehouse-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-hat-cowboy {
  --fa: "";
  --fa--fa: "";
}

.fa-bridge {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-flip {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-from-dotted-line {
  --fa: "";
  --fa--fa: "";
}

.fa-file-doc {
  --fa: "";
  --fa--fa: "";
}

.fa-square-quarters {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-front {
  --fa: "";
  --fa--fa: "";
}

.fa-cat {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-caret-left {
  --fa: "";
  --fa--fa: "";
}

.fa-caret-circle-left {
  --fa: "";
  --fa--fa: "";
}

.fa-files {
  --fa: "";
  --fa--fa: "";
}

.fa-anchor-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-face-clouds {
  --fa: "";
  --fa--fa: "";
}

.fa-user-crown {
  --fa: "";
  --fa--fa: "";
}

.fa-basket-shopping-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-field {
  --fa: "";
  --fa--fa: "";
}

.fa-route {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-clipboard-question {
  --fa: "";
  --fa--fa: "";
}

.fa-panorama {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-teeth-open {
  --fa: "";
  --fa--fa: "";
}

.fa-user-tie-hair-long {
  --fa: "";
  --fa--fa: "";
}

.fa-file-circle-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-head-side-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-turn-right {
  --fa: "";
  --fa--fa: "";
}

.fa-tags {
  --fa: "";
  --fa--fa: "";
}

.fa-wine-glass {
  --fa: "";
  --fa--fa: "";
}

.fa-forward-fast {
  --fa: "";
  --fa--fa: "";
}

.fa-fast-forward {
  --fa: "";
  --fa--fa: "";
}

.fa-face-meh-blank {
  --fa: "";
  --fa--fa: "";
}

.fa-meh-blank {
  --fa: "";
  --fa--fa: "";
}

.fa-user-robot {
  --fa: "";
  --fa--fa: "";
}

.fa-square-parking {
  --fa: "";
  --fa--fa: "";
}

.fa-parking {
  --fa: "";
  --fa--fa: "";
}

.fa-card-diamond {
  --fa: "";
  --fa--fa: "";
}

.fa-face-zipper {
  --fa: "";
  --fa--fa: "";
}

.fa-face-raised-eyebrow {
  --fa: "";
  --fa--fa: "";
}

.fa-house-signal {
  --fa: "";
  --fa--fa: "";
}

.fa-square-chevron-up {
  --fa: "";
  --fa--fa: "";
}

.fa-chevron-square-up {
  --fa: "";
  --fa--fa: "";
}

.fa-bars-progress {
  --fa: "";
  --fa--fa: "";
}

.fa-tasks-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-faucet-drip {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-to-line {
  --fa: "";
  --fa--fa: "";
}

.fa-dolphin {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-right {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-r {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-flatbed {
  --fa: "";
  --fa--fa: "";
}

.fa-dolly-flatbed {
  --fa: "";
  --fa--fa: "";
}

.fa-ban-smoking {
  --fa: "";
  --fa--fa: "";
}

.fa-smoking-ban {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-sort-up {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-circle-up {
  --fa: "";
  --fa--fa: "";
}

.fa-terminal {
  --fa: "";
  --fa--fa: "";
}

.fa-mobile-button {
  --fa: "";
  --fa--fa: "";
}

.fa-house-medical-flag {
  --fa: "";
  --fa--fa: "";
}

.fa-basket-shopping {
  --fa: "";
  --fa--fa: "";
}

.fa-shopping-basket {
  --fa: "";
  --fa--fa: "";
}

.fa-tape {
  --fa: "";
  --fa--fa: "";
}

.fa-chestnut {
  --fa: "";
  --fa--fa: "";
}

.fa-bus-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-bus-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-eye {
  --fa: "";
  --fa--fa: "";
}

.fa-face-sad-cry {
  --fa: "";
  --fa--fa: "";
}

.fa-sad-cry {
  --fa: "";
  --fa--fa: "";
}

.fa-heat {
  --fa: "";
  --fa--fa: "";
}

.fa-ticket-airline {
  --fa: "";
  --fa--fa: "";
}

.fa-ticket-perforated-plane {
  --fa: "";
  --fa--fa: "";
}

.fa-ticket-plane {
  --fa: "";
  --fa--fa: "";
}

.fa-boot-heeled {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-minimize {
  --fa: "";
  --fa--fa: "";
}

.fa-compress-arrows {
  --fa: "";
  --fa--fa: "";
}

.fa-audio-description {
  --fa: "";
  --fa--fa: "";
}

.fa-person-military-to-person {
  --fa: "";
  --fa--fa: "";
}

.fa-file-shield {
  --fa: "";
  --fa--fa: "";
}

.fa-hexagon {
  --fa: "";
  --fa--fa: "";
}

.fa-manhole {
  --fa: "";
  --fa--fa: "";
}

.fa-user-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-pen {
  --fa: "";
  --fa--fa: "";
}

.fa-tower-observation {
  --fa: "";
  --fa--fa: "";
}

.fa-floppy-disks {
  --fa: "";
  --fa--fa: "";
}

.fa-toilet-paper-blank-under {
  --fa: "";
  --fa--fa: "";
}

.fa-toilet-paper-reverse-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-file-code {
  --fa: "";
  --fa--fa: "";
}

.fa-signal {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-5 {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-perfect {
  --fa: "";
  --fa--fa: "";
}

.fa-pump {
  --fa: "";
  --fa--fa: "";
}

.fa-bus {
  --fa: "";
  --fa--fa: "";
}

.fa-heart-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-left-from-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-house-chimney {
  --fa: "";
  --fa--fa: "";
}

.fa-home-lg {
  --fa: "";
  --fa--fa: "";
}

.fa-window-maximize {
  --fa: "";
  --fa--fa: "";
}

.fa-dryer {
  --fa: "";
  --fa--fa: "";
}

.fa-face-frown {
  --fa: "";
  --fa--fa: "";
}

.fa-frown {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-bishop-piece {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-bishop-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-shirt-tank-top {
  --fa: "";
  --fa--fa: "";
}

.fa-diploma {
  --fa: "";
  --fa--fa: "";
}

.fa-scroll-ribbon {
  --fa: "";
  --fa--fa: "";
}

.fa-screencast {
  --fa: "";
  --fa--fa: "";
}

.fa-walker {
  --fa: "";
  --fa--fa: "";
}

.fa-prescription {
  --fa: "";
  --fa--fa: "";
}

.fa-shop {
  --fa: "";
  --fa--fa: "";
}

.fa-store-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-floppy-disk {
  --fa: "";
  --fa--fa: "";
}

.fa-save {
  --fa: "";
  --fa--fa: "";
}

.fa-vihara {
  --fa: "";
  --fa--fa: "";
}

.fa-face-kiss-closed-eyes {
  --fa: "";
  --fa--fa: "";
}

.fa-scale-unbalanced {
  --fa: "";
  --fa--fa: "";
}

.fa-balance-scale-left {
  --fa: "";
  --fa--fa: "";
}

.fa-file-user {
  --fa: "";
  --fa--fa: "";
}

.fa-user-police-tie {
  --fa: "";
  --fa--fa: "";
}

.fa-face-tongue-money {
  --fa: "";
  --fa--fa: "";
}

.fa-tennis-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-square-l {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-up {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-asc {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-upload {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-dots {
  --fa: "";
  --fa--fa: "";
}

.fa-commenting {
  --fa: "";
  --fa--fa: "";
}

.fa-plant-wilt {
  --fa: "";
  --fa--fa: "";
}

.fa-scarf {
  --fa: "";
  --fa--fa: "";
}

.fa-album-circle-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-user-nurse-hair-long {
  --fa: "";
  --fa--fa: "";
}

.fa-diamond {
  --fa: "";
  --fa--fa: "";
}

.fa-square-left {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-alt-square-left {
  --fa: "";
  --fa--fa: "";
}

.fa-face-grin-squint {
  --fa: "";
  --fa--fa: "";
}

.fa-grin-squint {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-ellipsis-vertical {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-holding-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-holding-usd {
  --fa: "";
  --fa--fa: "";
}

.fa-grid-dividers {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-diagram {
  --fa: "";
  --fa--fa: "";
}

.fa-bacterium {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-pointer {
  --fa: "";
  --fa--fa: "";
}

.fa-drum-steelpan {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-scissors {
  --fa: "";
  --fa--fa: "";
}

.fa-hands-praying {
  --fa: "";
  --fa--fa: "";
}

.fa-praying-hands {
  --fa: "";
  --fa--fa: "";
}

.fa-face-pensive {
  --fa: "";
  --fa--fa: "";
}

.fa-user-music {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-rotate-right {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-right-rotate {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-rotate-forward {
  --fa: "";
  --fa--fa: "";
}

.fa-redo {
  --fa: "";
  --fa--fa: "";
}

.fa-messages-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-comments-alt-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-sensor-on {
  --fa: "";
  --fa--fa: "";
}

.fa-balloon {
  --fa: "";
  --fa--fa: "";
}

.fa-biohazard {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-queen-piece {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-queen-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-location-crosshairs {
  --fa: "";
  --fa--fa: "";
}

.fa-location {
  --fa: "";
  --fa--fa: "";
}

.fa-mars-double {
  --fa: "";
  --fa--fa: "";
}

.fa-left-from-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-house-person-leave {
  --fa: "";
  --fa--fa: "";
}

.fa-house-leave {
  --fa: "";
  --fa--fa: "";
}

.fa-house-person-depart {
  --fa: "";
  --fa--fa: "";
}

.fa-ruler-triangle {
  --fa: "";
  --fa--fa: "";
}

.fa-card-club {
  --fa: "";
  --fa--fa: "";
}

.fa-child-dress {
  --fa: "";
  --fa--fa: "";
}

.fa-users-between-lines {
  --fa: "";
  --fa--fa: "";
}

.fa-lungs-virus {
  --fa: "";
  --fa--fa: "";
}

.fa-spinner-third {
  --fa: "";
  --fa--fa: "";
}

.fa-face-grin-tears {
  --fa: "";
  --fa--fa: "";
}

.fa-grin-tears {
  --fa: "";
  --fa--fa: "";
}

.fa-phone {
  --fa: "";
  --fa--fa: "";
}

.fa-computer-mouse-scrollwheel {
  --fa: "";
  --fa--fa: "";
}

.fa-mouse-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-times {
  --fa: "";
  --fa--fa: "";
}

.fa-child-reaching {
  --fa: "";
  --fa--fa: "";
}

.fa-table-layout {
  --fa: "";
  --fa--fa: "";
}

.fa-narwhal {
  --fa: "";
  --fa--fa: "";
}

.fa-ramp-loading {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-circle-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-toothbrush {
  --fa: "";
  --fa--fa: "";
}

.fa-border-inner {
  --fa: "";
  --fa--fa: "";
}

.fa-paw-claws {
  --fa: "";
  --fa--fa: "";
}

.fa-kiwi-fruit {
  --fa: "";
  --fa--fa: "";
}

.fa-traffic-light-slow {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-code {
  --fa: "";
  --fa--fa: "";
}

.fa-head-side-virus {
  --fa: "";
  --fa--fa: "";
}

.fa-keyboard-brightness {
  --fa: "";
  --fa--fa: "";
}

.fa-books-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-lightbulb-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-house-blank {
  --fa: "";
  --fa--fa: "";
}

.fa-home-blank {
  --fa: "";
  --fa--fa: "";
}

.fa-square-5 {
  --fa: "";
  --fa--fa: "";
}

.fa-square-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-heart-square {
  --fa: "";
  --fa--fa: "";
}

.fa-puzzle {
  --fa: "";
  --fa--fa: "";
}

.fa-user-gear {
  --fa: "";
  --fa--fa: "";
}

.fa-user-cog {
  --fa: "";
  --fa--fa: "";
}

.fa-pipe-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-1-9 {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-numeric-up {
  --fa: "";
  --fa--fa: "";
}

.fa-octagon-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-dial-low {
  --fa: "";
  --fa--fa: "";
}

.fa-door-closed {
  --fa: "";
  --fa--fa: "";
}

.fa-laptop-mobile {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-laptop {
  --fa: "";
  --fa--fa: "";
}

.fa-conveyor-belt-boxes {
  --fa: "";
  --fa--fa: "";
}

.fa-conveyor-belt-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-virus {
  --fa: "";
  --fa--fa: "";
}

.fa-starfighter-twin-ion-engine-advanced {
  --fa: "";
  --fa--fa: "";
}

.fa-starfighter-alt-advanced {
  --fa: "";
  --fa--fa: "";
}

.fa-dice-six {
  --fa: "";
  --fa--fa: "";
}

.fa-starfighter-twin-ion-engine {
  --fa: "";
  --fa--fa: "";
}

.fa-starfighter-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-rocket-launch {
  --fa: "";
  --fa--fa: "";
}

.fa-mosquito-net {
  --fa: "";
  --fa--fa: "";
}

.fa-file-fragment {
  --fa: "";
  --fa--fa: "";
}

.fa-vent-damper {
  --fa: "";
  --fa--fa: "";
}

.fa-bridge-water {
  --fa: "";
  --fa--fa: "";
}

.fa-ban-bug {
  --fa: "";
  --fa--fa: "";
}

.fa-debug {
  --fa: "";
  --fa--fa: "";
}

.fa-person-booth {
  --fa: "";
  --fa--fa: "";
}

.fa-text-width {
  --fa: "";
  --fa--fa: "";
}

.fa-garage-car {
  --fa: "";
  --fa--fa: "";
}

.fa-square-kanban {
  --fa: "";
  --fa--fa: "";
}

.fa-hat-wizard {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-kanban {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-fancy {
  --fa: "";
  --fa--fa: "";
}

.fa-coffee-pot {
  --fa: "";
  --fa--fa: "";
}

.fa-mouse-field {
  --fa: "";
  --fa--fa: "";
}

.fa-person-digging {
  --fa: "";
  --fa--fa: "";
}

.fa-digging {
  --fa: "";
  --fa--fa: "";
}

.fa-shower-down {
  --fa: "";
  --fa--fa: "";
}

.fa-shower-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-box-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-brightness {
  --fa: "";
  --fa--fa: "";
}

.fa-car-side-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-file-xml {
  --fa: "";
  --fa--fa: "";
}

.fa-ornament {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-arrow-down-left {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-incoming {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-word {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-fingers-crossed {
  --fa: "";
  --fa--fa: "";
}

.fa-trash {
  --fa: "";
  --fa--fa: "";
}

.fa-gauge-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-gauge-simple-med {
  --fa: "";
  --fa--fa: "";
}

.fa-tachometer-average {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-small-big {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-size-down-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-book-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-face-melting {
  --fa: "";
  --fa--fa: "";
}

.fa-poo {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-clip-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-alt-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-quote-right {
  --fa: "";
  --fa--fa: "";
}

.fa-quote-right-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-scroll-old {
  --fa: "";
  --fa--fa: "";
}

.fa-guitars {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-hose {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-six {
  --fa: "";
  --fa--fa: "";
}

.fa-shirt {
  --fa: "";
  --fa--fa: "";
}

.fa-t-shirt {
  --fa: "";
  --fa--fa: "";
}

.fa-tshirt {
  --fa: "";
  --fa--fa: "";
}

.fa-billboard {
  --fa: "";
  --fa--fa: "";
}

.fa-square-r {
  --fa: "";
  --fa--fa: "";
}

.fa-cubes {
  --fa: "";
  --fa--fa: "";
}

.fa-envelope-open-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-divide {
  --fa: "";
  --fa--fa: "";
}

.fa-sun-cloud {
  --fa: "";
  --fa--fa: "";
}

.fa-lamp-floor {
  --fa: "";
  --fa--fa: "";
}

.fa-square-7 {
  --fa: "";
  --fa--fa: "";
}

.fa-tenge-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-tenge {
  --fa: "";
  --fa--fa: "";
}

.fa-headphones {
  --fa: "";
  --fa--fa: "";
}

.fa-hands-holding {
  --fa: "";
  --fa--fa: "";
}

.fa-campfire {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-ampersand {
  --fa: "";
  --fa--fa: "";
}

.fa-snowflakes {
  --fa: "";
  --fa--fa: "";
}

.fa-hands-clapping {
  --fa: "";
  --fa--fa: "";
}

.fa-republican {
  --fa: "";
  --fa--fa: "";
}

.fa-leaf-maple {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-left {
  --fa: "";
  --fa--fa: "";
}

.fa-person-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-ruler {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-left-from-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-cup-straw-swoosh {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-sun {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-hot {
  --fa: "";
  --fa--fa: "";
}

.fa-align-left {
  --fa: "";
  --fa--fa: "";
}

.fa-dice-d6 {
  --fa: "";
  --fa--fa: "";
}

.fa-restroom {
  --fa: "";
  --fa--fa: "";
}

.fa-high-definition {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-hd {
  --fa: "";
  --fa--fa: "";
}

.fa-j {
  --fa: "J";
  --fa--fa: "JJ";
}

.fa-galaxy {
  --fa: "";
  --fa--fa: "";
}

.fa-users-viewfinder {
  --fa: "";
  --fa--fa: "";
}

.fa-file-video {
  --fa: "";
  --fa--fa: "";
}

.fa-cherries {
  --fa: "";
  --fa--fa: "";
}

.fa-up-right-from-square {
  --fa: "";
  --fa--fa: "";
}

.fa-external-link-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-sort {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-table-cells {
  --fa: "";
  --fa--fa: "";
}

.fa-th {
  --fa: "";
  --fa--fa: "";
}

.fa-bag-shopping-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-file-pdf {
  --fa: "";
  --fa--fa: "";
}

.fa-siren {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-to-dotted-line {
  --fa: "";
  --fa--fa: "";
}

.fa-image-landscape {
  --fa: "";
  --fa--fa: "";
}

.fa-landscape {
  --fa: "";
  --fa--fa: "";
}

.fa-tank-water {
  --fa: "";
  --fa--fa: "";
}

.fa-curling-stone {
  --fa: "";
  --fa--fa: "";
}

.fa-curling {
  --fa: "";
  --fa--fa: "";
}

.fa-gamepad-modern {
  --fa: "";
  --fa--fa: "";
}

.fa-gamepad-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-messages-question {
  --fa: "";
  --fa--fa: "";
}

.fa-book-bible {
  --fa: "";
  --fa--fa: "";
}

.fa-bible {
  --fa: "";
  --fa--fa: "";
}

.fa-o {
  --fa: "O";
  --fa--fa: "OO";
}

.fa-suitcase-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-medkit {
  --fa: "";
  --fa--fa: "";
}

.fa-briefcase-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-expand-wide {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-eleven-thirty {
  --fa: "";
  --fa--fa: "";
}

.fa-rv {
  --fa: "";
  --fa--fa: "";
}

.fa-user-secret {
  --fa: "";
  --fa--fa: "";
}

.fa-otter {
  --fa: "";
  --fa--fa: "";
}

.fa-dreidel {
  --fa: "";
  --fa--fa: "";
}

.fa-person-dress {
  --fa: "";
  --fa--fa: "";
}

.fa-female {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-business-time {
  --fa: "";
  --fa--fa: "";
}

.fa-briefcase-clock {
  --fa: "";
  --fa--fa: "";
}

.fa-flower-tulip {
  --fa: "";
  --fa--fa: "";
}

.fa-people-pants-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-drizzle {
  --fa: "";
  --fa--fa: "";
}

.fa-table-cells-large {
  --fa: "";
  --fa--fa: "";
}

.fa-th-large {
  --fa: "";
  --fa--fa: "";
}

.fa-book-tanakh {
  --fa: "";
  --fa--fa: "";
}

.fa-tanakh {
  --fa: "";
  --fa--fa: "";
}

.fa-solar-system {
  --fa: "";
  --fa--fa: "";
}

.fa-seal-question {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-volume {
  --fa: "";
  --fa--fa: "";
}

.fa-volume-control-phone {
  --fa: "";
  --fa--fa: "";
}

.fa-disc-drive {
  --fa: "";
  --fa--fa: "";
}

.fa-hat-cowboy-side {
  --fa: "";
  --fa--fa: "";
}

.fa-table-rows {
  --fa: "";
  --fa--fa: "";
}

.fa-rows {
  --fa: "";
  --fa--fa: "";
}

.fa-location-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-map-marker-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-face-fearful {
  --fa: "";
  --fa--fa: "";
}

.fa-clipboard-user {
  --fa: "";
  --fa--fa: "";
}

.fa-bus-school {
  --fa: "";
  --fa--fa: "";
}

.fa-film-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-square-arrow-down-right {
  --fa: "";
  --fa--fa: "";
}

.fa-book-sparkles {
  --fa: "";
  --fa--fa: "";
}

.fa-book-spells {
  --fa: "";
  --fa--fa: "";
}

.fa-washing-machine {
  --fa: "";
  --fa--fa: "";
}

.fa-washer {
  --fa: "";
  --fa--fa: "";
}

.fa-child {
  --fa: "";
  --fa--fa: "";
}

.fa-lira-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-user-visor {
  --fa: "";
  --fa--fa: "";
}

.fa-file-plus-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-clock-flip {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-clock-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-satellite {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-fire {
  --fa: "";
  --fa--fa: "";
}

.fa-plane-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-steering-wheel {
  --fa: "";
  --fa--fa: "";
}

.fa-tag {
  --fa: "";
  --fa--fa: "";
}

.fa-stretcher {
  --fa: "";
  --fa--fa: "";
}

.fa-book-section {
  --fa: "";
  --fa--fa: "";
}

.fa-book-law {
  --fa: "";
  --fa--fa: "";
}

.fa-inboxes {
  --fa: "";
  --fa--fa: "";
}

.fa-coffee-bean {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-yen {
  --fa: "";
  --fa--fa: "";
}

.fa-brackets-curly {
  --fa: "";
  --fa--fa: "";
}

.fa-ellipsis-stroke-vertical {
  --fa: "";
  --fa--fa: "";
}

.fa-ellipsis-v-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-comment {
  --fa: "";
  --fa--fa: "";
}

.fa-square-1 {
  --fa: "";
  --fa--fa: "";
}

.fa-cake-candles {
  --fa: "";
  --fa--fa: "";
}

.fa-birthday-cake {
  --fa: "";
  --fa--fa: "";
}

.fa-cake {
  --fa: "";
  --fa--fa: "";
}

.fa-head-side {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-ladder {
  --fa: "";
  --fa--fa: "";
}

.fa-envelope {
  --fa: "";
  --fa--fa: "";
}

.fa-dolly-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-face-tissue {
  --fa: "";
  --fa--fa: "";
}

.fa-angles-up {
  --fa: "";
  --fa--fa: "";
}

.fa-angle-double-up {
  --fa: "";
  --fa--fa: "";
}

.fa-bin-recycle {
  --fa: "";
  --fa--fa: "";
}

.fa-paperclip {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-line-down {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-right-to-city {
  --fa: "";
  --fa--fa: "";
}

.fa-lock-a {
  --fa: "";
  --fa--fa: "";
}

.fa-ribbon {
  --fa: "";
  --fa--fa: "";
}

.fa-lungs {
  --fa: "";
  --fa--fa: "";
}

.fa-person-pinball {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-9-1 {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-numeric-up-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-apple-core {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-y {
  --fa: "";
  --fa--fa: "";
}

.fa-h6 {
  --fa: "";
  --fa--fa: "";
}

.fa-litecoin-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-bottle-baby {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-small {
  --fa: "";
  --fa--fa: "";
}

.fa-border-none {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-turn-down-left {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-wifi-circle-wifi {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-wifi-group {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-nodes {
  --fa: "";
  --fa--fa: "";
}

.fa-parachute-box {
  --fa: "";
  --fa--fa: "";
}

.fa-reflect-horizontal {
  --fa: "";
  --fa--fa: "";
}

.fa-message-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-alt-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-rugby-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-music {
  --fa: "";
  --fa--fa: "";
}

.fa-indent {
  --fa: "";
  --fa--fa: "";
}

.fa-tree-deciduous {
  --fa: "";
  --fa--fa: "";
}

.fa-tree-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-puzzle-piece-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-puzzle-piece-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-field-un {
  --fa: "";
  --fa--fa: "";
}

.fa-nfc-trash {
  --fa: "";
  --fa--fa: "";
}

.fa-hourglass {
  --fa: "";
  --fa--fa: "";
}

.fa-hourglass-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-mountain {
  --fa: "";
  --fa--fa: "";
}

.fa-file-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-file-times {
  --fa: "";
  --fa--fa: "";
}

.fa-house-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-home-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-house-chimney-blank {
  --fa: "";
  --fa--fa: "";
}

.fa-meter-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-user-doctor {
  --fa: "";
  --fa--fa: "";
}

.fa-user-md {
  --fa: "";
  --fa--fa: "";
}

.fa-slash-back {
  --fa: "\\";
  --fa--fa: "\\\\";
}

.fa-circle-info {
  --fa: "";
  --fa--fa: "";
}

.fa-info-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-fishing-rod {
  --fa: "";
  --fa--fa: "";
}

.fa-hammer-crash {
  --fa: "";
  --fa--fa: "";
}

.fa-message-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-meatball {
  --fa: "";
  --fa--fa: "";
}

.fa-camera-polaroid {
  --fa: "";
  --fa--fa: "";
}

.fa-camera {
  --fa: "";
  --fa--fa: "";
}

.fa-camera-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-square-virus {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-meteor {
  --fa: "";
  --fa--fa: "";
}

.fa-car-on {
  --fa: "";
  --fa--fa: "";
}

.fa-sleigh {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-1-9 {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-numeric-asc {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-numeric-down {
  --fa: "";
  --fa--fa: "";
}

.fa-buoy-mooring {
  --fa: "";
  --fa--fa: "";
}

.fa-square-4 {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-holding-droplet {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-holding-water {
  --fa: "";
  --fa--fa: "";
}

.fa-file-eps {
  --fa: "";
  --fa--fa: "";
}

.fa-tricycle-adult {
  --fa: "";
  --fa--fa: "";
}

.fa-waveform {
  --fa: "";
  --fa--fa: "";
}

.fa-water {
  --fa: "";
  --fa--fa: "";
}

.fa-star-sharp-half-stroke {
  --fa: "";
  --fa--fa: "";
}

.fa-star-sharp-half-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-nfc-signal {
  --fa: "";
  --fa--fa: "";
}

.fa-plane-prop {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-check {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-desk {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-clock {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-time {
  --fa: "";
  --fa--fa: "";
}

.fa-braille {
  --fa: "";
  --fa--fa: "";
}

.fa-prescription-bottle-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-prescription-bottle-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-plate-utensils {
  --fa: "";
  --fa--fa: "";
}

.fa-family-pants {
  --fa: "";
  --fa--fa: "";
}

.fa-hose-reel {
  --fa: "";
  --fa--fa: "";
}

.fa-house-window {
  --fa: "";
  --fa--fa: "";
}

.fa-landmark {
  --fa: "";
  --fa--fa: "";
}

.fa-truck {
  --fa: "";
  --fa--fa: "";
}

.fa-music-magnifying-glass {
  --fa: "";
  --fa--fa: "";
}

.fa-crosshairs {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-rainbow {
  --fa: "";
  --fa--fa: "";
}

.fa-person-cane {
  --fa: "";
  --fa--fa: "";
}

.fa-alien {
  --fa: "";
  --fa--fa: "";
}

.fa-tent {
  --fa: "";
  --fa--fa: "";
}

.fa-laptop-binary {
  --fa: "";
  --fa--fa: "";
}

.fa-vest-patches {
  --fa: "";
  --fa--fa: "";
}

.fa-people-dress-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-check-double {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-a-z {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-alpha-asc {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-alpha-down {
  --fa: "";
  --fa--fa: "";
}

.fa-bowling-ball-pin {
  --fa: "";
  --fa--fa: "";
}

.fa-bell-school-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-plus-large {
  --fa: "";
  --fa--fa: "";
}

.fa-money-bill-wheat {
  --fa: "";
  --fa--fa: "";
}

.fa-camera-viewfinder {
  --fa: "";
  --fa--fa: "";
}

.fa-screenshot {
  --fa: "";
  --fa--fa: "";
}

.fa-message-music {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-alt-music {
  --fa: "";
  --fa--fa: "";
}

.fa-car-building {
  --fa: "";
  --fa--fa: "";
}

.fa-border-bottom-right {
  --fa: "";
  --fa--fa: "";
}

.fa-border-style-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-octagon {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-arrow-up-right {
  --fa: "";
  --fa--fa: "";
}

.fa-octagon-divide {
  --fa: "";
  --fa--fa: "";
}

.fa-cookie {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-rotate-left {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-left-rotate {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-rotate-back {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-rotate-backward {
  --fa: "";
  --fa--fa: "";
}

.fa-undo {
  --fa: "";
  --fa--fa: "";
}

.fa-tv-music {
  --fa: "";
  --fa--fa: "";
}

.fa-hard-drive {
  --fa: "";
  --fa--fa: "";
}

.fa-hdd {
  --fa: "";
  --fa--fa: "";
}

.fa-reel {
  --fa: "";
  --fa--fa: "";
}

.fa-face-grin-squint-tears {
  --fa: "";
  --fa--fa: "";
}

.fa-grin-squint-tears {
  --fa: "";
  --fa--fa: "";
}

.fa-dumbbell {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-list {
  --fa: "";
  --fa--fa: "";
}

.fa-list-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-tarp-droplet {
  --fa: "";
  --fa--fa: "";
}

.fa-alarm-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-house-medical-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-traffic-cone {
  --fa: "";
  --fa--fa: "";
}

.fa-grate {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-right {
  --fa: "";
  --fa--fa: "";
}

.fa-person-skiing-nordic {
  --fa: "";
  --fa--fa: "";
}

.fa-skiing-nordic {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-person-from-portal {
  --fa: "";
  --fa--fa: "";
}

.fa-portal-exit {
  --fa: "";
  --fa--fa: "";
}

.fa-plane-arrival {
  --fa: "";
  --fa--fa: "";
}

.fa-cowbell-circle-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-cowbell-more {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-left {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-alt-circle-left {
  --fa: "";
  --fa--fa: "";
}

.fa-distribute-spacing-vertical {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-bars-fair {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-alt-2 {
  --fa: "";
  --fa--fa: "";
}

.fa-sportsball {
  --fa: "";
  --fa--fa: "";
}

.fa-game-console-handheld-crank {
  --fa: "";
  --fa--fa: "";
}

.fa-train-subway {
  --fa: "";
  --fa--fa: "";
}

.fa-subway {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-gantt {
  --fa: "";
  --fa--fa: "";
}

.fa-face-smile-upside-down {
  --fa: "";
  --fa--fa: "";
}

.fa-ball-pile {
  --fa: "";
  --fa--fa: "";
}

.fa-badge-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-money-bills-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-money-bills-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-list-timeline {
  --fa: "";
  --fa--fa: "";
}

.fa-indian-rupee-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-indian-rupee {
  --fa: "";
  --fa--fa: "";
}

.fa-inr {
  --fa: "";
  --fa--fa: "";
}

.fa-crop-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-crop-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-money-bill-1 {
  --fa: "";
  --fa--fa: "";
}

.fa-money-bill-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-left-long {
  --fa: "";
  --fa--fa: "";
}

.fa-long-arrow-alt-left {
  --fa: "";
  --fa--fa: "";
}

.fa-keyboard-down {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-up-right {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-bolt-moon {
  --fa: "";
  --fa--fa: "";
}

.fa-thunderstorm-moon {
  --fa: "";
  --fa--fa: "";
}

.fa-turn-left-up {
  --fa: "";
  --fa--fa: "";
}

.fa-dna {
  --fa: "";
  --fa--fa: "";
}

.fa-virus-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-bracket-round-right {
  --fa: ")";
  --fa--fa: "))";
}

.fa-circle-sterling {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-5 {
  --fa: "";
  --fa--fa: "";
}

.fa-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-subtract {
  --fa: "";
  --fa--fa: "";
}

.fa-fire-flame {
  --fa: "";
  --fa--fa: "";
}

.fa-flame {
  --fa: "";
  --fa--fa: "";
}

.fa-right-to-line {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-alt-to-right {
  --fa: "";
  --fa--fa: "";
}

.fa-gif {
  --fa: "";
  --fa--fa: "";
}

.fa-chess {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-left-long {
  --fa: "";
  --fa--fa: "";
}

.fa-long-arrow-left {
  --fa: "";
  --fa--fa: "";
}

.fa-plug-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-font-case {
  --fa: "";
  --fa--fa: "";
}

.fa-street-view {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-left {
  --fa: "";
  --fa--fa: "";
}

.fa-franc-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-flask-round-poison {
  --fa: "";
  --fa--fa: "";
}

.fa-flask-poison {
  --fa: "";
  --fa--fa: "";
}

.fa-volume-off {
  --fa: "";
  --fa--fa: "";
}

.fa-book-circle-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-user {
  --fa: "";
  --fa--fa: "";
}

.fa-user-chart {
  --fa: "";
  --fa--fa: "";
}

.fa-hands-asl-interpreting {
  --fa: "";
  --fa--fa: "";
}

.fa-american-sign-language-interpreting {
  --fa: "";
  --fa--fa: "";
}

.fa-asl-interpreting {
  --fa: "";
  --fa--fa: "";
}

.fa-hands-american-sign-language-interpreting {
  --fa: "";
  --fa--fa: "";
}

.fa-presentation-screen {
  --fa: "";
  --fa--fa: "";
}

.fa-presentation {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-face-smile-halo {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-circle-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-house-person-return {
  --fa: "";
  --fa--fa: "";
}

.fa-house-person-arrive {
  --fa: "";
  --fa--fa: "";
}

.fa-house-return {
  --fa: "";
  --fa--fa: "";
}

.fa-message-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-alt-times {
  --fa: "";
  --fa--fa: "";
}

.fa-message-times {
  --fa: "";
  --fa--fa: "";
}

.fa-file-certificate {
  --fa: "";
  --fa--fa: "";
}

.fa-file-award {
  --fa: "";
  --fa--fa: "";
}

.fa-user-doctor-hair-long {
  --fa: "";
  --fa--fa: "";
}

.fa-camera-security {
  --fa: "";
  --fa--fa: "";
}

.fa-camera-home {
  --fa: "";
  --fa--fa: "";
}

.fa-gear {
  --fa: "";
  --fa--fa: "";
}

.fa-cog {
  --fa: "";
  --fa--fa: "";
}

.fa-droplet-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-tint-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-book-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-mosque {
  --fa: "";
  --fa--fa: "";
}

.fa-duck {
  --fa: "";
  --fa--fa: "";
}

.fa-mosquito {
  --fa: "";
  --fa--fa: "";
}

.fa-star-of-david {
  --fa: "";
  --fa--fa: "";
}

.fa-flag-swallowtail {
  --fa: "";
  --fa--fa: "";
}

.fa-flag-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-person-military-rifle {
  --fa: "";
  --fa--fa: "";
}

.fa-car-garage {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-shopping {
  --fa: "";
  --fa--fa: "";
}

.fa-shopping-cart {
  --fa: "";
  --fa--fa: "";
}

.fa-book-font {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-vials {
  --fa: "";
  --fa--fa: "";
}

.fa-eye-dropper-full {
  --fa: "";
  --fa--fa: "";
}

.fa-distribute-spacing-horizontal {
  --fa: "";
  --fa--fa: "";
}

.fa-tablet-rugged {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-snow {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-frigid {
  --fa: "";
  --fa--fa: "";
}

.fa-moped {
  --fa: "";
  --fa--fa: "";
}

.fa-face-smile-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-smile-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-radio-tuner {
  --fa: "";
  --fa--fa: "";
}

.fa-radio-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-face-swear {
  --fa: "";
  --fa--fa: "";
}

.fa-water-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-water-lower {
  --fa: "";
  --fa--fa: "";
}

.fa-scanner-touchscreen {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-7 {
  --fa: "";
  --fa--fa: "";
}

.fa-plug-circle-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-person-ski-jumping {
  --fa: "";
  --fa--fa: "";
}

.fa-ski-jump {
  --fa: "";
  --fa--fa: "";
}

.fa-place-of-worship {
  --fa: "";
  --fa--fa: "";
}

.fa-water-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-water-rise {
  --fa: "";
  --fa--fa: "";
}

.fa-waveform-lines {
  --fa: "";
  --fa--fa: "";
}

.fa-waveform-path {
  --fa: "";
  --fa--fa: "";
}

.fa-split {
  --fa: "";
  --fa--fa: "";
}

.fa-film-canister {
  --fa: "";
  --fa--fa: "";
}

.fa-film-cannister {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-times {
  --fa: "";
  --fa--fa: "";
}

.fa-toilet-paper-blank {
  --fa: "";
  --fa--fa: "";
}

.fa-toilet-paper-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-tablet-screen {
  --fa: "";
  --fa--fa: "";
}

.fa-tablet-android-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-hexagon-vertical-nft-slanted {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-music {
  --fa: "";
  --fa--fa: "";
}

.fa-display-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-desktop-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-share-all {
  --fa: "";
  --fa--fa: "";
}

.fa-peapod {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-clock {
  --fa: "";
  --fa--fa: "";
}

.fa-axe {
  --fa: "";
  --fa--fa: "";
}

.fa-square-d {
  --fa: "";
  --fa--fa: "";
}

.fa-grip-vertical {
  --fa: "";
  --fa--fa: "";
}

.fa-mobile-signal-out {
  --fa: "";
  --fa--fa: "";
}

.fa-hexagon-nodes {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-turn-up {
  --fa: "";
  --fa--fa: "";
}

.fa-level-up {
  --fa: "";
  --fa--fa: "";
}

.fa-u {
  --fa: "U";
  --fa--fa: "UU";
}

.fa-arrow-up-from-dotted-line {
  --fa: "";
  --fa--fa: "";
}

.fa-square-root-variable {
  --fa: "";
  --fa--fa: "";
}

.fa-square-root-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-light-switch-on {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-raindrops {
  --fa: "";
  --fa--fa: "";
}

.fa-dash {
  --fa: "";
  --fa--fa: "";
}

.fa-minus-large {
  --fa: "";
  --fa--fa: "";
}

.fa-clock {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-four {
  --fa: "";
  --fa--fa: "";
}

.fa-input-numeric {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-tow {
  --fa: "";
  --fa--fa: "";
}

.fa-backward-step {
  --fa: "";
  --fa--fa: "";
}

.fa-step-backward {
  --fa: "";
  --fa--fa: "";
}

.fa-pallet {
  --fa: "";
  --fa--fa: "";
}

.fa-car-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-maximize {
  --fa: "";
  --fa--fa: "";
}

.fa-expand-arrows {
  --fa: "";
  --fa--fa: "";
}

.fa-faucet {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-sleet {
  --fa: "";
  --fa--fa: "";
}

.fa-lamp-street {
  --fa: "";
  --fa--fa: "";
}

.fa-list-radio {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-nib-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-baseball-bat-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-square-up-left {
  --fa: "";
  --fa--fa: "";
}

.fa-overline {
  --fa: "";
  --fa--fa: "";
}

.fa-s {
  --fa: "S";
  --fa--fa: "SS";
}

.fa-timeline {
  --fa: "";
  --fa--fa: "";
}

.fa-keyboard {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-from-dotted-line {
  --fa: "";
  --fa--fa: "";
}

.fa-usb-drive {
  --fa: "";
  --fa--fa: "";
}

.fa-ballot {
  --fa: "";
  --fa--fa: "";
}

.fa-caret-down {
  --fa: "";
  --fa--fa: "";
}

.fa-location-dot-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-map-marker-alt-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-cards {
  --fa: "";
  --fa--fa: "";
}

.fa-house-chimney-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-clinic-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-boxing-glove {
  --fa: "";
  --fa--fa: "";
}

.fa-glove-boxing {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-three-quarters {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-3 {
  --fa: "";
  --fa--fa: "";
}

.fa-thermometer-3 {
  --fa: "";
  --fa--fa: "";
}

.fa-thermometer-three-quarters {
  --fa: "";
  --fa--fa: "";
}

.fa-bell-school {
  --fa: "";
  --fa--fa: "";
}

.fa-mobile-screen {
  --fa: "";
  --fa--fa: "";
}

.fa-mobile-android-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-plane-up {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-location-arrow {
  --fa: "";
  --fa--fa: "";
}

.fa-location-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-face-head-bandage {
  --fa: "";
  --fa--fa: "";
}

.fa-sushi-roll {
  --fa: "";
  --fa--fa: "";
}

.fa-maki-roll {
  --fa: "";
  --fa--fa: "";
}

.fa-makizushi {
  --fa: "";
  --fa--fa: "";
}

.fa-car-bump {
  --fa: "";
  --fa--fa: "";
}

.fa-piggy-bank {
  --fa: "";
  --fa--fa: "";
}

.fa-racquet {
  --fa: "";
  --fa--fa: "";
}

.fa-car-mirrors {
  --fa: "";
  --fa--fa: "";
}

.fa-industry-windows {
  --fa: "";
  --fa--fa: "";
}

.fa-industry-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-bolt-auto {
  --fa: "";
  --fa--fa: "";
}

.fa-battery-half {
  --fa: "";
  --fa--fa: "";
}

.fa-battery-3 {
  --fa: "";
  --fa--fa: "";
}

.fa-flux-capacitor {
  --fa: "";
  --fa--fa: "";
}

.fa-mountain-city {
  --fa: "";
  --fa--fa: "";
}

.fa-coins {
  --fa: "";
  --fa--fa: "";
}

.fa-honey-pot {
  --fa: "";
  --fa--fa: "";
}

.fa-olive {
  --fa: "";
  --fa--fa: "";
}

.fa-khanda {
  --fa: "";
  --fa--fa: "";
}

.fa-filter-list {
  --fa: "";
  --fa--fa: "";
}

.fa-outlet {
  --fa: "";
  --fa--fa: "";
}

.fa-sliders {
  --fa: "";
  --fa--fa: "";
}

.fa-sliders-h {
  --fa: "";
  --fa--fa: "";
}

.fa-cauldron {
  --fa: "";
  --fa--fa: "";
}

.fa-people {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-tree {
  --fa: "";
  --fa--fa: "";
}

.fa-network-wired {
  --fa: "";
  --fa--fa: "";
}

.fa-croissant {
  --fa: "";
  --fa--fa: "";
}

.fa-map-pin {
  --fa: "";
  --fa--fa: "";
}

.fa-hamsa {
  --fa: "";
  --fa--fa: "";
}

.fa-cent-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-swords-laser {
  --fa: "";
  --fa--fa: "";
}

.fa-flask {
  --fa: "";
  --fa--fa: "";
}

.fa-person-pregnant {
  --fa: "";
  --fa--fa: "";
}

.fa-square-u {
  --fa: "";
  --fa--fa: "";
}

.fa-wand-sparkles {
  --fa: "";
  --fa--fa: "";
}

.fa-router {
  --fa: "";
  --fa--fa: "";
}

.fa-ellipsis-vertical {
  --fa: "";
  --fa--fa: "";
}

.fa-ellipsis-v {
  --fa: "";
  --fa--fa: "";
}

.fa-sword-laser-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-ticket {
  --fa: "";
  --fa--fa: "";
}

.fa-power-off {
  --fa: "";
  --fa--fa: "";
}

.fa-coin {
  --fa: "";
  --fa--fa: "";
}

.fa-laptop-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-right-long {
  --fa: "";
  --fa--fa: "";
}

.fa-long-arrow-alt-right {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-b {
  --fa: "";
  --fa--fa: "";
}

.fa-person-dress-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-pipe-collar {
  --fa: "";
  --fa--fa: "";
}

.fa-lights-holiday {
  --fa: "";
  --fa--fa: "";
}

.fa-citrus {
  --fa: "";
  --fa--fa: "";
}

.fa-flag-usa {
  --fa: "";
  --fa--fa: "";
}

.fa-laptop-file {
  --fa: "";
  --fa--fa: "";
}

.fa-tty {
  --fa: "";
  --fa--fa: "";
}

.fa-teletype {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-tree-map {
  --fa: "";
  --fa--fa: "";
}

.fa-diagram-next {
  --fa: "";
  --fa--fa: "";
}

.fa-person-rifle {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-five-thirty {
  --fa: "";
  --fa--fa: "";
}

.fa-pipe-valve {
  --fa: "";
  --fa--fa: "";
}

.fa-lightbulb-message {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-from-arc {
  --fa: "";
  --fa--fa: "";
}

.fa-face-spiral-eyes {
  --fa: "";
  --fa--fa: "";
}

.fa-compress-wide {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-phone-hangup {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-circle-down {
  --fa: "";
  --fa--fa: "";
}

.fa-gear-complex-code {
  --fa: "";
  --fa--fa: "";
}

.fa-house-medical-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-badminton {
  --fa: "";
  --fa--fa: "";
}

.fa-closed-captioning {
  --fa: "";
  --fa--fa: "";
}

.fa-person-hiking {
  --fa: "";
  --fa--fa: "";
}

.fa-hiking {
  --fa: "";
  --fa--fa: "";
}

.fa-right-from-line {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-alt-from-left {
  --fa: "";
  --fa--fa: "";
}

.fa-venus-double {
  --fa: "";
  --fa--fa: "";
}

.fa-images {
  --fa: "";
  --fa--fa: "";
}

.fa-calculator {
  --fa: "";
  --fa--fa: "";
}

.fa-shuttlecock {
  --fa: "";
  --fa--fa: "";
}

.fa-user-hair {
  --fa: "";
  --fa--fa: "";
}

.fa-eye-evil {
  --fa: "";
  --fa--fa: "";
}

.fa-people-pulling {
  --fa: "";
  --fa--fa: "";
}

.fa-n {
  --fa: "N";
  --fa--fa: "NN";
}

.fa-swap {
  --fa: "";
  --fa--fa: "";
}

.fa-garage {
  --fa: "";
  --fa--fa: "";
}

.fa-cable-car {
  --fa: "";
  --fa--fa: "";
}

.fa-tram {
  --fa: "";
  --fa--fa: "";
}

.fa-shovel-snow {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-rain {
  --fa: "";
  --fa--fa: "";
}

.fa-face-lying {
  --fa: "";
  --fa--fa: "";
}

.fa-sprinkler {
  --fa: "";
  --fa--fa: "";
}

.fa-building-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-person-sledding {
  --fa: "";
  --fa--fa: "";
}

.fa-sledding {
  --fa: "";
  --fa--fa: "";
}

.fa-game-console-handheld {
  --fa: "";
  --fa--fa: "";
}

.fa-ship {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-six-thirty {
  --fa: "";
  --fa--fa: "";
}

.fa-battery-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-tugrik-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-down-to-line {
  --fa: "";
  --fa--fa: "";
}

.fa-download {
  --fa: "";
  --fa--fa: "";
}

.fa-angles-up-down {
  --fa: "";
  --fa--fa: "";
}

.fa-shelves {
  --fa: "";
  --fa--fa: "";
}

.fa-inventory {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-snow {
  --fa: "";
  --fa--fa: "";
}

.fa-face-grin {
  --fa: "";
  --fa--fa: "";
}

.fa-grin {
  --fa: "";
  --fa--fa: "";
}

.fa-delete-left {
  --fa: "";
  --fa--fa: "";
}

.fa-backspace {
  --fa: "";
  --fa--fa: "";
}

.fa-oven {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-binary {
  --fa: "";
  --fa--fa: "";
}

.fa-eye-dropper {
  --fa: "";
  --fa--fa: "";
}

.fa-eye-dropper-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-eyedropper {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-captions {
  --fa: "";
  --fa--fa: "";
}

.fa-comments-question {
  --fa: "";
  --fa--fa: "";
}

.fa-scribble {
  --fa: "";
  --fa--fa: "";
}

.fa-rotate-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-file-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-glass {
  --fa: "";
  --fa--fa: "";
}

.fa-loader {
  --fa: "";
  --fa--fa: "";
}

.fa-forward {
  --fa: "";
  --fa--fa: "";
}

.fa-user-pilot {
  --fa: "";
  --fa--fa: "";
}

.fa-mobile {
  --fa: "";
  --fa--fa: "";
}

.fa-mobile-android {
  --fa: "";
  --fa--fa: "";
}

.fa-mobile-phone {
  --fa: "";
  --fa--fa: "";
}

.fa-code-pull-request-closed {
  --fa: "";
  --fa--fa: "";
}

.fa-face-meh {
  --fa: "";
  --fa--fa: "";
}

.fa-meh {
  --fa: "";
  --fa--fa: "";
}

.fa-align-center {
  --fa: "";
  --fa--fa: "";
}

.fa-book-skull {
  --fa: "";
  --fa--fa: "";
}

.fa-book-dead {
  --fa: "";
  --fa--fa: "";
}

.fa-id-card {
  --fa: "";
  --fa--fa: "";
}

.fa-drivers-license {
  --fa: "";
  --fa--fa: "";
}

.fa-face-dotted {
  --fa: "";
  --fa--fa: "";
}

.fa-face-worried {
  --fa: "";
  --fa--fa: "";
}

.fa-outdent {
  --fa: "";
  --fa--fa: "";
}

.fa-dedent {
  --fa: "";
  --fa--fa: "";
}

.fa-court-sport {
  --fa: "";
  --fa--fa: "";
}

.fa-heart-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-house {
  --fa: "";
  --fa--fa: "";
}

.fa-home {
  --fa: "";
  --fa--fa: "";
}

.fa-home-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-home-lg-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-vector-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-car-circle-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-week {
  --fa: "";
  --fa--fa: "";
}

.fa-flying-disc {
  --fa: "";
  --fa--fa: "";
}

.fa-laptop-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-square-down-right {
  --fa: "";
  --fa--fa: "";
}

.fa-b {
  --fa: "B";
  --fa--fa: "BB";
}

.fa-seat-airline {
  --fa: "";
  --fa--fa: "";
}

.fa-moon-over-sun {
  --fa: "";
  --fa--fa: "";
}

.fa-eclipse-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-pipe {
  --fa: "|";
  --fa--fa: "||";
}

.fa-file-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-potato {
  --fa: "";
  --fa--fa: "";
}

.fa-dice-one {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-a {
  --fa: "";
  --fa--fa: "";
}

.fa-helmet-battle {
  --fa: "";
  --fa--fa: "";
}

.fa-butter {
  --fa: "";
  --fa--fa: "";
}

.fa-blanket-fire {
  --fa: "";
  --fa--fa: "";
}

.fa-kiwi-bird {
  --fa: "";
  --fa--fa: "";
}

.fa-castle {
  --fa: "";
  --fa--fa: "";
}

.fa-golf-club {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-right-arrow-left {
  --fa: "";
  --fa--fa: "";
}

.fa-exchange {
  --fa: "";
  --fa--fa: "";
}

.fa-rotate-right {
  --fa: "";
  --fa--fa: "";
}

.fa-redo-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-rotate-forward {
  --fa: "";
  --fa--fa: "";
}

.fa-utensils {
  --fa: "";
  --fa--fa: "";
}

.fa-cutlery {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-wide-short {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-amount-up {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-pie-simple-circle-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-balloons {
  --fa: "";
  --fa--fa: "";
}

.fa-mill-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-bowl-rice {
  --fa: "";
  --fa--fa: "";
}

.fa-timeline-arrow {
  --fa: "";
  --fa--fa: "";
}

.fa-skull {
  --fa: "";
  --fa--fa: "";
}

.fa-game-board-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-game-board-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-video {
  --fa: "";
  --fa--fa: "";
}

.fa-video-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-scatter-bubble {
  --fa: "";
  --fa--fa: "";
}

.fa-house-turret {
  --fa: "";
  --fa--fa: "";
}

.fa-banana {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-holding-skull {
  --fa: "";
  --fa--fa: "";
}

.fa-people-dress {
  --fa: "";
  --fa--fa: "";
}

.fa-loveseat {
  --fa: "";
  --fa--fa: "";
}

.fa-couch-small {
  --fa: "";
  --fa--fa: "";
}

.fa-tower-broadcast {
  --fa: "";
  --fa--fa: "";
}

.fa-broadcast-tower {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-pickup {
  --fa: "";
  --fa--fa: "";
}

.fa-block-quote {
  --fa: "";
  --fa--fa: "";
}

.fa-up-long {
  --fa: "";
  --fa--fa: "";
}

.fa-long-arrow-alt-up {
  --fa: "";
  --fa--fa: "";
}

.fa-stop {
  --fa: "";
  --fa--fa: "";
}

.fa-code-merge {
  --fa: "";
  --fa--fa: "";
}

.fa-money-check-dollar-pen {
  --fa: "";
  --fa--fa: "";
}

.fa-money-check-edit-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-up-from-line {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-alt-from-bottom {
  --fa: "";
  --fa--fa: "";
}

.fa-upload {
  --fa: "";
  --fa--fa: "";
}

.fa-hurricane {
  --fa: "";
  --fa--fa: "";
}

.fa-grid-round-2-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-people-pants {
  --fa: "";
  --fa--fa: "";
}

.fa-mound {
  --fa: "";
  --fa--fa: "";
}

.fa-windsock {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-half {
  --fa: "";
  --fa--fa: "";
}

.fa-brake-warning {
  --fa: "";
  --fa--fa: "";
}

.fa-toilet-portable {
  --fa: "";
  --fa--fa: "";
}

.fa-compact-disc {
  --fa: "";
  --fa--fa: "";
}

.fa-file-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-file-download {
  --fa: "";
  --fa--fa: "";
}

.fa-saxophone-fire {
  --fa: "";
  --fa--fa: "";
}

.fa-sax-hot {
  --fa: "";
  --fa--fa: "";
}

.fa-camera-web-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-webcam-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-gear {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-cog {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-wave {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-up-down {
  --fa: "";
  --fa--fa: "";
}

.fa-caravan {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-cat {
  --fa: "";
  --fa--fa: "";
}

.fa-message-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-alt-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-zap {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-can-check {
  --fa: "";
  --fa--fa: "";
}

.fa-glass-water {
  --fa: "";
  --fa--fa: "";
}

.fa-oil-well {
  --fa: "";
  --fa--fa: "";
}

.fa-table-cells-column-unlock {
  --fa: "";
  --fa--fa: "";
}

.fa-person-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-turn-left-up {
  --fa: "";
  --fa--fa: "";
}

.fa-vault {
  --fa: "";
  --fa--fa: "";
}

.fa-mars {
  --fa: "";
  --fa--fa: "";
}

.fa-toilet {
  --fa: "";
  --fa--fa: "";
}

.fa-plane-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-yen-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-cny {
  --fa: "";
  --fa--fa: "";
}

.fa-jpy {
  --fa: "";
  --fa--fa: "";
}

.fa-rmb {
  --fa: "";
  --fa--fa: "";
}

.fa-yen {
  --fa: "";
  --fa--fa: "";
}

.fa-gear-code {
  --fa: "";
  --fa--fa: "";
}

.fa-notes {
  --fa: "";
  --fa--fa: "";
}

.fa-ruble-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-rouble {
  --fa: "";
  --fa--fa: "";
}

.fa-rub {
  --fa: "";
  --fa--fa: "";
}

.fa-ruble {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-undo {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-arrow-turn-left {
  --fa: "";
  --fa--fa: "";
}

.fa-champagne-glass {
  --fa: "";
  --fa--fa: "";
}

.fa-glass-champagne {
  --fa: "";
  --fa--fa: "";
}

.fa-objects-align-center-horizontal {
  --fa: "";
  --fa--fa: "";
}

.fa-sun {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-can-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-alt-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-screen-users {
  --fa: "";
  --fa--fa: "";
}

.fa-users-class {
  --fa: "";
  --fa--fa: "";
}

.fa-guitar {
  --fa: "";
  --fa--fa: "";
}

.fa-square-arrow-left {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-square-left {
  --fa: "";
  --fa--fa: "";
}

.fa-square-8 {
  --fa: "";
  --fa--fa: "";
}

.fa-face-smile-hearts {
  --fa: "";
  --fa--fa: "";
}

.fa-brackets-square {
  --fa: "";
  --fa--fa: "";
}

.fa-brackets {
  --fa: "";
  --fa--fa: "";
}

.fa-laptop-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-hockey-stick-puck {
  --fa: "";
  --fa--fa: "";
}

.fa-house-tree {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-fair {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-2 {
  --fa: "";
  --fa--fa: "";
}

.fa-face-laugh-wink {
  --fa: "";
  --fa--fa: "";
}

.fa-laugh-wink {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-dollar-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-usd-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-horse-head {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-repeat {
  --fa: "";
  --fa--fa: "";
}

.fa-repeat-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-bore-hole {
  --fa: "";
  --fa--fa: "";
}

.fa-industry {
  --fa: "";
  --fa--fa: "";
}

.fa-image-polaroid {
  --fa: "";
  --fa--fa: "";
}

.fa-wave-triangle {
  --fa: "";
  --fa--fa: "";
}

.fa-turn-left-down {
  --fa: "";
  --fa--fa: "";
}

.fa-person-running-fast {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-down {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-alt-circle-down {
  --fa: "";
  --fa--fa: "";
}

.fa-grill {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-turn-to-dots {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-mixed {
  --fa: "";
  --fa--fa: "";
}

.fa-analytics {
  --fa: "";
  --fa--fa: "";
}

.fa-florin-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-short-wide {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-amount-desc {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-amount-down-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-less-than {
  --fa: "<";
  --fa--fa: "<<";
}

.fa-display-code {
  --fa: "";
  --fa--fa: "";
}

.fa-desktop-code {
  --fa: "";
  --fa--fa: "";
}

.fa-face-drooling {
  --fa: "";
  --fa--fa: "";
}

.fa-oil-temperature {
  --fa: "";
  --fa--fa: "";
}

.fa-oil-temp {
  --fa: "";
  --fa--fa: "";
}

.fa-square-question {
  --fa: "";
  --fa--fa: "";
}

.fa-question-square {
  --fa: "";
  --fa--fa: "";
}

.fa-air-conditioner {
  --fa: "";
  --fa--fa: "";
}

.fa-angle-down {
  --fa: "";
  --fa--fa: "";
}

.fa-mountains {
  --fa: "";
  --fa--fa: "";
}

.fa-omega {
  --fa: "";
  --fa--fa: "";
}

.fa-car-tunnel {
  --fa: "";
  --fa--fa: "";
}

.fa-person-dolly-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-pan-food {
  --fa: "";
  --fa--fa: "";
}

.fa-head-side-cough {
  --fa: "";
  --fa--fa: "";
}

.fa-grip-lines {
  --fa: "";
  --fa--fa: "";
}

.fa-thumbs-down {
  --fa: "";
  --fa--fa: "";
}

.fa-user-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-right-long {
  --fa: "";
  --fa--fa: "";
}

.fa-long-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-tickets-airline {
  --fa: "";
  --fa--fa: "";
}

.fa-tickets-perforated-plane {
  --fa: "";
  --fa--fa: "";
}

.fa-tickets-plane {
  --fa: "";
  --fa--fa: "";
}

.fa-tent-double-peak {
  --fa: "";
  --fa--fa: "";
}

.fa-anchor-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-ellipsis {
  --fa: "";
  --fa--fa: "";
}

.fa-ellipsis-h {
  --fa: "";
  --fa--fa: "";
}

.fa-nfc-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-pawn {
  --fa: "";
  --fa--fa: "";
}

.fa-kit-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-first-aid {
  --fa: "";
  --fa--fa: "";
}

.fa-grid-2-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-bells {
  --fa: "";
  --fa--fa: "";
}

.fa-person-through-window {
  --fa: "";
  --fa--fa: "";
}

.fa-toolbox {
  --fa: "";
  --fa--fa: "";
}

.fa-globe-wifi {
  --fa: "";
  --fa--fa: "";
}

.fa-envelope-dot {
  --fa: "";
  --fa--fa: "";
}

.fa-envelope-badge {
  --fa: "";
  --fa--fa: "";
}

.fa-magnifying-glass-waveform {
  --fa: "";
  --fa--fa: "";
}

.fa-hands-holding-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-bug {
  --fa: "";
  --fa--fa: "";
}

.fa-bowl-chopsticks {
  --fa: "";
  --fa--fa: "";
}

.fa-credit-card {
  --fa: "";
  --fa--fa: "";
}

.fa-credit-card-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-s {
  --fa: "";
  --fa--fa: "";
}

.fa-box-ballot {
  --fa: "";
  --fa--fa: "";
}

.fa-car {
  --fa: "";
  --fa--fa: "";
}

.fa-automobile {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-holding-hand {
  --fa: "";
  --fa--fa: "";
}

.fa-user-tie-hair {
  --fa: "";
  --fa--fa: "";
}

.fa-podium-star {
  --fa: "";
  --fa--fa: "";
}

.fa-user-hair-mullet {
  --fa: "";
  --fa--fa: "";
}

.fa-business-front {
  --fa: "";
  --fa--fa: "";
}

.fa-party-back {
  --fa: "";
  --fa--fa: "";
}

.fa-trian-balbot {
  --fa: "";
  --fa--fa: "";
}

.fa-microphone-stand {
  --fa: "";
  --fa--fa: "";
}

.fa-book-open-reader {
  --fa: "";
  --fa--fa: "";
}

.fa-book-reader {
  --fa: "";
  --fa--fa: "";
}

.fa-family-dress {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-x {
  --fa: "";
  --fa--fa: "";
}

.fa-cabin {
  --fa: "";
  --fa--fa: "";
}

.fa-mountain-sun {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-simple-horizontal {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-left-right-to-line {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-back-point-left {
  --fa: "";
  --fa--fa: "";
}

.fa-message-dots {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-alt-dots {
  --fa: "";
  --fa--fa: "";
}

.fa-messaging {
  --fa: "";
  --fa--fa: "";
}

.fa-file-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-beer-mug {
  --fa: "";
  --fa--fa: "";
}

.fa-beer-foam {
  --fa: "";
  --fa--fa: "";
}

.fa-dice-d20 {
  --fa: "";
  --fa--fa: "";
}

.fa-drone {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-droplet {
  --fa: "";
  --fa--fa: "";
}

.fa-file-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-up {
  --fa: "";
  --fa--fa: "";
}

.fa-medal {
  --fa: "";
  --fa--fa: "";
}

.fa-person-fairy {
  --fa: "";
  --fa--fa: "";
}

.fa-bed {
  --fa: "";
  --fa--fa: "";
}

.fa-book-copy {
  --fa: "";
  --fa--fa: "";
}

.fa-square-h {
  --fa: "";
  --fa--fa: "";
}

.fa-h-square {
  --fa: "";
  --fa--fa: "";
}

.fa-square-c {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-two {
  --fa: "";
  --fa--fa: "";
}

.fa-square-ellipsis-vertical {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-users {
  --fa: "";
  --fa--fa: "";
}

.fa-podcast {
  --fa: "";
  --fa--fa: "";
}

.fa-bee {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-full {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-4 {
  --fa: "";
  --fa--fa: "";
}

.fa-thermometer-4 {
  --fa: "";
  --fa--fa: "";
}

.fa-thermometer-full {
  --fa: "";
  --fa--fa: "";
}

.fa-bell {
  --fa: "";
  --fa--fa: "";
}

.fa-candy-bar {
  --fa: "";
  --fa--fa: "";
}

.fa-chocolate-bar {
  --fa: "";
  --fa--fa: "";
}

.fa-xmark-large {
  --fa: "";
  --fa--fa: "";
}

.fa-pinata {
  --fa: "";
  --fa--fa: "";
}

.fa-file-ppt {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-from-line {
  --fa: "";
  --fa--fa: "";
}

.fa-superscript {
  --fa: "";
  --fa--fa: "";
}

.fa-bowl-spoon {
  --fa: "";
  --fa--fa: "";
}

.fa-hexagon-check {
  --fa: "";
  --fa--fa: "";
}

.fa-plug-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-star-of-life {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-traffic-light-stop {
  --fa: "";
  --fa--fa: "";
}

.fa-paint-roller {
  --fa: "";
  --fa--fa: "";
}

.fa-accent-grave {
  --fa: "`";
  --fa--fa: "``";
}

.fa-handshake-angle {
  --fa: "";
  --fa--fa: "";
}

.fa-hands-helping {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-0 {
  --fa: "";
  --fa--fa: "";
}

.fa-dial-med-low {
  --fa: "";
  --fa--fa: "";
}

.fa-location-dot {
  --fa: "";
  --fa--fa: "";
}

.fa-map-marker-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-crab {
  --fa: "";
  --fa--fa: "";
}

.fa-box-open-full {
  --fa: "";
  --fa--fa: "";
}

.fa-box-full {
  --fa: "";
  --fa--fa: "";
}

.fa-file {
  --fa: "";
  --fa--fa: "";
}

.fa-greater-than {
  --fa: ">";
  --fa--fa: ">>";
}

.fa-quotes {
  --fa: "";
  --fa--fa: "";
}

.fa-pretzel {
  --fa: "";
  --fa--fa: "";
}

.fa-t-rex {
  --fa: "";
  --fa--fa: "";
}

.fa-person-swimming {
  --fa: "";
  --fa--fa: "";
}

.fa-swimmer {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-user-robot-xmarks {
  --fa: "";
  --fa--fa: "";
}

.fa-message-quote {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-alt-quote {
  --fa: "";
  --fa--fa: "";
}

.fa-candy-corn {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-magnifying-glass {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-search {
  --fa: "";
  --fa--fa: "";
}

.fa-notebook {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-wifi {
  --fa: "";
  --fa--fa: "";
}

.fa-droplet {
  --fa: "";
  --fa--fa: "";
}

.fa-tint {
  --fa: "";
  --fa--fa: "";
}

.fa-bullseye-pointer {
  --fa: "";
  --fa--fa: "";
}

.fa-eraser {
  --fa: "";
  --fa--fa: "";
}

.fa-hexagon-image {
  --fa: "";
  --fa--fa: "";
}

.fa-earth-americas {
  --fa: "";
  --fa--fa: "";
}

.fa-earth {
  --fa: "";
  --fa--fa: "";
}

.fa-earth-america {
  --fa: "";
  --fa--fa: "";
}

.fa-globe-americas {
  --fa: "";
  --fa--fa: "";
}

.fa-file-svg {
  --fa: "";
  --fa--fa: "";
}

.fa-crate-apple {
  --fa: "";
  --fa--fa: "";
}

.fa-apple-crate {
  --fa: "";
  --fa--fa: "";
}

.fa-person-burst {
  --fa: "";
  --fa--fa: "";
}

.fa-game-board {
  --fa: "";
  --fa--fa: "";
}

.fa-hat-chef {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-back-point-right {
  --fa: "";
  --fa--fa: "";
}

.fa-dove {
  --fa: "";
  --fa--fa: "";
}

.fa-snowflake-droplets {
  --fa: "";
  --fa--fa: "";
}

.fa-battery-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-battery-0 {
  --fa: "";
  --fa--fa: "";
}

.fa-grid-4 {
  --fa: "";
  --fa--fa: "";
}

.fa-socks {
  --fa: "";
  --fa--fa: "";
}

.fa-face-sunglasses {
  --fa: "";
  --fa--fa: "";
}

.fa-inbox {
  --fa: "";
  --fa--fa: "";
}

.fa-square-0 {
  --fa: "";
  --fa--fa: "";
}

.fa-section {
  --fa: "";
  --fa--fa: "";
}

.fa-square-this-way-up {
  --fa: "";
  --fa--fa: "";
}

.fa-box-up {
  --fa: "";
  --fa--fa: "";
}

.fa-gauge-high {
  --fa: "";
  --fa--fa: "";
}

.fa-tachometer-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-tachometer-alt-fast {
  --fa: "";
  --fa--fa: "";
}

.fa-square-ampersand {
  --fa: "";
  --fa--fa: "";
}

.fa-envelope-open-text {
  --fa: "";
  --fa--fa: "";
}

.fa-lamp-desk {
  --fa: "";
  --fa--fa: "";
}

.fa-hospital {
  --fa: "";
  --fa--fa: "";
}

.fa-hospital-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-hospital-wide {
  --fa: "";
  --fa--fa: "";
}

.fa-poll-people {
  --fa: "";
  --fa--fa: "";
}

.fa-whiskey-glass-ice {
  --fa: "";
  --fa--fa: "";
}

.fa-glass-whiskey-rocks {
  --fa: "";
  --fa--fa: "";
}

.fa-wine-bottle {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-rook {
  --fa: "";
  --fa--fa: "";
}

.fa-user-bounty-hunter {
  --fa: "";
  --fa--fa: "";
}

.fa-bars-staggered {
  --fa: "";
  --fa--fa: "";
}

.fa-reorder {
  --fa: "";
  --fa--fa: "";
}

.fa-stream {
  --fa: "";
  --fa--fa: "";
}

.fa-diagram-sankey {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-hail-mixed {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-up-left {
  --fa: "";
  --fa--fa: "";
}

.fa-dharmachakra {
  --fa: "";
  --fa--fa: "";
}

.fa-objects-align-left {
  --fa: "";
  --fa--fa: "";
}

.fa-oil-can-drip {
  --fa: "";
  --fa--fa: "";
}

.fa-face-smiling-hands {
  --fa: "";
  --fa--fa: "";
}

.fa-broccoli {
  --fa: "";
  --fa--fa: "";
}

.fa-route-interstate {
  --fa: "";
  --fa--fa: "";
}

.fa-ear-muffs {
  --fa: "";
  --fa--fa: "";
}

.fa-hotdog {
  --fa: "";
  --fa--fa: "";
}

.fa-transporter-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-person-walking-with-cane {
  --fa: "";
  --fa--fa: "";
}

.fa-blind {
  --fa: "";
  --fa--fa: "";
}

.fa-angle-90 {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-terminal {
  --fa: "";
  --fa--fa: "";
}

.fa-kite {
  --fa: "";
  --fa--fa: "";
}

.fa-drum {
  --fa: "";
  --fa--fa: "";
}

.fa-scrubber {
  --fa: "";
  --fa--fa: "";
}

.fa-ice-cream {
  --fa: "";
  --fa--fa: "";
}

.fa-heart-circle-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-fish-bones {
  --fa: "";
  --fa--fa: "";
}

.fa-deer-rudolph {
  --fa: "";
  --fa--fa: "";
}

.fa-fax {
  --fa: "";
  --fa--fa: "";
}

.fa-paragraph {
  --fa: "";
  --fa--fa: "";
}

.fa-head-side-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-square-e {
  --fa: "";
  --fa--fa: "";
}

.fa-meter-fire {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-hail {
  --fa: "";
  --fa--fa: "";
}

.fa-check-to-slot {
  --fa: "";
  --fa--fa: "";
}

.fa-vote-yea {
  --fa: "";
  --fa--fa: "";
}

.fa-money-from-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-star-half {
  --fa: "";
  --fa--fa: "";
}

.fa-car-bus {
  --fa: "";
  --fa--fa: "";
}

.fa-speaker {
  --fa: "";
  --fa--fa: "";
}

.fa-timer {
  --fa: "";
  --fa--fa: "";
}

.fa-boxes-stacked {
  --fa: "";
  --fa--fa: "";
}

.fa-boxes {
  --fa: "";
  --fa--fa: "";
}

.fa-boxes-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-landmark-magnifying-glass {
  --fa: "";
  --fa--fa: "";
}

.fa-grill-hot {
  --fa: "";
  --fa--fa: "";
}

.fa-ballot-check {
  --fa: "";
  --fa--fa: "";
}

.fa-link {
  --fa: "";
  --fa--fa: "";
}

.fa-chain {
  --fa: "";
  --fa--fa: "";
}

.fa-ear-listen {
  --fa: "";
  --fa--fa: "";
}

.fa-assistive-listening-systems {
  --fa: "";
  --fa--fa: "";
}

.fa-file-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-tree-city {
  --fa: "";
  --fa--fa: "";
}

.fa-play {
  --fa: "";
  --fa--fa: "";
}

.fa-font {
  --fa: "";
  --fa--fa: "";
}

.fa-cup-togo {
  --fa: "";
  --fa--fa: "";
}

.fa-coffee-togo {
  --fa: "";
  --fa--fa: "";
}

.fa-square-down-left {
  --fa: "";
  --fa--fa: "";
}

.fa-burger-lettuce {
  --fa: "";
  --fa--fa: "";
}

.fa-table-cells-row-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-rupiah-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-magnifying-glass {
  --fa: "";
  --fa--fa: "";
}

.fa-search {
  --fa: "";
  --fa--fa: "";
}

.fa-table-tennis-paddle-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-ping-pong-paddle-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-table-tennis {
  --fa: "";
  --fa--fa: "";
}

.fa-person-dots-from-line {
  --fa: "";
  --fa--fa: "";
}

.fa-diagnoses {
  --fa: "";
  --fa--fa: "";
}

.fa-chevrons-down {
  --fa: "";
  --fa--fa: "";
}

.fa-chevron-double-down {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-can-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-restore-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-good {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-3 {
  --fa: "";
  --fa--fa: "";
}

.fa-location-question {
  --fa: "";
  --fa--fa: "";
}

.fa-map-marker-question {
  --fa: "";
  --fa--fa: "";
}

.fa-floppy-disk-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-floppy-disk-times {
  --fa: "";
  --fa--fa: "";
}

.fa-save-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-save-times {
  --fa: "";
  --fa--fa: "";
}

.fa-naira-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-peach {
  --fa: "";
  --fa--fa: "";
}

.fa-circles-overlap-3 {
  --fa: "";
  --fa--fa: "";
}

.fa-pronoun {
  --fa: "";
  --fa--fa: "";
}

.fa-taxi-bus {
  --fa: "";
  --fa--fa: "";
}

.fa-bracket-curly {
  --fa: "{";
  --fa--fa: "{{";
}

.fa-bracket-curly-left {
  --fa: "{";
  --fa--fa: "{{";
}

.fa-lobster {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-flatbed-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-dolly-flatbed-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-colon {
  --fa: ":";
  --fa--fa: "::";
}

.fa-cart-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-wand {
  --fa: "";
  --fa--fa: "";
}

.fa-walkie-talkie {
  --fa: "";
  --fa--fa: "";
}

.fa-file-pen {
  --fa: "";
  --fa--fa: "";
}

.fa-file-edit {
  --fa: "";
  --fa--fa: "";
}

.fa-receipt {
  --fa: "";
  --fa--fa: "";
}

.fa-table-picnic {
  --fa: "";
  --fa--fa: "";
}

.fa-square-pen {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-square {
  --fa: "";
  --fa--fa: "";
}

.fa-pencil-square {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-microphone-lines {
  --fa: "";
  --fa--fa: "";
}

.fa-microphone-circle-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-display-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-desktop-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-suitcase-rolling {
  --fa: "";
  --fa--fa: "";
}

.fa-person-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-transporter-2 {
  --fa: "";
  --fa--fa: "";
}

.fa-user-hoodie {
  --fa: "";
  --fa--fa: "";
}

.fa-hands-holding-diamond {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-receiving {
  --fa: "";
  --fa--fa: "";
}

.fa-money-bill-simple-wave {
  --fa: "";
  --fa--fa: "";
}

.fa-chevron-down {
  --fa: "";
  --fa--fa: "";
}

.fa-battery-full {
  --fa: "";
  --fa--fa: "";
}

.fa-battery {
  --fa: "";
  --fa--fa: "";
}

.fa-battery-5 {
  --fa: "";
  --fa--fa: "";
}

.fa-bell-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-book-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-hospitals {
  --fa: "";
  --fa--fa: "";
}

.fa-club {
  --fa: "";
  --fa--fa: "";
}

.fa-skull-crossbones {
  --fa: "";
  --fa--fa: "";
}

.fa-droplet-degree {
  --fa: "";
  --fa--fa: "";
}

.fa-dewpoint {
  --fa: "";
  --fa--fa: "";
}

.fa-code-compare {
  --fa: "";
  --fa--fa: "";
}

.fa-list-ul {
  --fa: "";
  --fa--fa: "";
}

.fa-list-dots {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-holding-magic {
  --fa: "";
  --fa--fa: "";
}

.fa-watermelon-slice {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-ellipsis {
  --fa: "";
  --fa--fa: "";
}

.fa-school-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-tower-cell {
  --fa: "";
  --fa--fa: "";
}

.fa-sd-cards {
  --fa: "";
  --fa--fa: "";
}

.fa-jug-bottle {
  --fa: "";
  --fa--fa: "";
}

.fa-down-long {
  --fa: "";
  --fa--fa: "";
}

.fa-long-arrow-alt-down {
  --fa: "";
  --fa--fa: "";
}

.fa-envelopes {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-office {
  --fa: "";
  --fa--fa: "";
}

.fa-ranking-star {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-king {
  --fa: "";
  --fa--fa: "";
}

.fa-nfc-pen {
  --fa: "";
  --fa--fa: "";
}

.fa-person-harassing {
  --fa: "";
  --fa--fa: "";
}

.fa-magnifying-glass-play {
  --fa: "";
  --fa--fa: "";
}

.fa-hat-winter {
  --fa: "";
  --fa--fa: "";
}

.fa-brazilian-real-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-landmark-dome {
  --fa: "";
  --fa--fa: "";
}

.fa-landmark-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-bone-break {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-down-from-dotted-line {
  --fa: "";
  --fa--fa: "";
}

.fa-tv {
  --fa: "";
  --fa--fa: "";
}

.fa-television {
  --fa: "";
  --fa--fa: "";
}

.fa-tv-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-border-left {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-divide {
  --fa: "";
  --fa--fa: "";
}

.fa-shrimp {
  --fa: "";
  --fa--fa: "";
}

.fa-list-check {
  --fa: "";
  --fa--fa: "";
}

.fa-tasks {
  --fa: "";
  --fa--fa: "";
}

.fa-diagram-subtask {
  --fa: "";
  --fa--fa: "";
}

.fa-jug-detergent {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-user {
  --fa: "";
  --fa--fa: "";
}

.fa-user-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-square-y {
  --fa: "";
  --fa--fa: "";
}

.fa-user-doctor-hair {
  --fa: "";
  --fa--fa: "";
}

.fa-planet-ringed {
  --fa: "";
  --fa--fa: "";
}

.fa-mushroom {
  --fa: "";
  --fa--fa: "";
}

.fa-user-shield {
  --fa: "";
  --fa--fa: "";
}

.fa-megaphone {
  --fa: "";
  --fa--fa: "";
}

.fa-wreath-laurel {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-exclamation-check {
  --fa: "";
  --fa--fa: "";
}

.fa-wind {
  --fa: "";
  --fa--fa: "";
}

.fa-box-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-box-usd {
  --fa: "";
  --fa--fa: "";
}

.fa-car-burst {
  --fa: "";
  --fa--fa: "";
}

.fa-car-crash {
  --fa: "";
  --fa--fa: "";
}

.fa-y {
  --fa: "Y";
  --fa--fa: "YY";
}

.fa-user-headset {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-retweet {
  --fa: "";
  --fa--fa: "";
}

.fa-retweet-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-person-snowboarding {
  --fa: "";
  --fa--fa: "";
}

.fa-snowboarding {
  --fa: "";
  --fa--fa: "";
}

.fa-square-chevron-right {
  --fa: "";
  --fa--fa: "";
}

.fa-chevron-square-right {
  --fa: "";
  --fa--fa: "";
}

.fa-lacrosse-stick-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-fast {
  --fa: "";
  --fa--fa: "";
}

.fa-shipping-fast {
  --fa: "";
  --fa--fa: "";
}

.fa-user-magnifying-glass {
  --fa: "";
  --fa--fa: "";
}

.fa-star-sharp {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-1 {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-star {
  --fa: "";
  --fa--fa: "";
}

.fa-star-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-fish {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-fog {
  --fa: "";
  --fa--fa: "";
}

.fa-fog {
  --fa: "";
  --fa--fa: "";
}

.fa-waffle {
  --fa: "";
  --fa--fa: "";
}

.fa-music-note {
  --fa: "";
  --fa--fa: "";
}

.fa-music-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-hexagon-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-shopping-fast {
  --fa: "";
  --fa--fa: "";
}

.fa-object-union {
  --fa: "";
  --fa--fa: "";
}

.fa-user-graduate {
  --fa: "";
  --fa--fa: "";
}

.fa-starfighter {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-half-stroke {
  --fa: "";
  --fa--fa: "";
}

.fa-adjust {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-right-long-to-line {
  --fa: "";
  --fa--fa: "";
}

.fa-square-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-square-down {
  --fa: "";
  --fa--fa: "";
}

.fa-diamond-half-stroke {
  --fa: "";
  --fa--fa: "";
}

.fa-clapperboard {
  --fa: "";
  --fa--fa: "";
}

.fa-square-chevron-left {
  --fa: "";
  --fa--fa: "";
}

.fa-chevron-square-left {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-intercom {
  --fa: "";
  --fa--fa: "";
}

.fa-link-horizontal {
  --fa: "";
  --fa--fa: "";
}

.fa-chain-horizontal {
  --fa: "";
  --fa--fa: "";
}

.fa-mango {
  --fa: "";
  --fa--fa: "";
}

.fa-music-note-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-music-alt-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-radiation {
  --fa: "";
  --fa--fa: "";
}

.fa-radiation-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-face-tongue-sweat {
  --fa: "";
  --fa--fa: "";
}

.fa-globe-stand {
  --fa: "";
  --fa--fa: "";
}

.fa-baseball {
  --fa: "";
  --fa--fa: "";
}

.fa-baseball-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-p {
  --fa: "";
  --fa--fa: "";
}

.fa-award-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-jet-fighter-up {
  --fa: "";
  --fa--fa: "";
}

.fa-diagram-project {
  --fa: "";
  --fa--fa: "";
}

.fa-project-diagram {
  --fa: "";
  --fa--fa: "";
}

.fa-pedestal {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-pyramid {
  --fa: "";
  --fa--fa: "";
}

.fa-sidebar {
  --fa: "";
  --fa--fa: "";
}

.fa-snowman-head {
  --fa: "";
  --fa--fa: "";
}

.fa-frosty-head {
  --fa: "";
  --fa--fa: "";
}

.fa-copy {
  --fa: "";
  --fa--fa: "";
}

.fa-burger-glass {
  --fa: "";
  --fa--fa: "";
}

.fa-volume-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-volume-mute {
  --fa: "";
  --fa--fa: "";
}

.fa-volume-times {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-sparkles {
  --fa: "";
  --fa--fa: "";
}

.fa-bars-filter {
  --fa: "";
  --fa--fa: "";
}

.fa-paintbrush-pencil {
  --fa: "";
  --fa--fa: "";
}

.fa-party-bell {
  --fa: "";
  --fa--fa: "";
}

.fa-user-vneck-hair {
  --fa: "";
  --fa--fa: "";
}

.fa-jack-o-lantern {
  --fa: "";
  --fa--fa: "";
}

.fa-grip {
  --fa: "";
  --fa--fa: "";
}

.fa-grip-horizontal {
  --fa: "";
  --fa--fa: "";
}

.fa-share-from-square {
  --fa: "";
  --fa--fa: "";
}

.fa-share-square {
  --fa: "";
  --fa--fa: "";
}

.fa-keynote {
  --fa: "";
  --fa--fa: "";
}

.fa-child-combatant {
  --fa: "";
  --fa--fa: "";
}

.fa-child-rifle {
  --fa: "";
  --fa--fa: "";
}

.fa-gun {
  --fa: "";
  --fa--fa: "";
}

.fa-square-phone {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-square {
  --fa: "";
  --fa--fa: "";
}

.fa-hat-beach {
  --fa: "";
  --fa--fa: "";
}

.fa-plus {
  --fa: "+";
  --fa--fa: "++";
}

.fa-add {
  --fa: "+";
  --fa--fa: "++";
}

.fa-expand {
  --fa: "";
  --fa--fa: "";
}

.fa-computer {
  --fa: "";
  --fa--fa: "";
}

.fa-fort {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-check {
  --fa: "";
  --fa--fa: "";
}

.fa-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-close {
  --fa: "";
  --fa--fa: "";
}

.fa-multiply {
  --fa: "";
  --fa--fa: "";
}

.fa-remove {
  --fa: "";
  --fa--fa: "";
}

.fa-times {
  --fa: "";
  --fa--fa: "";
}

.fa-face-smirking {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-up-down-left-right {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows {
  --fa: "";
  --fa--fa: "";
}

.fa-chalkboard-user {
  --fa: "";
  --fa--fa: "";
}

.fa-chalkboard-teacher {
  --fa: "";
  --fa--fa: "";
}

.fa-rhombus {
  --fa: "";
  --fa--fa: "";
}

.fa-claw-marks {
  --fa: "";
  --fa--fa: "";
}

.fa-peso-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-face-smile-tongue {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-building-shield {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-phone-flip {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-circle-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-baby {
  --fa: "";
  --fa--fa: "";
}

.fa-users-line {
  --fa: "";
  --fa--fa: "";
}

.fa-quote-left {
  --fa: "";
  --fa--fa: "";
}

.fa-quote-left-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-tractor {
  --fa: "";
  --fa--fa: "";
}

.fa-down-from-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-key-skeleton {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-restore {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-up-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-to-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-lines-leaning {
  --fa: "";
  --fa--fa: "";
}

.fa-square-q {
  --fa: "";
  --fa--fa: "";
}

.fa-ruler-combined {
  --fa: "";
  --fa--fa: "";
}

.fa-symbols {
  --fa: "";
  --fa--fa: "";
}

.fa-icons-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-copyright {
  --fa: "";
  --fa--fa: "";
}

.fa-flask-gear {
  --fa: "";
  --fa--fa: "";
}

.fa-highlighter-line {
  --fa: "";
  --fa--fa: "";
}

.fa-bracket-square {
  --fa: "[";
  --fa--fa: "[[";
}

.fa-bracket {
  --fa: "[";
  --fa--fa: "[[";
}

.fa-bracket-left {
  --fa: "[";
  --fa--fa: "[[";
}

.fa-island-tropical {
  --fa: "";
  --fa--fa: "";
}

.fa-island-tree-palm {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-right-from-line {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-from-left {
  --fa: "";
  --fa--fa: "";
}

.fa-h2 {
  --fa: "";
  --fa--fa: "";
}

.fa-equals {
  --fa: "=";
  --fa--fa: "==";
}

.fa-cake-slice {
  --fa: "";
  --fa--fa: "";
}

.fa-shortcake {
  --fa: "";
  --fa--fa: "";
}

.fa-building-magnifying-glass {
  --fa: "";
  --fa--fa: "";
}

.fa-peanut {
  --fa: "";
  --fa--fa: "";
}

.fa-wrench-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-blender {
  --fa: "";
  --fa--fa: "";
}

.fa-teeth {
  --fa: "";
  --fa--fa: "";
}

.fa-tally-2 {
  --fa: "";
  --fa--fa: "";
}

.fa-shekel-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-ils {
  --fa: "";
  --fa--fa: "";
}

.fa-shekel {
  --fa: "";
  --fa--fa: "";
}

.fa-sheqel {
  --fa: "";
  --fa--fa: "";
}

.fa-sheqel-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-cars {
  --fa: "";
  --fa--fa: "";
}

.fa-axe-battle {
  --fa: "";
  --fa--fa: "";
}

.fa-user-hair-long {
  --fa: "";
  --fa--fa: "";
}

.fa-map {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-left-from-arc {
  --fa: "";
  --fa--fa: "";
}

.fa-file-circle-info {
  --fa: "";
  --fa--fa: "";
}

.fa-face-disappointed {
  --fa: "";
  --fa--fa: "";
}

.fa-lasso-sparkles {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-eleven {
  --fa: "";
  --fa--fa: "";
}

.fa-rocket {
  --fa: "";
  --fa--fa: "";
}

.fa-siren-on {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-ten {
  --fa: "";
  --fa--fa: "";
}

.fa-candle-holder {
  --fa: "";
  --fa--fa: "";
}

.fa-video-arrow-down-left {
  --fa: "";
  --fa--fa: "";
}

.fa-photo-film {
  --fa: "";
  --fa--fa: "";
}

.fa-photo-video {
  --fa: "";
  --fa--fa: "";
}

.fa-floppy-disk-circle-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-save-circle-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-hexagon-nodes-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-planet-moon {
  --fa: "";
  --fa--fa: "";
}

.fa-face-eyes-xmarks {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-scatter {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-gf {
  --fa: "";
  --fa--fa: "";
}

.fa-display-arrow-down {
  --fa: "";
  --fa--fa: "";
}

.fa-store {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-trend-up {
  --fa: "";
  --fa--fa: "";
}

.fa-plug-circle-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-olive-branch {
  --fa: "";
  --fa--fa: "";
}

.fa-angle {
  --fa: "";
  --fa--fa: "";
}

.fa-vacuum-robot {
  --fa: "";
  --fa--fa: "";
}

.fa-sign-hanging {
  --fa: "";
  --fa--fa: "";
}

.fa-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-square-divide {
  --fa: "";
  --fa--fa: "";
}

.fa-folder-check {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-stream-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-bezier-curve {
  --fa: "";
  --fa--fa: "";
}

.fa-eye-dropper-half {
  --fa: "";
  --fa--fa: "";
}

.fa-store-lock {
  --fa: "";
  --fa--fa: "";
}

.fa-bell-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-bolt-sun {
  --fa: "";
  --fa--fa: "";
}

.fa-thunderstorm-sun {
  --fa: "";
  --fa--fa: "";
}

.fa-camera-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-quote {
  --fa: "";
  --fa--fa: "";
}

.fa-tablet {
  --fa: "";
  --fa--fa: "";
}

.fa-tablet-android {
  --fa: "";
  --fa--fa: "";
}

.fa-school-flag {
  --fa: "";
  --fa--fa: "";
}

.fa-message-code {
  --fa: "";
  --fa--fa: "";
}

.fa-glass-half {
  --fa: "";
  --fa--fa: "";
}

.fa-glass-half-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-glass-half-full {
  --fa: "";
  --fa--fa: "";
}

.fa-fill {
  --fa: "";
  --fa--fa: "";
}

.fa-message-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-alt-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-angle-up {
  --fa: "";
  --fa--fa: "";
}

.fa-dinosaur {
  --fa: "";
  --fa--fa: "";
}

.fa-drumstick-bite {
  --fa: "";
  --fa--fa: "";
}

.fa-link-horizontal-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-chain-horizontal-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-holly-berry {
  --fa: "";
  --fa--fa: "";
}

.fa-nose {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-left-to-arc {
  --fa: "";
  --fa--fa: "";
}

.fa-chevron-left {
  --fa: "";
  --fa--fa: "";
}

.fa-bacteria {
  --fa: "";
  --fa--fa: "";
}

.fa-clouds {
  --fa: "";
  --fa--fa: "";
}

.fa-money-bill-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-lizard {
  --fa: "";
  --fa--fa: "";
}

.fa-table-pivot {
  --fa: "";
  --fa--fa: "";
}

.fa-filter-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-can-undo {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-can-arrow-turn-left {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-undo-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-notdef {
  --fa: "";
  --fa--fa: "";
}

.fa-disease {
  --fa: "";
  --fa--fa: "";
}

.fa-person-to-door {
  --fa: "";
  --fa--fa: "";
}

.fa-turntable {
  --fa: "";
  --fa--fa: "";
}

.fa-briefcase-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-genderless {
  --fa: "";
  --fa--fa: "";
}

.fa-chevron-right {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-weak {
  --fa: "";
  --fa--fa: "";
}

.fa-signal-1 {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-five {
  --fa: "";
  --fa--fa: "";
}

.fa-retweet {
  --fa: "";
  --fa--fa: "";
}

.fa-car-rear {
  --fa: "";
  --fa--fa: "";
}

.fa-car-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-pump-soap {
  --fa: "";
  --fa--fa: "";
}

.fa-computer-classic {
  --fa: "";
  --fa--fa: "";
}

.fa-frame {
  --fa: "";
  --fa--fa: "";
}

.fa-video-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-battery-quarter {
  --fa: "";
  --fa--fa: "";
}

.fa-battery-2 {
  --fa: "";
  --fa--fa: "";
}

.fa-ellipsis-stroke {
  --fa: "";
  --fa--fa: "";
}

.fa-ellipsis-h-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-radio {
  --fa: "";
  --fa--fa: "";
}

.fa-baby-carriage {
  --fa: "";
  --fa--fa: "";
}

.fa-carriage-baby {
  --fa: "";
  --fa--fa: "";
}

.fa-face-expressionless {
  --fa: "";
  --fa--fa: "";
}

.fa-down-to-dotted-line {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-music {
  --fa: "";
  --fa--fa: "";
}

.fa-traffic-light {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-thermometer {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-vr-cardboard {
  --fa: "";
  --fa--fa: "";
}

.fa-car-tilt {
  --fa: "";
  --fa--fa: "";
}

.fa-gauge-circle-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-brightness-low {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-middle-finger {
  --fa: "";
  --fa--fa: "";
}

.fa-percent {
  --fa: "%";
  --fa--fa: "%%";
}

.fa-percentage {
  --fa: "%";
  --fa--fa: "%%";
}

.fa-truck-moving {
  --fa: "";
  --fa--fa: "";
}

.fa-glass-water-droplet {
  --fa: "";
  --fa--fa: "";
}

.fa-conveyor-belt {
  --fa: "";
  --fa--fa: "";
}

.fa-location-check {
  --fa: "";
  --fa--fa: "";
}

.fa-map-marker-check {
  --fa: "";
  --fa--fa: "";
}

.fa-coin-vertical {
  --fa: "";
  --fa--fa: "";
}

.fa-display {
  --fa: "";
  --fa--fa: "";
}

.fa-person-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-face-smile {
  --fa: "";
  --fa--fa: "";
}

.fa-smile {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-hangup {
  --fa: "";
  --fa--fa: "";
}

.fa-signature-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-thumbtack {
  --fa: "";
  --fa--fa: "";
}

.fa-thumb-tack {
  --fa: "";
  --fa--fa: "";
}

.fa-wheat-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-trophy {
  --fa: "";
  --fa--fa: "";
}

.fa-clouds-sun {
  --fa: "";
  --fa--fa: "";
}

.fa-person-praying {
  --fa: "";
  --fa--fa: "";
}

.fa-pray {
  --fa: "";
  --fa--fa: "";
}

.fa-hammer {
  --fa: "";
  --fa--fa: "";
}

.fa-face-vomit {
  --fa: "";
  --fa--fa: "";
}

.fa-speakers {
  --fa: "";
  --fa--fa: "";
}

.fa-tty-answer {
  --fa: "";
  --fa--fa: "";
}

.fa-teletype-answer {
  --fa: "";
  --fa--fa: "";
}

.fa-mug-tea-saucer {
  --fa: "";
  --fa--fa: "";
}

.fa-diagram-lean-canvas {
  --fa: "";
  --fa--fa: "";
}

.fa-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-dial {
  --fa: "";
  --fa--fa: "";
}

.fa-dial-med-high {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-peace {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-trash {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-rotate {
  --fa: "";
  --fa--fa: "";
}

.fa-sync-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-quarters {
  --fa: "";
  --fa--fa: "";
}

.fa-spinner {
  --fa: "";
  --fa--fa: "";
}

.fa-tower-control {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-triangle-square {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-shapes-up {
  --fa: "";
  --fa--fa: "";
}

.fa-whale {
  --fa: "";
  --fa--fa: "";
}

.fa-robot {
  --fa: "";
  --fa--fa: "";
}

.fa-peace {
  --fa: "";
  --fa--fa: "";
}

.fa-party-horn {
  --fa: "";
  --fa--fa: "";
}

.fa-gears {
  --fa: "";
  --fa--fa: "";
}

.fa-cogs {
  --fa: "";
  --fa--fa: "";
}

.fa-sun-bright {
  --fa: "";
  --fa--fa: "";
}

.fa-sun-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-warehouse {
  --fa: "";
  --fa--fa: "";
}

.fa-conveyor-belt-arm {
  --fa: "";
  --fa--fa: "";
}

.fa-lock-keyhole-open {
  --fa: "";
  --fa--fa: "";
}

.fa-lock-open-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-square-fragile {
  --fa: "";
  --fa--fa: "";
}

.fa-box-fragile {
  --fa: "";
  --fa--fa: "";
}

.fa-square-wine-glass-crack {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-right-dots {
  --fa: "";
  --fa--fa: "";
}

.fa-square-n {
  --fa: "";
  --fa--fa: "";
}

.fa-splotch {
  --fa: "";
  --fa--fa: "";
}

.fa-face-grin-hearts {
  --fa: "";
  --fa--fa: "";
}

.fa-grin-hearts {
  --fa: "";
  --fa--fa: "";
}

.fa-meter {
  --fa: "";
  --fa--fa: "";
}

.fa-mandolin {
  --fa: "";
  --fa--fa: "";
}

.fa-dice-four {
  --fa: "";
  --fa--fa: "";
}

.fa-sim-card {
  --fa: "";
  --fa--fa: "";
}

.fa-transgender {
  --fa: "";
  --fa--fa: "";
}

.fa-transgender-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-mercury {
  --fa: "";
  --fa--fa: "";
}

.fa-up-from-bracket {
  --fa: "";
  --fa--fa: "";
}

.fa-knife-kitchen {
  --fa: "";
  --fa--fa: "";
}

.fa-border-right {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-turn-down {
  --fa: "";
  --fa--fa: "";
}

.fa-level-down {
  --fa: "";
  --fa--fa: "";
}

.fa-spade {
  --fa: "";
  --fa--fa: "";
}

.fa-card-spade {
  --fa: "";
  --fa--fa: "";
}

.fa-line-columns {
  --fa: "";
  --fa--fa: "";
}

.fa-ant {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-right-to-line {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-to-right {
  --fa: "";
  --fa--fa: "";
}

.fa-person-falling-burst {
  --fa: "";
  --fa--fa: "";
}

.fa-flag-pennant {
  --fa: "";
  --fa--fa: "";
}

.fa-pennant {
  --fa: "";
  --fa--fa: "";
}

.fa-conveyor-belt-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-user-group-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-award {
  --fa: "";
  --fa--fa: "";
}

.fa-ticket-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-ticket-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-building {
  --fa: "";
  --fa--fa: "";
}

.fa-angles-left {
  --fa: "";
  --fa--fa: "";
}

.fa-angle-double-left {
  --fa: "";
  --fa--fa: "";
}

.fa-camcorder {
  --fa: "";
  --fa--fa: "";
}

.fa-video-handheld {
  --fa: "";
  --fa--fa: "";
}

.fa-pancakes {
  --fa: "";
  --fa--fa: "";
}

.fa-album-circle-user {
  --fa: "";
  --fa--fa: "";
}

.fa-subtitles-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-qrcode {
  --fa: "";
  --fa--fa: "";
}

.fa-dice-d10 {
  --fa: "";
  --fa--fa: "";
}

.fa-fireplace {
  --fa: "";
  --fa--fa: "";
}

.fa-browser {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-paintbrush {
  --fa: "";
  --fa--fa: "";
}

.fa-pencil-paintbrush {
  --fa: "";
  --fa--fa: "";
}

.fa-fish-cooked {
  --fa: "";
  --fa--fa: "";
}

.fa-chair-office {
  --fa: "";
  --fa--fa: "";
}

.fa-magnifying-glass-music {
  --fa: "";
  --fa--fa: "";
}

.fa-nesting-dolls {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-rotate-left {
  --fa: "";
  --fa--fa: "";
}

.fa-history {
  --fa: "";
  --fa--fa: "";
}

.fa-trumpet {
  --fa: "";
  --fa--fa: "";
}

.fa-face-grin-beam-sweat {
  --fa: "";
  --fa--fa: "";
}

.fa-grin-beam-sweat {
  --fa: "";
  --fa--fa: "";
}

.fa-fire-smoke {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-missed {
  --fa: "";
  --fa--fa: "";
}

.fa-file-export {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-right-from-file {
  --fa: "";
  --fa--fa: "";
}

.fa-shield {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-blank {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-short-wide {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-amount-up-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-repeat-1 {
  --fa: "";
  --fa--fa: "";
}

.fa-repeat-1-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-gun-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-avocado {
  --fa: "";
  --fa--fa: "";
}

.fa-binary {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-nodes {
  --fa: "";
  --fa--fa: "";
}

.fa-glasses-round {
  --fa: "";
  --fa--fa: "";
}

.fa-glasses-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-phone-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-ditto {
  --fa: '"';
  --fa--fa: '""';
}

.fa-person-seat {
  --fa: "";
  --fa--fa: "";
}

.fa-house-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-golf-ball-tee {
  --fa: "";
  --fa--fa: "";
}

.fa-golf-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-chevron-left {
  --fa: "";
  --fa--fa: "";
}

.fa-chevron-circle-left {
  --fa: "";
  --fa--fa: "";
}

.fa-house-chimney-window {
  --fa: "";
  --fa--fa: "";
}

.fa-scythe {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-nib {
  --fa: "";
  --fa--fa: "";
}

.fa-ban-parking {
  --fa: "";
  --fa--fa: "";
}

.fa-parking-circle-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-tent-arrow-turn-left {
  --fa: "";
  --fa--fa: "";
}

.fa-face-diagonal-mouth {
  --fa: "";
  --fa--fa: "";
}

.fa-diagram-cells {
  --fa: "";
  --fa--fa: "";
}

.fa-cricket-bat-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-cricket {
  --fa: "";
  --fa--fa: "";
}

.fa-tents {
  --fa: "";
  --fa--fa: "";
}

.fa-wand-magic {
  --fa: "";
  --fa--fa: "";
}

.fa-magic {
  --fa: "";
  --fa--fa: "";
}

.fa-dog {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-line {
  --fa: "";
  --fa--fa: "";
}

.fa-atom-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-atom-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-ampersand {
  --fa: "&";
  --fa--fa: "&&";
}

.fa-carrot {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-from-line {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-from-bottom {
  --fa: "";
  --fa--fa: "";
}

.fa-moon {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-wine-glass-empty {
  --fa: "";
  --fa--fa: "";
}

.fa-wine-glass-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-square-star {
  --fa: "";
  --fa--fa: "";
}

.fa-cheese {
  --fa: "";
  --fa--fa: "";
}

.fa-send-backward {
  --fa: "";
  --fa--fa: "";
}

.fa-yin-yang {
  --fa: "";
  --fa--fa: "";
}

.fa-music {
  --fa: "";
  --fa--fa: "";
}

.fa-compass-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-one {
  --fa: "";
  --fa--fa: "";
}

.fa-file-music {
  --fa: "";
  --fa--fa: "";
}

.fa-code-commit {
  --fa: "";
  --fa--fa: "";
}

.fa-temperature-low {
  --fa: "";
  --fa--fa: "";
}

.fa-person-biking {
  --fa: "";
  --fa--fa: "";
}

.fa-biking {
  --fa: "";
  --fa--fa: "";
}

.fa-display-chart-up-circle-currency {
  --fa: "";
  --fa--fa: "";
}

.fa-skeleton {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-g {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-arrow-up-left {
  --fa: "";
  --fa--fa: "";
}

.fa-coin-blank {
  --fa: "";
  --fa--fa: "";
}

.fa-broom {
  --fa: "";
  --fa--fa: "";
}

.fa-vacuum {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-card-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-lightbulb-cfl-on {
  --fa: "";
  --fa--fa: "";
}

.fa-melon {
  --fa: "";
  --fa--fa: "";
}

.fa-gopuram {
  --fa: "";
  --fa--fa: "";
}

.fa-earth-oceania {
  --fa: "";
  --fa--fa: "";
}

.fa-globe-oceania {
  --fa: "";
  --fa--fa: "";
}

.fa-container-storage {
  --fa: "";
  --fa--fa: "";
}

.fa-face-pouting {
  --fa: "";
  --fa--fa: "";
}

.fa-square-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-times-square {
  --fa: "";
  --fa--fa: "";
}

.fa-xmark-square {
  --fa: "";
  --fa--fa: "";
}

.fa-face-explode {
  --fa: "";
  --fa--fa: "";
}

.fa-exploding-head {
  --fa: "";
  --fa--fa: "";
}

.fa-hashtag {
  --fa: "#";
  --fa--fa: "##";
}

.fa-up-right-and-down-left-from-center {
  --fa: "";
  --fa--fa: "";
}

.fa-expand-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-oil-can {
  --fa: "";
  --fa--fa: "";
}

.fa-t {
  --fa: "T";
  --fa--fa: "TT";
}

.fa-transformer-bolt {
  --fa: "";
  --fa--fa: "";
}

.fa-hippo {
  --fa: "";
  --fa--fa: "";
}

.fa-chart-column {
  --fa: "";
  --fa--fa: "";
}

.fa-cassette-vhs {
  --fa: "";
  --fa--fa: "";
}

.fa-vhs {
  --fa: "";
  --fa--fa: "";
}

.fa-infinity {
  --fa: "";
  --fa--fa: "";
}

.fa-vial-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-chimney {
  --fa: "";
  --fa--fa: "";
}

.fa-object-intersect {
  --fa: "";
  --fa--fa: "";
}

.fa-person-arrow-down-to-line {
  --fa: "";
  --fa--fa: "";
}

.fa-voicemail {
  --fa: "";
  --fa--fa: "";
}

.fa-block-brick {
  --fa: "";
  --fa--fa: "";
}

.fa-wall-brick {
  --fa: "";
  --fa--fa: "";
}

.fa-fan {
  --fa: "";
  --fa--fa: "";
}

.fa-bags-shopping {
  --fa: "";
  --fa--fa: "";
}

.fa-paragraph-left {
  --fa: "";
  --fa--fa: "";
}

.fa-paragraph-rtl {
  --fa: "";
  --fa--fa: "";
}

.fa-person-walking-luggage {
  --fa: "";
  --fa--fa: "";
}

.fa-caravan-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-caravan-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-turtle {
  --fa: "";
  --fa--fa: "";
}

.fa-pencil-mechanical {
  --fa: "";
  --fa--fa: "";
}

.fa-up-down {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-alt-v {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-moon-rain {
  --fa: "";
  --fa--fa: "";
}

.fa-booth-curtain {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar {
  --fa: "";
  --fa--fa: "";
}

.fa-box-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-trailer {
  --fa: "";
  --fa--fa: "";
}

.fa-user-doctor-message {
  --fa: "";
  --fa--fa: "";
}

.fa-user-md-chat {
  --fa: "";
  --fa--fa: "";
}

.fa-bahai {
  --fa: "";
  --fa--fa: "";
}

.fa-haykal {
  --fa: "";
  --fa--fa: "";
}

.fa-lighthouse {
  --fa: "";
  --fa--fa: "";
}

.fa-amp-guitar {
  --fa: "";
  --fa--fa: "";
}

.fa-sd-card {
  --fa: "";
  --fa--fa: "";
}

.fa-volume-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-border-bottom {
  --fa: "";
  --fa--fa: "";
}

.fa-wifi-weak {
  --fa: "";
  --fa--fa: "";
}

.fa-wifi-1 {
  --fa: "";
  --fa--fa: "";
}

.fa-dragon {
  --fa: "";
  --fa--fa: "";
}

.fa-shoe-prints {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-plus-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-face-grin-tongue-wink {
  --fa: "";
  --fa--fa: "";
}

.fa-grin-tongue-wink {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-holding {
  --fa: "";
  --fa--fa: "";
}

.fa-plug-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-link-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-chain-broken {
  --fa: "";
  --fa--fa: "";
}

.fa-chain-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-unlink {
  --fa: "";
  --fa--fa: "";
}

.fa-clone {
  --fa: "";
  --fa--fa: "";
}

.fa-person-walking-arrow-loop-left {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-z-a {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-alpha-up-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-fire-flame-curved {
  --fa: "";
  --fa--fa: "";
}

.fa-fire-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-tornado {
  --fa: "";
  --fa--fa: "";
}

.fa-file-circle-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-delete-right {
  --fa: "";
  --fa--fa: "";
}

.fa-book-quran {
  --fa: "";
  --fa--fa: "";
}

.fa-quran {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-quarter {
  --fa: "";
  --fa--fa: "";
}

.fa-anchor {
  --fa: "";
  --fa--fa: "";
}

.fa-border-all {
  --fa: "";
  --fa--fa: "";
}

.fa-function {
  --fa: "";
  --fa--fa: "";
}

.fa-face-angry {
  --fa: "";
  --fa--fa: "";
}

.fa-angry {
  --fa: "";
  --fa--fa: "";
}

.fa-people-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-cookie-bite {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-trend-down {
  --fa: "";
  --fa--fa: "";
}

.fa-rss {
  --fa: "";
  --fa--fa: "";
}

.fa-feed {
  --fa: "";
  --fa--fa: "";
}

.fa-face-monocle {
  --fa: "";
  --fa--fa: "";
}

.fa-draw-polygon {
  --fa: "";
  --fa--fa: "";
}

.fa-scale-balanced {
  --fa: "";
  --fa--fa: "";
}

.fa-balance-scale {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-lines {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-note {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-big-small {
  --fa: "";
  --fa--fa: "";
}

.fa-sort-size-down {
  --fa: "";
  --fa--fa: "";
}

.fa-gauge-simple-high {
  --fa: "";
  --fa--fa: "";
}

.fa-tachometer {
  --fa: "";
  --fa--fa: "";
}

.fa-tachometer-fast {
  --fa: "";
  --fa--fa: "";
}

.fa-do-not-enter {
  --fa: "";
  --fa--fa: "";
}

.fa-shower {
  --fa: "";
  --fa--fa: "";
}

.fa-dice-d8 {
  --fa: "";
  --fa--fa: "";
}

.fa-desktop {
  --fa: "";
  --fa--fa: "";
}

.fa-desktop-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-m {
  --fa: "M";
  --fa--fa: "MM";
}

.fa-spinner-scale {
  --fa: "";
  --fa--fa: "";
}

.fa-grip-dots-vertical {
  --fa: "";
  --fa--fa: "";
}

.fa-face-viewfinder {
  --fa: "";
  --fa--fa: "";
}

.fa-soft-serve {
  --fa: "";
  --fa--fa: "";
}

.fa-creemee {
  --fa: "";
  --fa--fa: "";
}

.fa-h5 {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-back-point-down {
  --fa: "";
  --fa--fa: "";
}

.fa-table-list {
  --fa: "";
  --fa--fa: "";
}

.fa-th-list {
  --fa: "";
  --fa--fa: "";
}

.fa-basket-shopping-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-sms {
  --fa: "";
  --fa--fa: "";
}

.fa-sms {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-landscape {
  --fa: "";
  --fa--fa: "";
}

.fa-clipboard-list-check {
  --fa: "";
  --fa--fa: "";
}

.fa-turkey {
  --fa: "";
  --fa--fa: "";
}

.fa-book {
  --fa: "";
  --fa--fa: "";
}

.fa-user-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-ice-skate {
  --fa: "";
  --fa--fa: "";
}

.fa-check {
  --fa: "";
  --fa--fa: "";
}

.fa-battery-three-quarters {
  --fa: "";
  --fa--fa: "";
}

.fa-battery-4 {
  --fa: "";
  --fa--fa: "";
}

.fa-tomato {
  --fa: "";
  --fa--fa: "";
}

.fa-sword-laser {
  --fa: "";
  --fa--fa: "";
}

.fa-house-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-buildings {
  --fa: "";
  --fa--fa: "";
}

.fa-angle-left {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-flatbed-boxes {
  --fa: "";
  --fa--fa: "";
}

.fa-dolly-flatbed-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-diagram-successor {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-square-w {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-split-up-and-left {
  --fa: "";
  --fa--fa: "";
}

.fa-lamp {
  --fa: "";
  --fa--fa: "";
}

.fa-airplay {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-fist {
  --fa: "";
  --fa--fa: "";
}

.fa-fist-raised {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-quartered {
  --fa: "";
  --fa--fa: "";
}

.fa-slash-forward {
  --fa: "/";
  --fa--fa: "//";
}

.fa-location-pen {
  --fa: "";
  --fa--fa: "";
}

.fa-map-marker-edit {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-moon {
  --fa: "";
  --fa--fa: "";
}

.fa-pot-food {
  --fa: "";
  --fa--fa: "";
}

.fa-briefcase {
  --fa: "";
  --fa--fa: "";
}

.fa-person-falling {
  --fa: "";
  --fa--fa: "";
}

.fa-image-portrait {
  --fa: "";
  --fa--fa: "";
}

.fa-portrait {
  --fa: "";
  --fa--fa: "";
}

.fa-user-tag {
  --fa: "";
  --fa--fa: "";
}

.fa-rug {
  --fa: "";
  --fa--fa: "";
}

.fa-print-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-earth-europe {
  --fa: "";
  --fa--fa: "";
}

.fa-globe-europe {
  --fa: "";
  --fa--fa: "";
}

.fa-cart-flatbed-suitcase {
  --fa: "";
  --fa--fa: "";
}

.fa-luggage-cart {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-back-point-ribbon {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-times {
  --fa: "";
  --fa--fa: "";
}

.fa-times-rectangle {
  --fa: "";
  --fa--fa: "";
}

.fa-window-close {
  --fa: "";
  --fa--fa: "";
}

.fa-tire-rugged {
  --fa: "";
  --fa--fa: "";
}

.fa-lightbulb-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-cowbell {
  --fa: "";
  --fa--fa: "";
}

.fa-baht-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-corner {
  --fa: "";
  --fa--fa: "";
}

.fa-chevrons-right {
  --fa: "";
  --fa--fa: "";
}

.fa-chevron-double-right {
  --fa: "";
  --fa--fa: "";
}

.fa-book-open {
  --fa: "";
  --fa--fa: "";
}

.fa-book-journal-whills {
  --fa: "";
  --fa--fa: "";
}

.fa-journal-whills {
  --fa: "";
  --fa--fa: "";
}

.fa-inhaler {
  --fa: "";
  --fa--fa: "";
}

.fa-handcuffs {
  --fa: "";
  --fa--fa: "";
}

.fa-snake {
  --fa: "";
  --fa--fa: "";
}

.fa-triangle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-exclamation-triangle {
  --fa: "";
  --fa--fa: "";
}

.fa-warning {
  --fa: "";
  --fa--fa: "";
}

.fa-note-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-database {
  --fa: "";
  --fa--fa: "";
}

.fa-down-left {
  --fa: "";
  --fa--fa: "";
}

.fa-share {
  --fa: "";
  --fa--fa: "";
}

.fa-mail-forward {
  --fa: "";
  --fa--fa: "";
}

.fa-face-thinking {
  --fa: "";
  --fa--fa: "";
}

.fa-turn-down-right {
  --fa: "";
  --fa--fa: "";
}

.fa-bottle-droplet {
  --fa: "";
  --fa--fa: "";
}

.fa-mask-face {
  --fa: "";
  --fa--fa: "";
}

.fa-hill-rockslide {
  --fa: "";
  --fa--fa: "";
}

.fa-scanner-keyboard {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-o {
  --fa: "";
  --fa--fa: "";
}

.fa-grid-horizontal {
  --fa: "";
  --fa--fa: "";
}

.fa-message-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-alt-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-right-left {
  --fa: "";
  --fa--fa: "";
}

.fa-exchange-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-columns-3 {
  --fa: "";
  --fa--fa: "";
}

.fa-paper-plane {
  --fa: "";
  --fa--fa: "";
}

.fa-road-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-dungeon {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-holding-box {
  --fa: "";
  --fa--fa: "";
}

.fa-input-text {
  --fa: "";
  --fa--fa: "";
}

.fa-window-flip {
  --fa: "";
  --fa--fa: "";
}

.fa-window-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-align-right {
  --fa: "";
  --fa--fa: "";
}

.fa-scanner-gun {
  --fa: "";
  --fa--fa: "";
}

.fa-scanner {
  --fa: "";
  --fa--fa: "";
}

.fa-tire {
  --fa: "";
  --fa--fa: "";
}

.fa-engine {
  --fa: "";
  --fa--fa: "";
}

.fa-money-bill-1-wave {
  --fa: "";
  --fa--fa: "";
}

.fa-money-bill-wave-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-life-ring {
  --fa: "";
  --fa--fa: "";
}

.fa-hands {
  --fa: "";
  --fa--fa: "";
}

.fa-sign-language {
  --fa: "";
  --fa--fa: "";
}

.fa-signing {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-caret-right {
  --fa: "";
  --fa--fa: "";
}

.fa-caret-circle-right {
  --fa: "";
  --fa--fa: "";
}

.fa-turn-left {
  --fa: "";
  --fa--fa: "";
}

.fa-wheat {
  --fa: "";
  --fa--fa: "";
}

.fa-file-spreadsheet {
  --fa: "";
  --fa--fa: "";
}

.fa-audio-description-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-bell-ring {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-day {
  --fa: "";
  --fa--fa: "";
}

.fa-water-ladder {
  --fa: "";
  --fa--fa: "";
}

.fa-ladder-water {
  --fa: "";
  --fa--fa: "";
}

.fa-swimming-pool {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-up-down {
  --fa: "";
  --fa--fa: "";
}

.fa-arrows-v {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-pawn-piece {
  --fa: "";
  --fa--fa: "";
}

.fa-chess-pawn-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-face-grimace {
  --fa: "";
  --fa--fa: "";
}

.fa-grimace {
  --fa: "";
  --fa--fa: "";
}

.fa-wheelchair-move {
  --fa: "";
  --fa--fa: "";
}

.fa-wheelchair-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-turn-down {
  --fa: "";
  --fa--fa: "";
}

.fa-level-down-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-square-s {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-barcode {
  --fa: "";
  --fa--fa: "";
}

.fa-barcode-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-person-walking-arrow-right {
  --fa: "";
  --fa--fa: "";
}

.fa-square-envelope {
  --fa: "";
  --fa--fa: "";
}

.fa-envelope-square {
  --fa: "";
  --fa--fa: "";
}

.fa-dice {
  --fa: "";
  --fa--fa: "";
}

.fa-unicorn {
  --fa: "";
  --fa--fa: "";
}

.fa-bowling-ball {
  --fa: "";
  --fa--fa: "";
}

.fa-pompebled {
  --fa: "";
  --fa--fa: "";
}

.fa-brain {
  --fa: "";
  --fa--fa: "";
}

.fa-watch-smart {
  --fa: "";
  --fa--fa: "";
}

.fa-book-user {
  --fa: "";
  --fa--fa: "";
}

.fa-sensor-cloud {
  --fa: "";
  --fa--fa: "";
}

.fa-sensor-smoke {
  --fa: "";
  --fa--fa: "";
}

.fa-clapperboard-play {
  --fa: "";
  --fa--fa: "";
}

.fa-bandage {
  --fa: "";
  --fa--fa: "";
}

.fa-band-aid {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-times-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-xmark-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-4 {
  --fa: "";
  --fa--fa: "";
}

.fa-gifts {
  --fa: "";
  --fa--fa: "";
}

.fa-album-collection {
  --fa: "";
  --fa--fa: "";
}

.fa-hotel {
  --fa: "";
  --fa--fa: "";
}

.fa-earth-asia {
  --fa: "";
  --fa--fa: "";
}

.fa-globe-asia {
  --fa: "";
  --fa--fa: "";
}

.fa-id-card-clip {
  --fa: "";
  --fa--fa: "";
}

.fa-id-card-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-magnifying-glass-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-search-plus {
  --fa: "";
  --fa--fa: "";
}

.fa-thumbs-up {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-showers {
  --fa: "";
  --fa--fa: "";
}

.fa-user-clock {
  --fa: "";
  --fa--fa: "";
}

.fa-onion {
  --fa: "";
  --fa--fa: "";
}

.fa-clock-twelve-thirty {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-to-dotted-line {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-dots {
  --fa: "";
  --fa--fa: "";
}

.fa-allergies {
  --fa: "";
  --fa--fa: "";
}

.fa-file-invoice {
  --fa: "";
  --fa--fa: "";
}

.fa-window-minimize {
  --fa: "";
  --fa--fa: "";
}

.fa-rectangle-wide {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-arrow-up {
  --fa: "";
  --fa--fa: "";
}

.fa-garlic {
  --fa: "";
  --fa--fa: "";
}

.fa-mug-saucer {
  --fa: "";
  --fa--fa: "";
}

.fa-coffee {
  --fa: "";
  --fa--fa: "";
}

.fa-brush {
  --fa: "";
  --fa--fa: "";
}

.fa-file-half-dashed {
  --fa: "";
  --fa--fa: "";
}

.fa-tree-decorated {
  --fa: "";
  --fa--fa: "";
}

.fa-mask {
  --fa: "";
  --fa--fa: "";
}

.fa-calendar-heart {
  --fa: "";
  --fa--fa: "";
}

.fa-magnifying-glass-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-search-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-flower {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-down-from-arc {
  --fa: "";
  --fa--fa: "";
}

.fa-right-left-large {
  --fa: "";
  --fa--fa: "";
}

.fa-ruler-vertical {
  --fa: "";
  --fa--fa: "";
}

.fa-circles-overlap {
  --fa: "";
  --fa--fa: "";
}

.fa-user-large {
  --fa: "";
  --fa--fa: "";
}

.fa-user-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-starship-freighter {
  --fa: "";
  --fa--fa: "";
}

.fa-train-tram {
  --fa: "";
  --fa--fa: "";
}

.fa-bridge-suspension {
  --fa: "";
  --fa--fa: "";
}

.fa-trash-check {
  --fa: "";
  --fa--fa: "";
}

.fa-user-nurse {
  --fa: "";
  --fa--fa: "";
}

.fa-boombox {
  --fa: "";
  --fa--fa: "";
}

.fa-syringe {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-sun {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-stopwatch-20 {
  --fa: "";
  --fa--fa: "";
}

.fa-square-full {
  --fa: "";
  --fa--fa: "";
}

.fa-grip-dots {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-swirl {
  --fa: "";
  --fa--fa: "";
}

.fa-falafel {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-2 {
  --fa: "";
  --fa--fa: "";
}

.fa-magnet {
  --fa: "";
  --fa--fa: "";
}

.fa-jar {
  --fa: "";
  --fa--fa: "";
}

.fa-gramophone {
  --fa: "";
  --fa--fa: "";
}

.fa-dice-d12 {
  --fa: "";
  --fa--fa: "";
}

.fa-note-sticky {
  --fa: "";
  --fa--fa: "";
}

.fa-sticky-note {
  --fa: "";
  --fa--fa: "";
}

.fa-down {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-alt-down {
  --fa: "";
  --fa--fa: "";
}

.fa-hundred-points {
  --fa: "";
  --fa--fa: "";
}

.fa-100 {
  --fa: "";
  --fa--fa: "";
}

.fa-paperclip-vertical {
  --fa: "";
  --fa--fa: "";
}

.fa-wind-warning {
  --fa: "";
  --fa--fa: "";
}

.fa-wind-circle-exclamation {
  --fa: "";
  --fa--fa: "";
}

.fa-location-pin-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-map-marker-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-face-sad-sweat {
  --fa: "";
  --fa--fa: "";
}

.fa-bug-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-cupcake {
  --fa: "";
  --fa--fa: "";
}

.fa-light-switch-off {
  --fa: "";
  --fa--fa: "";
}

.fa-toggle-large-off {
  --fa: "";
  --fa--fa: "";
}

.fa-pen-fancy-slash {
  --fa: "";
  --fa--fa: "";
}

.fa-truck-container {
  --fa: "";
  --fa--fa: "";
}

.fa-boot {
  --fa: "";
  --fa--fa: "";
}

.fa-arrow-up-from-water-pump {
  --fa: "";
  --fa--fa: "";
}

.fa-file-check {
  --fa: "";
  --fa--fa: "";
}

.fa-bone {
  --fa: "";
  --fa--fa: "";
}

.fa-cards-blank {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-3 {
  --fa: "";
  --fa--fa: "";
}

.fa-bench-tree {
  --fa: "";
  --fa--fa: "";
}

.fa-keyboard-brightness-low {
  --fa: "";
  --fa--fa: "";
}

.fa-ski-boot-ski {
  --fa: "";
  --fa--fa: "";
}

.fa-brain-circuit {
  --fa: "";
  --fa--fa: "";
}

.fa-table-cells-row-unlock {
  --fa: "";
  --fa--fa: "";
}

.fa-user-injured {
  --fa: "";
  --fa--fa: "";
}

.fa-block-brick-fire {
  --fa: "";
  --fa--fa: "";
}

.fa-firewall {
  --fa: "";
  --fa--fa: "";
}

.fa-face-sad-tear {
  --fa: "";
  --fa--fa: "";
}

.fa-sad-tear {
  --fa: "";
  --fa--fa: "";
}

.fa-plane {
  --fa: "";
  --fa--fa: "";
}

.fa-tent-arrows-down {
  --fa: "";
  --fa--fa: "";
}

.fa-exclamation {
  --fa: "!";
  --fa--fa: "!!";
}

.fa-arrows-spin {
  --fa: "";
  --fa--fa: "";
}

.fa-face-smile-relaxed {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-xmark {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-times {
  --fa: "";
  --fa--fa: "";
}

.fa-print {
  --fa: "";
  --fa--fa: "";
}

.fa-turkish-lira-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-try {
  --fa: "";
  --fa--fa: "";
}

.fa-turkish-lira {
  --fa: "";
  --fa--fa: "";
}

.fa-face-nose-steam {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-waveform-lines {
  --fa: "";
  --fa--fa: "";
}

.fa-waveform-circle {
  --fa: "";
  --fa--fa: "";
}

.fa-dollar-sign {
  --fa: "$";
  --fa--fa: "$$";
}

.fa-dollar {
  --fa: "$";
  --fa--fa: "$$";
}

.fa-usd {
  --fa: "$";
  --fa--fa: "$$";
}

.fa-ferris-wheel {
  --fa: "";
  --fa--fa: "";
}

.fa-computer-speaker {
  --fa: "";
  --fa--fa: "";
}

.fa-skull-cow {
  --fa: "";
  --fa--fa: "";
}

.fa-x {
  --fa: "X";
  --fa--fa: "XX";
}

.fa-magnifying-glass-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-search-dollar {
  --fa: "";
  --fa--fa: "";
}

.fa-users-gear {
  --fa: "";
  --fa--fa: "";
}

.fa-users-cog {
  --fa: "";
  --fa--fa: "";
}

.fa-person-military-pointing {
  --fa: "";
  --fa--fa: "";
}

.fa-building-columns {
  --fa: "";
  --fa--fa: "";
}

.fa-bank {
  --fa: "";
  --fa--fa: "";
}

.fa-institution {
  --fa: "";
  --fa--fa: "";
}

.fa-museum {
  --fa: "";
  --fa--fa: "";
}

.fa-university {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-t {
  --fa: "";
  --fa--fa: "";
}

.fa-sack {
  --fa: "";
  --fa--fa: "";
}

.fa-grid-2 {
  --fa: "";
  --fa--fa: "";
}

.fa-camera-cctv {
  --fa: "";
  --fa--fa: "";
}

.fa-cctv {
  --fa: "";
  --fa--fa: "";
}

.fa-umbrella {
  --fa: "";
  --fa--fa: "";
}

.fa-trowel {
  --fa: "";
  --fa--fa: "";
}

.fa-horizontal-rule {
  --fa: "";
  --fa--fa: "";
}

.fa-bed-front {
  --fa: "";
  --fa--fa: "";
}

.fa-bed-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-d {
  --fa: "D";
  --fa--fa: "DD";
}

.fa-stapler {
  --fa: "";
  --fa--fa: "";
}

.fa-masks-theater {
  --fa: "";
  --fa--fa: "";
}

.fa-theater-masks {
  --fa: "";
  --fa--fa: "";
}

.fa-file-gif {
  --fa: "";
  --fa--fa: "";
}

.fa-kip-sign {
  --fa: "";
  --fa--fa: "";
}

.fa-face-woozy {
  --fa: "";
  --fa--fa: "";
}

.fa-cloud-question {
  --fa: "";
  --fa--fa: "";
}

.fa-pineapple {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-point-left {
  --fa: "";
  --fa--fa: "";
}

.fa-gallery-thumbnails {
  --fa: "";
  --fa--fa: "";
}

.fa-circle-j {
  --fa: "";
  --fa--fa: "";
}

.fa-eyes {
  --fa: "";
  --fa--fa: "";
}

.fa-handshake-simple {
  --fa: "";
  --fa--fa: "";
}

.fa-handshake-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-page-caret-up {
  --fa: "";
  --fa--fa: "";
}

.fa-file-caret-up {
  --fa: "";
  --fa--fa: "";
}

.fa-jet-fighter {
  --fa: "";
  --fa--fa: "";
}

.fa-fighter-jet {
  --fa: "";
  --fa--fa: "";
}

.fa-comet {
  --fa: "";
  --fa--fa: "";
}

.fa-square-share-nodes {
  --fa: "";
  --fa--fa: "";
}

.fa-share-alt-square {
  --fa: "";
  --fa--fa: "";
}

.fa-reflect-vertical {
  --fa: "";
  --fa--fa: "";
}

.fa-shield-keyhole {
  --fa: "";
  --fa--fa: "";
}

.fa-file-mp4 {
  --fa: "";
  --fa--fa: "";
}

.fa-barcode {
  --fa: "";
  --fa--fa: "";
}

.fa-bulldozer {
  --fa: "";
  --fa--fa: "";
}

.fa-plus-minus {
  --fa: "";
  --fa--fa: "";
}

.fa-square-sliders-vertical {
  --fa: "";
  --fa--fa: "";
}

.fa-sliders-v-square {
  --fa: "";
  --fa--fa: "";
}

.fa-video {
  --fa: "";
  --fa--fa: "";
}

.fa-video-camera {
  --fa: "";
  --fa--fa: "";
}

.fa-message-middle {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-middle-alt {
  --fa: "";
  --fa--fa: "";
}

.fa-graduation-cap {
  --fa: "";
  --fa--fa: "";
}

.fa-mortar-board {
  --fa: "";
  --fa--fa: "";
}

.fa-hand-holding-medical {
  --fa: "";
  --fa--fa: "";
}

.fa-person-circle-check {
  --fa: "";
  --fa--fa: "";
}

.fa-square-z {
  --fa: "";
  --fa--fa: "";
}

.fa-message-text {
  --fa: "";
  --fa--fa: "";
}

.fa-comment-alt-text {
  --fa: "";
  --fa--fa: "";
}

.fa-turn-up {
  --fa: "";
  --fa--fa: "";
}

.fa-level-up-alt {
  --fa: "";
  --fa--fa: "";
}

.sr-only,
.fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.sr-only-focusable:not(:focus),
.fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

/*!
 * Bootstrap  v5.3.2 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #2d3f65;
  --bs-purple: #B45396;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #FF6200;
  --bs-yellow: #F09100;
  --bs-green: #5DB12A;
  --bs-teal: #00a693;
  --bs-cyan: #30A6B1;
  --bs-black: #2C292D;
  --bs-white: #ffffff;
  --bs-gray: #959496;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #F5F5F5;
  --bs-gray-200: #E9E9E9;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #959496;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #FF6200;
  --bs-secondary: #adb5bd;
  --bs-success: #5DB12A;
  --bs-info: #30A6B1;
  --bs-warning: #F09100;
  --bs-danger: #dc3545;
  --bs-light: #F5F5F5;
  --bs-dark: #212529;
  --bs-primary-rgb: 255, 98, 0;
  --bs-secondary-rgb: 173, 181, 189;
  --bs-success-rgb: 93, 177, 42;
  --bs-info-rgb: 48, 166, 177;
  --bs-warning-rgb: 240, 145, 0;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 245, 245, 245;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #662700;
  --bs-secondary-text-emphasis: #45484c;
  --bs-success-text-emphasis: #254711;
  --bs-info-text-emphasis: #134247;
  --bs-warning-text-emphasis: #603a00;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #ffe7d9;
  --bs-secondary-bg-subtle: #f3f4f5;
  --bs-success-bg-subtle: #e7f3df;
  --bs-info-bg-subtle: #d9f2ef;
  --bs-warning-bg-subtle: #fdefd9;
  --bs-danger-bg-subtle: #fae1e3;
  --bs-light-bg-subtle: #fafafa;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #ffc099;
  --bs-secondary-border-subtle: #dee1e5;
  --bs-success-border-subtle: #bee0aa;
  --bs-info-border-subtle: #acdbe0;
  --bs-warning-border-subtle: #f9d399;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #E9E9E9;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 44, 41, 45;
  --bs-font-sans-serif: "Source Sans Pro", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #2C292D;
  --bs-body-color-rgb: 44, 41, 45;
  --bs-body-bg: #F5F5F5;
  --bs-body-bg-rgb: 245, 245, 245;
  --bs-emphasis-color: #2C292D;
  --bs-emphasis-color-rgb: 44, 41, 45;
  --bs-secondary-color: rgba(44, 41, 45, 0.75);
  --bs-secondary-color-rgb: 44, 41, 45;
  --bs-secondary-bg: #E9E9E9;
  --bs-secondary-bg-rgb: 233, 233, 233;
  --bs-tertiary-color: rgba(44, 41, 45, 0.5);
  --bs-tertiary-color-rgb: 44, 41, 45;
  --bs-tertiary-bg: #F5F5F5;
  --bs-tertiary-bg-rgb: 245, 245, 245;
  --bs-heading-color: inherit;
  --bs-link-color: #FF6200;
  --bs-link-color-rgb: 255, 98, 0;
  --bs-link-decoration: none;
  --bs-link-hover-color: #FF6200;
  --bs-link-hover-color-rgb: 255, 98, 0;
  --bs-code-color: #d63384;
  --bs-highlight-color: #2C292D;
  --bs-highlight-bg: #fce9cc;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(44, 41, 45, 0.175);
  --bs-border-radius: 0.5rem;
  --bs-border-radius-sm: 0.3rem;
  --bs-border-radius-lg: 0.6rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.125rem 0.375rem rgba(44, 41, 45, 0.07);
  --bs-box-shadow-sm: 0 0.003125rem 0.09375rem rgba(44, 41, 45, 0.075);
  --bs-box-shadow-lg: 0 0.25rem 1.125rem rgba(44, 41, 45, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(44, 41, 45, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(255, 98, 0, 0.25);
  --bs-form-valid-color: #5DB12A;
  --bs-form-valid-border-color: #5DB12A;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #ffffff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #ffa166;
  --bs-secondary-text-emphasis: #ced3d7;
  --bs-success-text-emphasis: #9ed07f;
  --bs-info-text-emphasis: #83cad0;
  --bs-warning-text-emphasis: #f6bd66;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #F5F5F5;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #331400;
  --bs-secondary-bg-subtle: #232426;
  --bs-success-bg-subtle: #132308;
  --bs-info-bg-subtle: #0a2123;
  --bs-warning-bg-subtle: #301d00;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #303237;
  --bs-primary-border-subtle: #993b00;
  --bs-secondary-border-subtle: #686d71;
  --bs-success-border-subtle: #386a19;
  --bs-info-border-subtle: #1d646a;
  --bs-warning-border-subtle: #905700;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #ffa166;
  --bs-link-hover-color: #ffb485;
  --bs-link-color-rgb: 255, 161, 102;
  --bs-link-hover-color-rgb: 255, 180, 133;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #603a00;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #9ed07f;
  --bs-form-valid-border-color: #9ed07f;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(44, 41, 45, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 1.25rem;
  font-weight: 700;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.3125rem + 0.75vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 1.875rem;
  }
}

h2, .h2 {
  font-size: calc(1.28125rem + 0.375vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.5625rem;
  }
}

h3, .h3 {
  font-size: 1.25rem;
}

h4, .h4 {
  font-size: 1.125rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: 0.9rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: none;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.3rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #959496;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 3.2rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 3.2rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 4rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 4rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 4.5rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 4.5rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 3.2rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 3.2rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 4rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 4rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 4.5rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 4.5rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 3.2rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 3.2rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 4rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 4rem;
  }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 4.5rem;
  }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 4.5rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 3.2rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 3.2rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 4rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 4rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 4.5rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 4.5rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 3.2rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 3.2rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 4rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 4rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 4.5rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 4.5rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 3.2rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 3.2rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 4rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 4rem;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 4.5rem;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 4.5rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: #ffffff;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(even) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #2C292D;
  --bs-table-bg: #ffd0b3;
  --bs-table-border-color: #d5af98;
  --bs-table-striped-bg: #f4c8ac;
  --bs-table-striped-color: #2C292D;
  --bs-table-active-bg: #eabfa6;
  --bs-table-active-color: #2C292D;
  --bs-table-hover-bg: #efc3a9;
  --bs-table-hover-color: #2C292D;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #959496;
  --bs-table-bg: #e6e9eb;
  --bs-table-border-color: #d6d8da;
  --bs-table-striped-bg: #e2e5e7;
  --bs-table-striped-color: #2C292D;
  --bs-table-active-bg: #dee1e3;
  --bs-table-active-color: #2C292D;
  --bs-table-hover-bg: #e0e3e5;
  --bs-table-hover-color: #2C292D;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #2C292D;
  --bs-table-bg: #cee8bf;
  --bs-table-border-color: #aec2a2;
  --bs-table-striped-bg: #c6deb8;
  --bs-table-striped-color: #2C292D;
  --bs-table-active-bg: #bed5b0;
  --bs-table-active-color: #2C292D;
  --bs-table-hover-bg: #c2dab4;
  --bs-table-hover-color: #2C292D;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #2C292D;
  --bs-table-bg: #c1e4e8;
  --bs-table-border-color: #a3bfc3;
  --bs-table-striped-bg: #badbdf;
  --bs-table-striped-color: #2C292D;
  --bs-table-active-bg: #b2d1d5;
  --bs-table-active-color: #2C292D;
  --bs-table-hover-bg: #b6d6da;
  --bs-table-hover-color: #2C292D;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #2C292D;
  --bs-table-bg: #fbdeb3;
  --bs-table-border-color: #d2ba98;
  --bs-table-striped-bg: #f1d5ac;
  --bs-table-striped-color: #2C292D;
  --bs-table-active-bg: #e6cca6;
  --bs-table-active-color: #2C292D;
  --bs-table-hover-bg: #ebd0a9;
  --bs-table-hover-color: #2C292D;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #2C292D;
  --bs-table-bg: #f5c2c7;
  --bs-table-border-color: #cda3a8;
  --bs-table-striped-bg: #ebbabf;
  --bs-table-striped-color: #2C292D;
  --bs-table-active-bg: #e1b3b8;
  --bs-table-active-color: #2C292D;
  --bs-table-hover-bg: #e6b7bb;
  --bs-table-hover-color: #2C292D;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #959496;
  --bs-table-bg: #F5F5F5;
  --bs-table-border-color: #e2e2e2;
  --bs-table-striped-bg: #f0f0f0;
  --bs-table-striped-color: #959496;
  --bs-table-active-bg: #ebebec;
  --bs-table-active-color: #959496;
  --bs-table-hover-bg: #eeeeee;
  --bs-table-hover-color: #959496;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #ffffff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #4d5154;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.6875rem + var(--bs-border-width));
  padding-bottom: calc(0.6875rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.63rem + var(--bs-border-width));
  padding-bottom: calc(0.63rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.315rem + var(--bs-border-width));
  padding-bottom: calc(0.315rem + var(--bs-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.6875rem 1.25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: #ffffff;
  border-color: #ffb180;
  outline: 0;
  box-shadow: none;
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: #ffffff;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.6875rem 1.25rem;
  margin: -0.6875rem -1.25rem;
  margin-inline-end: 1.25rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.6875rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.63rem + calc(var(--bs-border-width) * 2));
  padding: 0.315rem 1.25rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.315rem 1.25rem;
  margin: -0.315rem -1.25rem;
  margin-inline-end: 1.25rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1.26rem + calc(var(--bs-border-width) * 2));
  padding: 0.63rem 2.5rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 0.63rem 2.5rem;
  margin: -0.63rem -2.5rem;
  margin-inline-end: 2.5rem;
}

textarea.form-control {
  min-height: calc(1.5em + 1.375rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.63rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1.26rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 1.375rem + calc(var(--bs-border-width) * 2));
  padding: 0.6875rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.63rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1.26rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.6875rem 3.75rem 0.6875rem 1.25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: #ffffff;
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 1.25rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #ffb180;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 98, 0, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1.25rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #ffffff;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.315rem;
  padding-bottom: 0.315rem;
  padding-left: 1.25rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.63rem;
  padding-bottom: 0.63rem;
  padding-left: 2.5rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: #ffffff;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #ffb180;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 98, 0, 0.25);
}
.form-check-input:checked {
  background-color: #FF6200;
  border-color: #FF6200;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #FF6200;
  border-color: #FF6200;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%2844, 41, 45, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffb180'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #F5F5F5, none;
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #F5F5F5, none;
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #FF6200;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #ffd0b3;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #FF6200;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #ffd0b3;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 1.25rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 1.25rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.625rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: #ffffff;
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #959496;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: #ffffff;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.6875rem 1.25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.63rem 2.5rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.315rem 1.25rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 5rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 1.375rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%235DB12A' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.34375rem) center;
  background-size: calc(0.75em + 0.6875rem) calc(0.75em + 0.6875rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.375rem);
  background-position: top calc(0.375em + 0.34375rem) right calc(0.375em + 0.34375rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%235DB12A' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 6.875rem;
  background-position: right 1.25rem center, center right 3.75rem;
  background-size: 16px 12px, calc(0.75em + 0.6875rem) calc(0.75em + 0.6875rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 1.375rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 1.375rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.34375rem) center;
  background-size: calc(0.75em + 0.6875rem) calc(0.75em + 0.6875rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.375rem);
  background-position: top calc(0.375em + 0.34375rem) right calc(0.375em + 0.34375rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 6.875rem;
  background-position: right 1.25rem center, center right 3.75rem;
  background-size: 16px 12px, calc(0.75em + 0.6875rem) calc(0.75em + 0.6875rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 1.375rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 1.25rem;
  --bs-btn-padding-y: 0.6875rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(44, 41, 45, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary, app-table-filters form .filters-buttons .btn:first-of-type {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #FF6200;
  --bs-btn-border-color: #FF6200;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #d95300;
  --bs-btn-hover-border-color: #cc4e00;
  --bs-btn-focus-shadow-rgb: 255, 122, 38;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #cc4e00;
  --bs-btn-active-border-color: #bf4a00;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(44, 41, 45, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #FF6200;
  --bs-btn-disabled-border-color: #FF6200;
}

.btn-secondary, app-table-filters form .filters-buttons .btn:last-of-type {
  --bs-btn-color: #2C292D;
  --bs-btn-bg: #adb5bd;
  --bs-btn-border-color: #adb5bd;
  --bs-btn-hover-color: #2C292D;
  --bs-btn-hover-bg: #b9c0c7;
  --bs-btn-hover-border-color: #b5bcc4;
  --bs-btn-focus-shadow-rgb: 154, 160, 167;
  --bs-btn-active-color: #2C292D;
  --bs-btn-active-bg: #bdc4ca;
  --bs-btn-active-border-color: #b5bcc4;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(44, 41, 45, 0.125);
  --bs-btn-disabled-color: #2C292D;
  --bs-btn-disabled-bg: #adb5bd;
  --bs-btn-disabled-border-color: #adb5bd;
}

.btn-success {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #5DB12A;
  --bs-btn-border-color: #5DB12A;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #4f9624;
  --bs-btn-hover-border-color: #4a8e22;
  --bs-btn-focus-shadow-rgb: 117, 189, 74;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #4a8e22;
  --bs-btn-active-border-color: #468520;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(44, 41, 45, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #5DB12A;
  --bs-btn-disabled-border-color: #5DB12A;
}

.btn-info {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #30A6B1;
  --bs-btn-border-color: #30A6B1;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #298d96;
  --bs-btn-hover-border-color: #26858e;
  --bs-btn-focus-shadow-rgb: 79, 179, 189;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #26858e;
  --bs-btn-active-border-color: #247d85;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(44, 41, 45, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #30A6B1;
  --bs-btn-disabled-border-color: #30A6B1;
}

.btn-warning {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #F09100;
  --bs-btn-border-color: #F09100;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #cc7b00;
  --bs-btn-hover-border-color: #c07400;
  --bs-btn-focus-shadow-rgb: 242, 162, 38;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #c07400;
  --bs-btn-active-border-color: #b46d00;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(44, 41, 45, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #F09100;
  --bs-btn-disabled-border-color: #F09100;
}

.btn-danger {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(44, 41, 45, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #959496;
  --bs-btn-bg: #F5F5F5;
  --bs-btn-border-color: #F5F5F5;
  --bs-btn-hover-color: #2C292D;
  --bs-btn-hover-bg: #d0d0d0;
  --bs-btn-hover-border-color: #c4c4c4;
  --bs-btn-focus-shadow-rgb: 231, 230, 231;
  --bs-btn-active-color: #2C292D;
  --bs-btn-active-bg: #c4c4c4;
  --bs-btn-active-border-color: #b8b8b8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(44, 41, 45, 0.125);
  --bs-btn-disabled-color: #959496;
  --bs-btn-disabled-bg: #F5F5F5;
  --bs-btn-disabled-border-color: #F5F5F5;
}

.btn-dark {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(44, 41, 45, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #FF6200;
  --bs-btn-border-color: #FF6200;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #FF6200;
  --bs-btn-hover-border-color: #FF6200;
  --bs-btn-focus-shadow-rgb: 255, 98, 0;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #FF6200;
  --bs-btn-active-border-color: #FF6200;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(44, 41, 45, 0.125);
  --bs-btn-disabled-color: #FF6200;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FF6200;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #adb5bd;
  --bs-btn-border-color: #adb5bd;
  --bs-btn-hover-color: #2C292D;
  --bs-btn-hover-bg: #adb5bd;
  --bs-btn-hover-border-color: #adb5bd;
  --bs-btn-focus-shadow-rgb: 173, 181, 189;
  --bs-btn-active-color: #2C292D;
  --bs-btn-active-bg: #adb5bd;
  --bs-btn-active-border-color: #adb5bd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(44, 41, 45, 0.125);
  --bs-btn-disabled-color: #adb5bd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #adb5bd;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #5DB12A;
  --bs-btn-border-color: #5DB12A;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #5DB12A;
  --bs-btn-hover-border-color: #5DB12A;
  --bs-btn-focus-shadow-rgb: 93, 177, 42;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #5DB12A;
  --bs-btn-active-border-color: #5DB12A;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(44, 41, 45, 0.125);
  --bs-btn-disabled-color: #5DB12A;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #5DB12A;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #30A6B1;
  --bs-btn-border-color: #30A6B1;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #30A6B1;
  --bs-btn-hover-border-color: #30A6B1;
  --bs-btn-focus-shadow-rgb: 48, 166, 177;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #30A6B1;
  --bs-btn-active-border-color: #30A6B1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(44, 41, 45, 0.125);
  --bs-btn-disabled-color: #30A6B1;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #30A6B1;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #F09100;
  --bs-btn-border-color: #F09100;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #F09100;
  --bs-btn-hover-border-color: #F09100;
  --bs-btn-focus-shadow-rgb: 240, 145, 0;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #F09100;
  --bs-btn-active-border-color: #F09100;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(44, 41, 45, 0.125);
  --bs-btn-disabled-color: #F09100;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F09100;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(44, 41, 45, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #F5F5F5;
  --bs-btn-border-color: #F5F5F5;
  --bs-btn-hover-color: #959496;
  --bs-btn-hover-bg: #F5F5F5;
  --bs-btn-hover-border-color: #F5F5F5;
  --bs-btn-focus-shadow-rgb: 245, 245, 245;
  --bs-btn-active-color: #959496;
  --bs-btn-active-bg: #F5F5F5;
  --bs-btn-active-border-color: #F5F5F5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(44, 41, 45, 0.125);
  --bs-btn-disabled-color: #F5F5F5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F5F5F5;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(44, 41, 45, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #959496;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 255, 122, 38;
  text-decoration: none;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.63rem;
  --bs-btn-padding-x: 2.5rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.315rem;
  --bs-btn-padding-x: 1.25rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: #ffffff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.125rem 0.375rem rgba(44, 41, 45, 0.07);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: #F5F5F5;
  --bs-dropdown-link-active-color: #ffffff;
  --bs-dropdown-link-active-bg: #FF6200;
  --bs-dropdown-link-disabled-color: #959496;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #959496;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #ffffff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #ffffff;
  --bs-dropdown-link-active-bg: #FF6200;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group, app-table-filters form .filters-buttons,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn, app-table-filters form .filters-buttons > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn, app-table-filters form .filters-buttons > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
app-table-filters form .filters-buttons > .btn-check:focus + .btn,
.btn-group > .btn:hover,
app-table-filters form .filters-buttons > .btn:hover,
.btn-group > .btn:focus,
app-table-filters form .filters-buttons > .btn:focus,
.btn-group > .btn:active,
app-table-filters form .filters-buttons > .btn:active,
.btn-group > .btn.active,
app-table-filters form .filters-buttons > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group, app-table-filters form .filters-buttons {
  border-radius: var(--bs-border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn, app-table-filters form .filters-buttons > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child),
app-table-filters form .filters-buttons > .btn-group:not(:first-child),
app-table-filters form .btn-group > .filters-buttons:not(:first-child),
app-table-filters form .filters-buttons > .filters-buttons:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle), app-table-filters form .filters-buttons > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
app-table-filters form .filters-buttons > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn,
app-table-filters form .filters-buttons > .btn-group:not(:last-child) > .btn,
app-table-filters form .btn-group > .filters-buttons:not(:last-child) > .btn,
app-table-filters form .filters-buttons > .filters-buttons:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3), app-table-filters form .filters-buttons > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
app-table-filters form .filters-buttons > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn,
app-table-filters form .filters-buttons > .btn-group:not(:first-child) > .btn,
app-table-filters form .btn-group > .filters-buttons:not(:first-child) > .btn,
app-table-filters form .filters-buttons > .filters-buttons:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.875rem;
  padding-left: 1.875rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group,
app-table-filters form .btn-group-vertical > .filters-buttons {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child),
app-table-filters form .btn-group-vertical > .filters-buttons:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
app-table-filters form .btn-group-vertical > .filters-buttons:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
app-table-filters form .btn-group-vertical > .filters-buttons:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 98, 0, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 2px;
  --bs-nav-tabs-border-color: #FF6200;
  --bs-nav-tabs-border-radius: 0;
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) #FF6200;
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #ffffff;
  --bs-nav-pills-link-active-bg: #FF6200;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2844, 41, 45, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #ffffff;
  --bs-navbar-brand-color: #ffffff;
  --bs-navbar-brand-hover-color: #ffffff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1.4375rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: 0;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: 0.5rem;
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1.4375rem;
  --bs-card-cap-bg: #ffffff;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #ffffff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232C292D'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23662700'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #ffb180;
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffa166'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffa166'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: none;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "\f054") /* rtl: var(--bs-breadcrumb-divider, "\f054") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-white);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: #959496;
  --bs-pagination-hover-bg: #ffe6d6;
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: #959496;
  --bs-pagination-focus-bg: #ffe6d6;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(255, 98, 0, 0.25);
  --bs-pagination-active-color: #ffffff;
  --bs-pagination-active-bg: #FF6200;
  --bs-pagination-active-border-color: #FF6200;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-white);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.625rem;
  --bs-badge-padding-y: 0.3125rem;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #ffffff;
  --bs-badge-border-radius: 1rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #ffffff;
  --bs-progress-bar-bg: #FF6200;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #ffffff;
  --bs-list-group-active-bg: #FF6200;
  --bs-list-group-active-border-color: #FF6200;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #2C292D;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232C292D'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(255, 98, 0, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.5rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: #ffffff;
  --bs-toast-bg: inherit;
  --bs-toast-border-width: 0;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: #ffffff;
  --bs-toast-header-bg: inherit;
  --bs-toast-header-border-color: rgba(255, 255, 255, 0.25);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #ffffff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: 0 0.003125rem 0.09375rem rgba(44, 41, 45, 0.075);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #2C292D;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.125rem 0.375rem rgba(44, 41, 45, 0.07);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #ffffff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #2C292D;
}
.carousel-dark .carousel-caption {
  color: #2C292D;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #2C292D;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #2C292D;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.003125rem 0.09375rem rgba(44, 41, 45, 0.075);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #2C292D;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #2C292D 55%, rgba(0, 0, 0, 0.8) 75%, #2C292D 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #2C292D !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #959496 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(204, 78, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(204, 78, 0, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(189, 196, 202, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(189, 196, 202, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(74, 142, 34, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(74, 142, 34, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(38, 133, 142, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(38, 133, 142, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(192, 116, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(192, 116, 0, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(247, 247, 247, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(247, 247, 247, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-6 {
  margin: 3.2rem !important;
}

.m-7 {
  margin: 4rem !important;
}

.m-8 {
  margin: 4.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-6 {
  margin-right: 3.2rem !important;
  margin-left: 3.2rem !important;
}

.mx-7 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-8 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-6 {
  margin-top: 3.2rem !important;
  margin-bottom: 3.2rem !important;
}

.my-7 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-8 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-6 {
  margin-top: 3.2rem !important;
}

.mt-7 {
  margin-top: 4rem !important;
}

.mt-8 {
  margin-top: 4.5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-6 {
  margin-right: 3.2rem !important;
}

.me-7 {
  margin-right: 4rem !important;
}

.me-8 {
  margin-right: 4.5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-6 {
  margin-bottom: 3.2rem !important;
}

.mb-7 {
  margin-bottom: 4rem !important;
}

.mb-8 {
  margin-bottom: 4.5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-6 {
  margin-left: 3.2rem !important;
}

.ms-7 {
  margin-left: 4rem !important;
}

.ms-8 {
  margin-left: 4.5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-6 {
  padding: 3.2rem !important;
}

.p-7 {
  padding: 4rem !important;
}

.p-8 {
  padding: 4.5rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-6 {
  padding-right: 3.2rem !important;
  padding-left: 3.2rem !important;
}

.px-7 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-8 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-6 {
  padding-top: 3.2rem !important;
  padding-bottom: 3.2rem !important;
}

.py-7 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-8 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-6 {
  padding-top: 3.2rem !important;
}

.pt-7 {
  padding-top: 4rem !important;
}

.pt-8 {
  padding-top: 4.5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pe-6 {
  padding-right: 3.2rem !important;
}

.pe-7 {
  padding-right: 4rem !important;
}

.pe-8 {
  padding-right: 4.5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-6 {
  padding-bottom: 3.2rem !important;
}

.pb-7 {
  padding-bottom: 4rem !important;
}

.pb-8 {
  padding-bottom: 4.5rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-6 {
  padding-left: 3.2rem !important;
}

.ps-7 {
  padding-left: 4rem !important;
}

.ps-8 {
  padding-left: 4.5rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.gap-6 {
  gap: 3.2rem !important;
}

.gap-7 {
  gap: 4rem !important;
}

.gap-8 {
  gap: 4.5rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.row-gap-6 {
  row-gap: 3.2rem !important;
}

.row-gap-7 {
  row-gap: 4rem !important;
}

.row-gap-8 {
  row-gap: 4.5rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.column-gap-6 {
  column-gap: 3.2rem !important;
}

.column-gap-7 {
  column-gap: 4rem !important;
}

.column-gap-8 {
  column-gap: 4.5rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.3125rem + 0.75vw) !important;
}

.fs-2 {
  font-size: calc(1.28125rem + 0.375vw) !important;
}

.fs-3 {
  font-size: 1.25rem !important;
}

.fs-4 {
  font-size: 1.125rem !important;
}

.fs-5 {
  font-size: 1rem !important;
}

.fs-6 {
  font-size: 0.9rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(44, 41, 45, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary, .placeholder {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-6 {
    margin: 3.2rem !important;
  }
  .m-sm-7 {
    margin: 4rem !important;
  }
  .m-sm-8 {
    margin: 4.5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-6 {
    margin-right: 3.2rem !important;
    margin-left: 3.2rem !important;
  }
  .mx-sm-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-sm-8 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-6 {
    margin-top: 3.2rem !important;
    margin-bottom: 3.2rem !important;
  }
  .my-sm-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-sm-8 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-6 {
    margin-top: 3.2rem !important;
  }
  .mt-sm-7 {
    margin-top: 4rem !important;
  }
  .mt-sm-8 {
    margin-top: 4.5rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-6 {
    margin-right: 3.2rem !important;
  }
  .me-sm-7 {
    margin-right: 4rem !important;
  }
  .me-sm-8 {
    margin-right: 4.5rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 3.2rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 4rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 4.5rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-6 {
    margin-left: 3.2rem !important;
  }
  .ms-sm-7 {
    margin-left: 4rem !important;
  }
  .ms-sm-8 {
    margin-left: 4.5rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .p-sm-6 {
    padding: 3.2rem !important;
  }
  .p-sm-7 {
    padding: 4rem !important;
  }
  .p-sm-8 {
    padding: 4.5rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-6 {
    padding-right: 3.2rem !important;
    padding-left: 3.2rem !important;
  }
  .px-sm-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-sm-8 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-6 {
    padding-top: 3.2rem !important;
    padding-bottom: 3.2rem !important;
  }
  .py-sm-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-sm-8 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pt-sm-6 {
    padding-top: 3.2rem !important;
  }
  .pt-sm-7 {
    padding-top: 4rem !important;
  }
  .pt-sm-8 {
    padding-top: 4.5rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pe-sm-6 {
    padding-right: 3.2rem !important;
  }
  .pe-sm-7 {
    padding-right: 4rem !important;
  }
  .pe-sm-8 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 3.2rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 4rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 4.5rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .ps-sm-6 {
    padding-left: 3.2rem !important;
  }
  .ps-sm-7 {
    padding-left: 4rem !important;
  }
  .ps-sm-8 {
    padding-left: 4.5rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .gap-sm-6 {
    gap: 3.2rem !important;
  }
  .gap-sm-7 {
    gap: 4rem !important;
  }
  .gap-sm-8 {
    gap: 4.5rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .row-gap-sm-6 {
    row-gap: 3.2rem !important;
  }
  .row-gap-sm-7 {
    row-gap: 4rem !important;
  }
  .row-gap-sm-8 {
    row-gap: 4.5rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .column-gap-sm-6 {
    column-gap: 3.2rem !important;
  }
  .column-gap-sm-7 {
    column-gap: 4rem !important;
  }
  .column-gap-sm-8 {
    column-gap: 4.5rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-6 {
    margin: 3.2rem !important;
  }
  .m-md-7 {
    margin: 4rem !important;
  }
  .m-md-8 {
    margin: 4.5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-6 {
    margin-right: 3.2rem !important;
    margin-left: 3.2rem !important;
  }
  .mx-md-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-md-8 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-6 {
    margin-top: 3.2rem !important;
    margin-bottom: 3.2rem !important;
  }
  .my-md-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-md-8 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-6 {
    margin-top: 3.2rem !important;
  }
  .mt-md-7 {
    margin-top: 4rem !important;
  }
  .mt-md-8 {
    margin-top: 4.5rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-6 {
    margin-right: 3.2rem !important;
  }
  .me-md-7 {
    margin-right: 4rem !important;
  }
  .me-md-8 {
    margin-right: 4.5rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-6 {
    margin-bottom: 3.2rem !important;
  }
  .mb-md-7 {
    margin-bottom: 4rem !important;
  }
  .mb-md-8 {
    margin-bottom: 4.5rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-6 {
    margin-left: 3.2rem !important;
  }
  .ms-md-7 {
    margin-left: 4rem !important;
  }
  .ms-md-8 {
    margin-left: 4.5rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .p-md-6 {
    padding: 3.2rem !important;
  }
  .p-md-7 {
    padding: 4rem !important;
  }
  .p-md-8 {
    padding: 4.5rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-6 {
    padding-right: 3.2rem !important;
    padding-left: 3.2rem !important;
  }
  .px-md-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-md-8 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-6 {
    padding-top: 3.2rem !important;
    padding-bottom: 3.2rem !important;
  }
  .py-md-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-md-8 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pt-md-6 {
    padding-top: 3.2rem !important;
  }
  .pt-md-7 {
    padding-top: 4rem !important;
  }
  .pt-md-8 {
    padding-top: 4.5rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pe-md-6 {
    padding-right: 3.2rem !important;
  }
  .pe-md-7 {
    padding-right: 4rem !important;
  }
  .pe-md-8 {
    padding-right: 4.5rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .pb-md-6 {
    padding-bottom: 3.2rem !important;
  }
  .pb-md-7 {
    padding-bottom: 4rem !important;
  }
  .pb-md-8 {
    padding-bottom: 4.5rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .ps-md-6 {
    padding-left: 3.2rem !important;
  }
  .ps-md-7 {
    padding-left: 4rem !important;
  }
  .ps-md-8 {
    padding-left: 4.5rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .gap-md-6 {
    gap: 3.2rem !important;
  }
  .gap-md-7 {
    gap: 4rem !important;
  }
  .gap-md-8 {
    gap: 4.5rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .row-gap-md-6 {
    row-gap: 3.2rem !important;
  }
  .row-gap-md-7 {
    row-gap: 4rem !important;
  }
  .row-gap-md-8 {
    row-gap: 4.5rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .column-gap-md-6 {
    column-gap: 3.2rem !important;
  }
  .column-gap-md-7 {
    column-gap: 4rem !important;
  }
  .column-gap-md-8 {
    column-gap: 4.5rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-6 {
    margin: 3.2rem !important;
  }
  .m-lg-7 {
    margin: 4rem !important;
  }
  .m-lg-8 {
    margin: 4.5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-6 {
    margin-right: 3.2rem !important;
    margin-left: 3.2rem !important;
  }
  .mx-lg-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-lg-8 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-6 {
    margin-top: 3.2rem !important;
    margin-bottom: 3.2rem !important;
  }
  .my-lg-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-lg-8 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-6 {
    margin-top: 3.2rem !important;
  }
  .mt-lg-7 {
    margin-top: 4rem !important;
  }
  .mt-lg-8 {
    margin-top: 4.5rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-6 {
    margin-right: 3.2rem !important;
  }
  .me-lg-7 {
    margin-right: 4rem !important;
  }
  .me-lg-8 {
    margin-right: 4.5rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 3.2rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 4rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 4.5rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-6 {
    margin-left: 3.2rem !important;
  }
  .ms-lg-7 {
    margin-left: 4rem !important;
  }
  .ms-lg-8 {
    margin-left: 4.5rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .p-lg-6 {
    padding: 3.2rem !important;
  }
  .p-lg-7 {
    padding: 4rem !important;
  }
  .p-lg-8 {
    padding: 4.5rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-6 {
    padding-right: 3.2rem !important;
    padding-left: 3.2rem !important;
  }
  .px-lg-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-lg-8 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-6 {
    padding-top: 3.2rem !important;
    padding-bottom: 3.2rem !important;
  }
  .py-lg-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-lg-8 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pt-lg-6 {
    padding-top: 3.2rem !important;
  }
  .pt-lg-7 {
    padding-top: 4rem !important;
  }
  .pt-lg-8 {
    padding-top: 4.5rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pe-lg-6 {
    padding-right: 3.2rem !important;
  }
  .pe-lg-7 {
    padding-right: 4rem !important;
  }
  .pe-lg-8 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 3.2rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 4rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 4.5rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .ps-lg-6 {
    padding-left: 3.2rem !important;
  }
  .ps-lg-7 {
    padding-left: 4rem !important;
  }
  .ps-lg-8 {
    padding-left: 4.5rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .gap-lg-6 {
    gap: 3.2rem !important;
  }
  .gap-lg-7 {
    gap: 4rem !important;
  }
  .gap-lg-8 {
    gap: 4.5rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .row-gap-lg-6 {
    row-gap: 3.2rem !important;
  }
  .row-gap-lg-7 {
    row-gap: 4rem !important;
  }
  .row-gap-lg-8 {
    row-gap: 4.5rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .column-gap-lg-6 {
    column-gap: 3.2rem !important;
  }
  .column-gap-lg-7 {
    column-gap: 4rem !important;
  }
  .column-gap-lg-8 {
    column-gap: 4.5rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-6 {
    margin: 3.2rem !important;
  }
  .m-xl-7 {
    margin: 4rem !important;
  }
  .m-xl-8 {
    margin: 4.5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-6 {
    margin-right: 3.2rem !important;
    margin-left: 3.2rem !important;
  }
  .mx-xl-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xl-8 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-6 {
    margin-top: 3.2rem !important;
    margin-bottom: 3.2rem !important;
  }
  .my-xl-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xl-8 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-6 {
    margin-top: 3.2rem !important;
  }
  .mt-xl-7 {
    margin-top: 4rem !important;
  }
  .mt-xl-8 {
    margin-top: 4.5rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-6 {
    margin-right: 3.2rem !important;
  }
  .me-xl-7 {
    margin-right: 4rem !important;
  }
  .me-xl-8 {
    margin-right: 4.5rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 3.2rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 4rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-6 {
    margin-left: 3.2rem !important;
  }
  .ms-xl-7 {
    margin-left: 4rem !important;
  }
  .ms-xl-8 {
    margin-left: 4.5rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .p-xl-6 {
    padding: 3.2rem !important;
  }
  .p-xl-7 {
    padding: 4rem !important;
  }
  .p-xl-8 {
    padding: 4.5rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-6 {
    padding-right: 3.2rem !important;
    padding-left: 3.2rem !important;
  }
  .px-xl-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xl-8 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-6 {
    padding-top: 3.2rem !important;
    padding-bottom: 3.2rem !important;
  }
  .py-xl-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xl-8 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pt-xl-6 {
    padding-top: 3.2rem !important;
  }
  .pt-xl-7 {
    padding-top: 4rem !important;
  }
  .pt-xl-8 {
    padding-top: 4.5rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pe-xl-6 {
    padding-right: 3.2rem !important;
  }
  .pe-xl-7 {
    padding-right: 4rem !important;
  }
  .pe-xl-8 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 3.2rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 4rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 4.5rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .ps-xl-6 {
    padding-left: 3.2rem !important;
  }
  .ps-xl-7 {
    padding-left: 4rem !important;
  }
  .ps-xl-8 {
    padding-left: 4.5rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .gap-xl-6 {
    gap: 3.2rem !important;
  }
  .gap-xl-7 {
    gap: 4rem !important;
  }
  .gap-xl-8 {
    gap: 4.5rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .row-gap-xl-6 {
    row-gap: 3.2rem !important;
  }
  .row-gap-xl-7 {
    row-gap: 4rem !important;
  }
  .row-gap-xl-8 {
    row-gap: 4.5rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .column-gap-xl-6 {
    column-gap: 3.2rem !important;
  }
  .column-gap-xl-7 {
    column-gap: 4rem !important;
  }
  .column-gap-xl-8 {
    column-gap: 4.5rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-6 {
    margin: 3.2rem !important;
  }
  .m-xxl-7 {
    margin: 4rem !important;
  }
  .m-xxl-8 {
    margin: 4.5rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-6 {
    margin-right: 3.2rem !important;
    margin-left: 3.2rem !important;
  }
  .mx-xxl-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xxl-8 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-6 {
    margin-top: 3.2rem !important;
    margin-bottom: 3.2rem !important;
  }
  .my-xxl-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xxl-8 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-6 {
    margin-top: 3.2rem !important;
  }
  .mt-xxl-7 {
    margin-top: 4rem !important;
  }
  .mt-xxl-8 {
    margin-top: 4.5rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-6 {
    margin-right: 3.2rem !important;
  }
  .me-xxl-7 {
    margin-right: 4rem !important;
  }
  .me-xxl-8 {
    margin-right: 4.5rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 3.2rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-6 {
    margin-left: 3.2rem !important;
  }
  .ms-xxl-7 {
    margin-left: 4rem !important;
  }
  .ms-xxl-8 {
    margin-left: 4.5rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .p-xxl-6 {
    padding: 3.2rem !important;
  }
  .p-xxl-7 {
    padding: 4rem !important;
  }
  .p-xxl-8 {
    padding: 4.5rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-6 {
    padding-right: 3.2rem !important;
    padding-left: 3.2rem !important;
  }
  .px-xxl-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xxl-8 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-6 {
    padding-top: 3.2rem !important;
    padding-bottom: 3.2rem !important;
  }
  .py-xxl-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xxl-8 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pt-xxl-6 {
    padding-top: 3.2rem !important;
  }
  .pt-xxl-7 {
    padding-top: 4rem !important;
  }
  .pt-xxl-8 {
    padding-top: 4.5rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pe-xxl-6 {
    padding-right: 3.2rem !important;
  }
  .pe-xxl-7 {
    padding-right: 4rem !important;
  }
  .pe-xxl-8 {
    padding-right: 4.5rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 3.2rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 4rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 4.5rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .ps-xxl-6 {
    padding-left: 3.2rem !important;
  }
  .ps-xxl-7 {
    padding-left: 4rem !important;
  }
  .ps-xxl-8 {
    padding-left: 4.5rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .gap-xxl-6 {
    gap: 3.2rem !important;
  }
  .gap-xxl-7 {
    gap: 4rem !important;
  }
  .gap-xxl-8 {
    gap: 4.5rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .row-gap-xxl-6 {
    row-gap: 3.2rem !important;
  }
  .row-gap-xxl-7 {
    row-gap: 4rem !important;
  }
  .row-gap-xxl-8 {
    row-gap: 4.5rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  .column-gap-xxl-6 {
    column-gap: 3.2rem !important;
  }
  .column-gap-xxl-7 {
    column-gap: 4rem !important;
  }
  .column-gap-xxl-8 {
    column-gap: 4.5rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 1.875rem !important;
  }
  .fs-2 {
    font-size: 1.5625rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.ng-select.ng-select-opened > .ng-select-container {
  background: #ffffff;
  border-color: #c1c9d0 #dee2e6 #edeff1;
}
.ng-select.ng-select-opened > .ng-select-container:hover {
  box-shadow: none;
}
.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  top: -2px;
  border-color: transparent transparent #a4afba;
  border-width: 0 5px 5px;
}
.ng-select.ng-select-opened > .ng-select-container .ng-arrow:hover {
  border-color: transparent transparent #3f4953;
}
.ng-select.ng-select-opened.ng-select-top > .ng-select-container {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.ng-select.ng-select-opened.ng-select-right > .ng-select-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.ng-select.ng-select-opened.ng-select-left > .ng-select-container {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: #ffffff;
  box-shadow: none;
}
.ng-select.ng-select-disabled > .ng-select-container {
  background-color: #ffffff;
}
.ng-select .ng-has-value .ng-placeholder {
  display: none;
}
.ng-select .ng-select-container {
  color: #2C292D;
  background-color: #ffffff;
  border-radius: 0.5rem;
  border: 1px solid #dee2e6;
  min-height: calc(1.5em + 1.375rem + calc(var(--bs-border-width) * 2));
  align-items: center;
}
.ng-select .ng-select-container:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}
.ng-select .ng-select-container .ng-value-container {
  align-items: center;
  padding-left: 0.625rem;
}
[dir=rtl] .ng-select .ng-select-container .ng-value-container {
  padding-right: 0.625rem;
  padding-left: 0;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #8d8b8e;
}
.ng-select.ng-select-single .ng-select-container {
  height: calc(1.5em + 1.375rem + calc(var(--bs-border-width) * 2));
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 5px;
  left: 0;
  padding-left: 0.625rem;
  padding-right: 50px;
}
[dir=rtl] .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding-right: 0.625rem;
  padding-left: 50px;
}

.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value {
  background-color: #ffffff;
  border: 1px solid #fbfcfc;
}
.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value .ng-value-label {
  padding: 0 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-top: 5px;
  padding-left: 7px;
}
[dir=rtl] .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-right: 7px;
  padding-left: 0;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  font-size: 1rem;
  margin-bottom: 5px;
  color: #2C292D;
  background-color: #fff7f2;
  border-radius: 2px;
  margin-right: 5px;
}
[dir=rtl] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  margin-right: 0;
  margin-left: 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
  background-color: #ffffff;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
  padding-left: 5px;
}
[dir=rtl] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
  padding-left: 0;
  padding-right: 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  display: inline-block;
  padding: 1px 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  display: inline-block;
  padding: 1px 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  background-color: #ffe7d9;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: 1px solid #ffd8bf;
}
[dir=rtl] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-left: 1px solid #ffd8bf;
  border-right: none;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
  border-left: 1px solid #ffd8bf;
}
[dir=rtl] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
  border-left: 0;
  border-right: 1px solid #ffd8bf;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding: 0 0 3px 3px;
}
[dir=rtl] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding: 0 3px 3px 0;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input > input {
  color: #000000;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 5px;
  padding-bottom: 5px;
  padding-left: 3px;
}
[dir=rtl] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  padding-right: 3px;
  padding-left: 0;
}

.ng-select .ng-clear-wrapper {
  color: #a4afba;
}
.ng-select .ng-clear-wrapper:hover .ng-clear {
  color: #D0021B;
}
.ng-select .ng-clear-wrapper:focus .ng-clear {
  color: #D0021B;
}
.ng-select .ng-clear-wrapper:focus {
  outline: none;
}
.ng-select .ng-spinner-zone {
  padding: 5px 5px 0 0;
}
[dir=rtl] .ng-select .ng-spinner-zone {
  padding: 5px 0 0 5px;
}

.ng-select .ng-arrow-wrapper {
  width: 25px;
  padding-right: 5px;
}
[dir=rtl] .ng-select .ng-arrow-wrapper {
  padding-left: 5px;
  padding-right: 0;
}

.ng-select .ng-arrow-wrapper:hover .ng-arrow {
  border-top-color: #6b7c8d;
}
.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: #a4afba transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
}

.ng-dropdown-panel {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  left: 0;
}
.ng-dropdown-panel.ng-select-top {
  bottom: 100%;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-bottom-color: #fbfcfc;
  margin-bottom: -1px;
}
.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child {
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}
.ng-dropdown-panel.ng-select-right {
  left: 100%;
  top: 0;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-color: #fbfcfc;
  margin-bottom: -1px;
}
.ng-dropdown-panel.ng-select-right .ng-dropdown-panel-items .ng-option:first-child {
  border-top-right-radius: 0.5rem;
}
.ng-dropdown-panel.ng-select-bottom {
  top: 100%;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-top-color: #fbfcfc;
  margin-top: -1px;
}
.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.ng-dropdown-panel.ng-select-left {
  left: -100%;
  top: 0;
  border-top-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-color: #fbfcfc;
  margin-bottom: -1px;
}
.ng-dropdown-panel.ng-select-left .ng-dropdown-panel-items .ng-option:first-child {
  border-top-left-radius: 0.5rem;
}
.ng-dropdown-panel .ng-dropdown-header {
  border-bottom: 1px solid #dee2e6;
  padding: 5px 7px;
}
.ng-dropdown-panel .ng-dropdown-footer {
  border-top: 1px solid #dee2e6;
  padding: 5px 7px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  user-select: none;
  padding: 8px 10px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
  cursor: default;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  background-color: #fff7f2;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked {
  color: rgba(0, 0, 0, 0.54);
  background-color: #fff7f2;
  font-weight: 600;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  padding: 8px 10px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: #2C292D;
  background-color: #fff7f2;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  font-weight: 600;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #fff7f2;
  color: #2C292D;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: #c5c1c7;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-left: 22px;
}
[dir=rtl] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-right: 22px;
  padding-left: 0;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  font-size: 80%;
  font-weight: 400;
  padding-right: 5px;
}
[dir=rtl] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  padding-left: 5px;
  padding-right: 0;
}

[dir=rtl] .ng-dropdown-panel {
  direction: rtl;
  text-align: right;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: initial;
}

.ng-select:not(.ng-select-multiple) .ng-value {
  width: 100%;
}

.ng-select .ng-select-container {
  color: #2C292D;
}

.ng-select.ng-select-multiple .ng-select-container {
  color: #FF6200;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: #dee2e6;
}

.is-invalid .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: var(--bs-form-invalid-color);
}
.is-invalid .ng-select .ng-select-container {
  border-color: var(--bs-form-invalid-color);
  padding-right: calc(1.5em + 1.375rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.34375rem) center;
  background-size: calc(0.75em + 0.6875rem) calc(0.75em + 0.6875rem);
}
.is-invalid .ng-select .ng-select-container:focus {
  border-color: var(--bs-form-invalid-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-form-invalid-color), 0.25);
}

.input-group > .ng-select:not(:last-child) .ng-select-container,
.input-group *:not(:last-child) > .ng-select .ng-select-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .ng-select:not(:first-child) .ng-select-container,
.input-group *:not(:first-child) > .ng-select .ng-select-container {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ng-select {
  display: flex;
  flex-grow: 1;
}
.ng-select .ng-select-container {
  min-width: 100px;
  width: auto;
}
.ng-select .ng-dropdown-panel {
  min-width: 100%;
  width: auto;
}

.modal-primary .ng-select-container,
.modal-primary .ng-select.ng-select-opened > .ng-select-container,
.modal-primary .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container,
.modal-primary .ng-dropdown-panel {
  border-color: #FF6200;
}

.modal-secondary .ng-select-container,
.modal-secondary .ng-select.ng-select-opened > .ng-select-container,
.modal-secondary .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container,
.modal-secondary .ng-dropdown-panel {
  border-color: #adb5bd;
}

.modal-success .ng-select-container,
.modal-success .ng-select.ng-select-opened > .ng-select-container,
.modal-success .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container,
.modal-success .ng-dropdown-panel {
  border-color: #5DB12A;
}

.modal-info .ng-select-container,
.modal-info .ng-select.ng-select-opened > .ng-select-container,
.modal-info .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container,
.modal-info .ng-dropdown-panel {
  border-color: #30A6B1;
}

.modal-warning .ng-select-container,
.modal-warning .ng-select.ng-select-opened > .ng-select-container,
.modal-warning .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container,
.modal-warning .ng-dropdown-panel {
  border-color: #F09100;
}

.modal-danger .ng-select-container,
.modal-danger .ng-select.ng-select-opened > .ng-select-container,
.modal-danger .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container,
.modal-danger .ng-dropdown-panel {
  border-color: #dc3545;
}

.modal-light .ng-select-container,
.modal-light .ng-select.ng-select-opened > .ng-select-container,
.modal-light .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container,
.modal-light .ng-dropdown-panel {
  border-color: #F5F5F5;
}

.modal-dark .ng-select-container,
.modal-dark .ng-select.ng-select-opened > .ng-select-container,
.modal-dark .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container,
.modal-dark .ng-dropdown-panel {
  border-color: #212529;
}

@media all and (max-width: 576px) {
  .ng-value-container .ng-value {
    white-space: normal !important;
    max-width: 100%;
    height: 25px;
  }
}
select-article .ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 440px;
}

html, body {
  height: 100%;
}

a:not(.btn) {
  outline: none;
}

.rotate-45 {
  transform: rotateZ(45deg);
}

.no-transform {
  transform: none;
}

table th.min, table td.min {
  width: 1%;
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.img-16x9 {
  position: relative;
  width: 100%;
  padding-top: 56%;
  background-color: rgba(128, 128, 128, 0.1);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  overflow: hidden;
}

.badge {
  --bs-bg-opacity: 0.149;
}

statistics-counter .card {
  transition: border-color 0.3s, box-shadow 0.3s ease-out;
}

statistics-counter .card.border-primary {
  box-shadow: 0 0 0 5px #ffe7d9;
}

statistics-counter .card {
  transition: border-color 0.3s, box-shadow 0.3s ease-out;
}

statistics-counter .card.border-secondary {
  box-shadow: 0 0 0 5px #f3f4f5;
}

statistics-counter .card {
  transition: border-color 0.3s, box-shadow 0.3s ease-out;
}

statistics-counter .card.border-success {
  box-shadow: 0 0 0 5px #e7f3df;
}

statistics-counter .card {
  transition: border-color 0.3s, box-shadow 0.3s ease-out;
}

statistics-counter .card.border-info {
  box-shadow: 0 0 0 5px #e0f2f3;
}

statistics-counter .card {
  transition: border-color 0.3s, box-shadow 0.3s ease-out;
}

statistics-counter .card.border-warning {
  box-shadow: 0 0 0 5px #fdefd9;
}

statistics-counter .card {
  transition: border-color 0.3s, box-shadow 0.3s ease-out;
}

statistics-counter .card.border-danger {
  box-shadow: 0 0 0 5px #fae1e3;
}

statistics-counter .card {
  transition: border-color 0.3s, box-shadow 0.3s ease-out;
}

statistics-counter .card.border-light {
  box-shadow: 0 0 0 5px #fefefe;
}

statistics-counter .card {
  transition: border-color 0.3s, box-shadow 0.3s ease-out;
}

statistics-counter .card.border-dark {
  box-shadow: 0 0 0 5px #dededf;
}

.striped-rows > :nth-child(even) .row {
  background-color: #F5F5F5;
}
.striped-rows .row {
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.dropdown-menu-right {
  right: 0 !important;
  left: auto !important;
}

.nav-tabs {
  border-bottom: 0;
  margin-bottom: 2rem;
}
.nav-tabs .nav-link {
  border: 0;
  border-bottom: 2px solid transparent;
  color: #959496;
  font-weight: 700;
  text-transform: uppercase;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  color: var(--bs-link-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-link-color);
  border-bottom: 2px solid #FF6200;
}

.breadcrumb-item + .breadcrumb-item::before {
  font-family: "Font Awesome 6 Pro", sans-serif;
  font-weight: 900;
  opacity: 0.5;
}

.btn {
  font-weight: 700;
  text-transform: uppercase;
}
.btn:focus, .btn .focus {
  box-shadow: none;
}

.btn-secondary, app-table-filters form .filters-buttons .btn:last-of-type {
  --bs-btn-color: #959496;
  --bs-btn-bg: #E9E9E9;
  --bs-btn-border-color: #E9E9E9;
  --bs-btn-hover-color: #959496;
  --bs-btn-hover-bg: #ececec;
  --bs-btn-hover-border-color: #ebebeb;
  --bs-btn-focus-shadow-rgb: 220, 220, 221;
  --bs-btn-active-color: #959496;
  --bs-btn-active-bg: #ededed;
  --bs-btn-active-border-color: #ebebeb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(44, 41, 45, 0.125);
  --bs-btn-disabled-color: #959496;
  --bs-btn-disabled-bg: #E9E9E9;
  --bs-btn-disabled-border-color: #E9E9E9;
}

.card {
  margin-bottom: 1.5rem;
}
.card .card-header {
  border-bottom: 1px solid var(--bs-border-color-translucent);
}
.card .card-header .fa-regular {
  font-size: 18px;
}
.card .card-header h5 .fa-regular:last-child, .card .card-header .h5 .fa-regular:last-child {
  transform: rotateZ(-90deg);
  transition: transform 0.2s;
}
.card .card-header:not(.collapsed) h5 .fa-regular:last-child, .card .card-header:not(.collapsed) .h5 .fa-regular:last-child {
  transform: rotateZ(0deg);
  transition: transform 0.2s;
}

.rounded-icon-size {
  width: 3.125rem;
  height: 3.125rem;
}

statistics-number .progress {
  margin-bottom: 5px;
}
statistics-number .card-body .title small, statistics-number .card-body .title .small {
  font-size: 1rem;
}
statistics-number .status {
  font-size: 0.7rem;
  color: var(--bs-secondary-color);
}

legend {
  float: none;
}

label {
  font-weight: 700;
}

label .required {
  color: #dc3545;
  font-weight: 700;
}

.form-check-list {
  columns: 2;
}

.form-control-plaintext:focus {
  border-color: transparent;
}

.form-control-plaintext ~ .input-group-text {
  background-color: transparent;
  border-color: transparent;
}

.form-control[readonly] {
  background-color: transparent;
}

.form-control[type=file]:disabled,
.form-control[type=file][readonly] {
  opacity: 0;
}

ngb-timepicker input[readonly], ngb-timepicker input[readonly]:focus {
  border-color: transparent;
}

input-number.fix-append .input-group-text {
  width: 6.2rem;
  overflow-wrap: anywhere;
  white-space: initial;
  line-height: 1;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  justify-content: center;
}

.loader-backdrop {
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1050;
  background-color: #2C292D;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1050;
}

.loader-content {
  background-color: #ffffff;
  border: var(--bs-border-width) solid var(--bs-border-color-translucent);
  border-radius: var(--bs-border-radius-lg);
}

.progress__section {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.progress__indic {
  color: #cccccc;
  top: 0;
  width: 1px;
  height: 130%;
  background-color: #cccccc;
}
.progress__value {
  top: 150%;
  left: 0;
  transform: translateX(-50%);
  font-size: 1rem;
}
.progress__text {
  padding-top: 0.2em;
}

ngb-progressbar-stacked ngb-progressbar:last-child .progress-bar {
  border-bottom-right-radius: var(--bs-border-radius);
  border-top-right-radius: var(--bs-border-radius);
}

.modal-primary .modal-header {
  color: #FF6200;
  border-left: 5px solid #FF6200;
  border-right: 5px solid #FF6200;
  border-top: 5px solid #FF6200;
  border-bottom-color: #FF6200;
}
.modal-primary .modal-body {
  border-left: 5px solid #FF6200;
  border-right: 5px solid #FF6200;
}
.modal-primary .modal-footer {
  border-top-color: #FF6200;
  border-left: 5px solid #FF6200;
  border-right: 5px solid #FF6200;
  border-bottom: 5px solid #FF6200;
}
.modal-primary .form-file label,
.modal-primary input {
  border-color: #FF6200;
}
.modal-primary .input-group-text {
  border-color: #FF6200;
}
.modal-primary .btn {
  border-color: #FF6200;
}

.modal-secondary .modal-header {
  color: #adb5bd;
  border-left: 5px solid #adb5bd;
  border-right: 5px solid #adb5bd;
  border-top: 5px solid #adb5bd;
  border-bottom-color: #adb5bd;
}
.modal-secondary .modal-body {
  border-left: 5px solid #adb5bd;
  border-right: 5px solid #adb5bd;
}
.modal-secondary .modal-footer {
  border-top-color: #adb5bd;
  border-left: 5px solid #adb5bd;
  border-right: 5px solid #adb5bd;
  border-bottom: 5px solid #adb5bd;
}
.modal-secondary .form-file label,
.modal-secondary input {
  border-color: #adb5bd;
}
.modal-secondary .input-group-text {
  border-color: #adb5bd;
}
.modal-secondary .btn {
  border-color: #adb5bd;
}

.modal-success .modal-header {
  color: #5DB12A;
  border-left: 5px solid #5DB12A;
  border-right: 5px solid #5DB12A;
  border-top: 5px solid #5DB12A;
  border-bottom-color: #5DB12A;
}
.modal-success .modal-body {
  border-left: 5px solid #5DB12A;
  border-right: 5px solid #5DB12A;
}
.modal-success .modal-footer {
  border-top-color: #5DB12A;
  border-left: 5px solid #5DB12A;
  border-right: 5px solid #5DB12A;
  border-bottom: 5px solid #5DB12A;
}
.modal-success .form-file label,
.modal-success input {
  border-color: #5DB12A;
}
.modal-success .input-group-text {
  border-color: #5DB12A;
}
.modal-success .btn {
  border-color: #5DB12A;
}

.modal-info .modal-header {
  color: #30A6B1;
  border-left: 5px solid #30A6B1;
  border-right: 5px solid #30A6B1;
  border-top: 5px solid #30A6B1;
  border-bottom-color: #30A6B1;
}
.modal-info .modal-body {
  border-left: 5px solid #30A6B1;
  border-right: 5px solid #30A6B1;
}
.modal-info .modal-footer {
  border-top-color: #30A6B1;
  border-left: 5px solid #30A6B1;
  border-right: 5px solid #30A6B1;
  border-bottom: 5px solid #30A6B1;
}
.modal-info .form-file label,
.modal-info input {
  border-color: #30A6B1;
}
.modal-info .input-group-text {
  border-color: #30A6B1;
}
.modal-info .btn {
  border-color: #30A6B1;
}

.modal-warning .modal-header {
  color: #F09100;
  border-left: 5px solid #F09100;
  border-right: 5px solid #F09100;
  border-top: 5px solid #F09100;
  border-bottom-color: #F09100;
}
.modal-warning .modal-body {
  border-left: 5px solid #F09100;
  border-right: 5px solid #F09100;
}
.modal-warning .modal-footer {
  border-top-color: #F09100;
  border-left: 5px solid #F09100;
  border-right: 5px solid #F09100;
  border-bottom: 5px solid #F09100;
}
.modal-warning .form-file label,
.modal-warning input {
  border-color: #F09100;
}
.modal-warning .input-group-text {
  border-color: #F09100;
}
.modal-warning .btn {
  border-color: #F09100;
}

.modal-danger .modal-header {
  color: #dc3545;
  border-left: 5px solid #dc3545;
  border-right: 5px solid #dc3545;
  border-top: 5px solid #dc3545;
  border-bottom-color: #dc3545;
}
.modal-danger .modal-body {
  border-left: 5px solid #dc3545;
  border-right: 5px solid #dc3545;
}
.modal-danger .modal-footer {
  border-top-color: #dc3545;
  border-left: 5px solid #dc3545;
  border-right: 5px solid #dc3545;
  border-bottom: 5px solid #dc3545;
}
.modal-danger .form-file label,
.modal-danger input {
  border-color: #dc3545;
}
.modal-danger .input-group-text {
  border-color: #dc3545;
}
.modal-danger .btn {
  border-color: #dc3545;
}

.modal-light .modal-header {
  color: #F5F5F5;
  border-left: 5px solid #F5F5F5;
  border-right: 5px solid #F5F5F5;
  border-top: 5px solid #F5F5F5;
  border-bottom-color: #F5F5F5;
}
.modal-light .modal-body {
  border-left: 5px solid #F5F5F5;
  border-right: 5px solid #F5F5F5;
}
.modal-light .modal-footer {
  border-top-color: #F5F5F5;
  border-left: 5px solid #F5F5F5;
  border-right: 5px solid #F5F5F5;
  border-bottom: 5px solid #F5F5F5;
}
.modal-light .form-file label,
.modal-light input {
  border-color: #F5F5F5;
}
.modal-light .input-group-text {
  border-color: #F5F5F5;
}
.modal-light .btn {
  border-color: #F5F5F5;
}

.modal-dark .modal-header {
  color: #212529;
  border-left: 5px solid #212529;
  border-right: 5px solid #212529;
  border-top: 5px solid #212529;
  border-bottom-color: #212529;
}
.modal-dark .modal-body {
  border-left: 5px solid #212529;
  border-right: 5px solid #212529;
}
.modal-dark .modal-footer {
  border-top-color: #212529;
  border-left: 5px solid #212529;
  border-right: 5px solid #212529;
  border-bottom: 5px solid #212529;
}
.modal-dark .form-file label,
.modal-dark input {
  border-color: #212529;
}
.modal-dark .input-group-text {
  border-color: #212529;
}
.modal-dark .btn {
  border-color: #212529;
}

notifications .dropdown .fa-regular::before,
notifications .dropdown .dropdown-toggle {
  color: #2C292D;
}
notifications .dropdown .dropdown-menu {
  width: 300px;
  max-height: 235px;
  overflow-y: auto;
}
notifications .dropdown .dropdown-menu .dropdown-item {
  white-space: normal;
  line-break: anywhere;
}
notifications .dropdown .dropdown-toggle .fa-regular::after {
  content: attr(data-counter);
  position: absolute;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: #dc3545;
  border-radius: 50%;
  top: 6px;
  left: 13px;
  color: #ffffff;
  font-family: var(--bs-font-sans-serif);
  width: 23px;
  height: 23px;
  line-height: 23px;
}

.basket-block-content td {
  vertical-align: middle;
}

.number-helper {
  width: 75px;
}
.number-helper input {
  padding: 6px 8px;
}
.number-helper .spinner-btns .btn {
  padding: 1px 0.35rem !important;
  line-height: 0;
  height: 50%;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.number-helper .spinner-btns .btn.btn-primary, .number-helper .spinner-btns app-table-filters form .filters-buttons .btn:first-of-type, app-table-filters form .filters-buttons .number-helper .spinner-btns .btn:first-of-type {
  border-bottom-right-radius: 0;
}
.number-helper .spinner-btns .btn.btn-secondary, .number-helper .spinner-btns app-table-filters form .filters-buttons .btn:last-of-type, app-table-filters form .filters-buttons .number-helper .spinner-btns .btn:last-of-type {
  border-top-right-radius: 0;
}

cart-row .inner {
  position: relative;
  margin-bottom: 1.5rem;
}
cart-row .inner > .row {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
cart-row .inner .btn-toggle {
  font-size: 22px;
  position: absolute;
  top: 0;
  right: 0.5625rem;
  z-index: 200;
}
cart-row .inner .supplier-logo {
  max-width: 120px;
  max-height: 120px;
  display: inline-block;
  vertical-align: baseline;
}
cart-row .inner .head {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
cart-row .inner .head span {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.25rem;
}
cart-row .inner .head .franco i {
  font-size: 1.625rem;
}
cart-row .inner .head .franco span {
  font-size: 0.9375rem;
}
cart-row .inner .head [class*=col] {
  align-self: center;
  text-align: center;
}
cart-row .table-total {
  font-size: 1rem;
  font-weight: bold;
}
cart-row .table-total th {
  width: 100%;
  max-width: 50%;
  background: #E9E9E9;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}
cart-row .supplier-pricing-date {
  display: inline-block;
  margin-top: 0.3125rem;
  padding: 0.3125rem;
  background-color: #fbf005;
  border: 2px #357ebd solid;
}
cart-row [cart-item] .quantity {
  display: flex;
  align-items: center;
}
cart-row [cart-item] td {
  vertical-align: middle;
}

.toast-container {
  position: fixed;
  right: 0;
  margin: 0.5em;
  z-index: 1200;
}

th[sortable] {
  cursor: pointer;
}

th[sortable]::before {
  font-family: "Font Awesome 6 Pro";
  content: "\f0dc";
  margin-right: 0.5rem;
  font-weight: 900;
}

th.asc[sortable]::before {
  content: "\f0de";
}

th.desc[sortable]::before {
  content: "\f0dd";
}

.table > :not(:first-child) {
  border-top: 0;
}

app-table-list .table-responsive {
  overflow: visible;
}

app-table-filters form .filters {
  margin-bottom: -0.5rem;
}

app-table-filters form .filters > * {
  margin-bottom: 0.5rem;
}

table, .table {
  margin-bottom: 0;
}
table thead th, .table thead th {
  position: sticky;
  top: 0;
}
table thead tr:nth-child(2) th, .table thead tr:nth-child(2) th {
  top: 2.5rem;
}
table td, table tr:not(.align-bottom) th, .table td, .table tr:not(.align-bottom) th {
  vertical-align: middle;
}
table tr.border-divider, .table tr.border-divider {
  border-bottom: 2px;
  border-bottom-style: solid;
  border-bottom-color: #2C292D;
}
table .btn-light,
table .btn-light:hover,
table .btn-light:focus,
table .btn-light:not(:disabled):not(.disabled):active, .table .btn-light,
.table .btn-light:hover,
.table .btn-light:focus,
.table .btn-light:not(:disabled):not(.disabled):active {
  padding: 5px 5px;
  margin-right: 5px;
  font-size: initial;
  border-color: transparent;
  background: none;
  color: #959496;
}
table .btn-link, .table .btn-link {
  font-weight: normal;
  font-size: 1rem;
}

.main-nav {
  user-select: none;
}
.main-nav a > [class*=fa-] {
  color: #FF6200;
  font-size: 1.25rem;
}
.main-nav [class*=fa-] {
  vertical-align: middle;
  margin-right: 0.5rem;
  width: 1.3rem;
}
.main-nav .nav-link {
  text-decoration: none;
  padding: 0.25rem 0 0.25rem 1rem;
}

.tox .tox-dialog.tox-dialog--width-lg .tox-form,
.tox .tox-dialog.tox-dialog--width-lg .tox-form .tox-form__group {
  height: 100%;
}

.tox .tox-button:not(:disabled):not(.tox-button--icon):not(.tox-button--secondary),
.tox .tox-button:hover:not(:disabled):not(.tox-button--icon):not(.tox-button--secondary) {
  background-color: #FF6200 !important;
  border-color: #FF6200 !important;
}

.tox .tox-tbtn:hover,
.tox .tox-tbtn--enabled {
  background: #ffc099 !important;
}

.blockquote q + .blockquote-footer {
  margin-top: 0;
}

p.text blockquote {
  border-left: 2px solid #dee2e6;
  margin-left: 1rem;
  padding-left: 1rem;
}

.placeholder-wave > .placeholder {
  display: block;
}

.main-nav {
  background-color: #2C292D;
  box-shadow: 0.125rem 0 0.375rem rgba(44, 41, 45, 0.07);
}
.main-nav h5, .main-nav .h5 {
  margin: 0;
  padding: 0.5rem 1rem;
}
.main-nav h5 a.nav-link, .main-nav .h5 a.nav-link {
  color: #ffffff;
  padding: 0.75rem 1rem;
}
.main-nav .nav {
  border-bottom: 1px solid #373438;
}
.main-nav .nav-link {
  position: relative;
  color: #959496;
  text-decoration: none;
}
.main-nav .nav-link:hover {
  color: #ffffff;
}
.main-nav .nav-link.active {
  background-color: rgba(0, 0, 0, 0.2);
  color: #ffffff;
}
.main-nav .nav-link.active::before {
  transform: scaleY(1);
}
.main-nav .nav-link::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 0.25rem;
  height: 100%;
  background-color: #FF6200;
  transform: scaleY(0);
  transform-origin: bottom center;
  transition: transform 0.5s ease-out;
}
.main-nav .nav-link:hover::before {
  transform: scaleY(1);
  transform-origin: top center;
}