.nav-tabs {
  border-bottom: 0;
  margin-bottom: $spacer * 2;

  .nav-link {
    border: 0;
    border-bottom: $nav-tabs-border-width solid transparent;
    color: $gray-600;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    &:hover, &:focus {
      color: $nav-link-color;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $nav-link-color;
    border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
  }
}
