.tox .tox-dialog.tox-dialog--width-lg .tox-form,
.tox .tox-dialog.tox-dialog--width-lg .tox-form .tox-form__group {
    height: 100%;
}

.tox .tox-button:not(:disabled):not(.tox-button--icon):not(.tox-button--secondary),
.tox .tox-button:hover:not(:disabled):not(.tox-button--icon):not(.tox-button--secondary) {
    background-color: $primary !important;
    border-color: $primary !important;
}

.tox .tox-tbtn:hover,
.tox .tox-tbtn--enabled {
    background: lighten($primary, 30%) !important;
}
