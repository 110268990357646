.card {
  margin-bottom: $spacer * 1.5; // mb-4

  .card-header {
    border-bottom: $border-width solid $card-border-color;

    .fa-regular { font-size: 18px; }

    h5 {
      .fa-regular:last-child {
        transform: rotateZ(-90deg);
        transition: transform .2s;
      }
    }

    &:not(.collapsed) h5 {
      .fa-regular:last-child {
        transform: rotateZ(0deg);
        transition: transform .2s;
      }
    }
  }
}

.rounded-icon-size {
    width: 3.125rem;
    height: 3.125rem;
}

statistics-number {
  .progress { margin-bottom: 5px; }
  .card-body .title small { font-size: $font-size-base; }
  .status {
    font-size: $font-size-xs;
    color: $text-muted;
  }
}
