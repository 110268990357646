@each $color-name, $color-value in $theme-colors {
  statistics-counter .card {
    transition: border-color 0.3s, box-shadow 0.3s ease-out;
  }

  statistics-counter .card.border-#{$color-name} {
    box-shadow: 0 0 0 5px shift-color($color-value, -85%);
  }
}

.striped-rows {
  &> :nth-child(even) .row {
    background-color: $body-bg;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0;
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
}
