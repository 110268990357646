/*!
 * Font Awesome Pro 6.7.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-brands: 'Font Awesome 6 Brands';
  --fa-font-brands: normal 400 1em/1 'Font Awesome 6 Brands'; }

@font-face {
  font-family: 'Font Awesome 6 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../webfonts/fa-brands-400.woff2") format("woff2"), url("../webfonts/fa-brands-400.ttf") format("truetype"); }

.fab,
.fa-brands {
  font-weight: 400; }

.fa-monero {
  --fa: "\f3d0"; }

.fa-hooli {
  --fa: "\f427"; }

.fa-yelp {
  --fa: "\f1e9"; }

.fa-cc-visa {
  --fa: "\f1f0"; }

.fa-lastfm {
  --fa: "\f202"; }

.fa-shopware {
  --fa: "\f5b5"; }

.fa-creative-commons-nc {
  --fa: "\f4e8"; }

.fa-aws {
  --fa: "\f375"; }

.fa-redhat {
  --fa: "\f7bc"; }

.fa-yoast {
  --fa: "\f2b1"; }

.fa-cloudflare {
  --fa: "\e07d"; }

.fa-ups {
  --fa: "\f7e0"; }

.fa-pixiv {
  --fa: "\e640"; }

.fa-wpexplorer {
  --fa: "\f2de"; }

.fa-dyalog {
  --fa: "\f399"; }

.fa-bity {
  --fa: "\f37a"; }

.fa-stackpath {
  --fa: "\f842"; }

.fa-buysellads {
  --fa: "\f20d"; }

.fa-first-order {
  --fa: "\f2b0"; }

.fa-modx {
  --fa: "\f285"; }

.fa-guilded {
  --fa: "\e07e"; }

.fa-vnv {
  --fa: "\f40b"; }

.fa-square-js {
  --fa: "\f3b9"; }

.fa-js-square {
  --fa: "\f3b9"; }

.fa-microsoft {
  --fa: "\f3ca"; }

.fa-qq {
  --fa: "\f1d6"; }

.fa-orcid {
  --fa: "\f8d2"; }

.fa-java {
  --fa: "\f4e4"; }

.fa-invision {
  --fa: "\f7b0"; }

.fa-creative-commons-pd-alt {
  --fa: "\f4ed"; }

.fa-centercode {
  --fa: "\f380"; }

.fa-glide-g {
  --fa: "\f2a6"; }

.fa-drupal {
  --fa: "\f1a9"; }

.fa-jxl {
  --fa: "\e67b"; }

.fa-dart-lang {
  --fa: "\e693"; }

.fa-hire-a-helper {
  --fa: "\f3b0"; }

.fa-creative-commons-by {
  --fa: "\f4e7"; }

.fa-unity {
  --fa: "\e049"; }

.fa-whmcs {
  --fa: "\f40d"; }

.fa-rocketchat {
  --fa: "\f3e8"; }

.fa-vk {
  --fa: "\f189"; }

.fa-untappd {
  --fa: "\f405"; }

.fa-mailchimp {
  --fa: "\f59e"; }

.fa-css3-alt {
  --fa: "\f38b"; }

.fa-square-reddit {
  --fa: "\f1a2"; }

.fa-reddit-square {
  --fa: "\f1a2"; }

.fa-vimeo-v {
  --fa: "\f27d"; }

.fa-contao {
  --fa: "\f26d"; }

.fa-square-font-awesome {
  --fa: "\e5ad"; }

.fa-deskpro {
  --fa: "\f38f"; }

.fa-brave {
  --fa: "\e63c"; }

.fa-sistrix {
  --fa: "\f3ee"; }

.fa-square-instagram {
  --fa: "\e055"; }

.fa-instagram-square {
  --fa: "\e055"; }

.fa-battle-net {
  --fa: "\f835"; }

.fa-the-red-yeti {
  --fa: "\f69d"; }

.fa-square-hacker-news {
  --fa: "\f3af"; }

.fa-hacker-news-square {
  --fa: "\f3af"; }

.fa-edge {
  --fa: "\f282"; }

.fa-threads {
  --fa: "\e618"; }

.fa-napster {
  --fa: "\f3d2"; }

.fa-square-snapchat {
  --fa: "\f2ad"; }

.fa-snapchat-square {
  --fa: "\f2ad"; }

.fa-google-plus-g {
  --fa: "\f0d5"; }

.fa-artstation {
  --fa: "\f77a"; }

.fa-markdown {
  --fa: "\f60f"; }

.fa-sourcetree {
  --fa: "\f7d3"; }

.fa-google-plus {
  --fa: "\f2b3"; }

.fa-diaspora {
  --fa: "\f791"; }

.fa-foursquare {
  --fa: "\f180"; }

.fa-stack-overflow {
  --fa: "\f16c"; }

.fa-github-alt {
  --fa: "\f113"; }

.fa-phoenix-squadron {
  --fa: "\f511"; }

.fa-pagelines {
  --fa: "\f18c"; }

.fa-algolia {
  --fa: "\f36c"; }

.fa-red-river {
  --fa: "\f3e3"; }

.fa-creative-commons-sa {
  --fa: "\f4ef"; }

.fa-safari {
  --fa: "\f267"; }

.fa-google {
  --fa: "\f1a0"; }

.fa-square-font-awesome-stroke {
  --fa: "\f35c"; }

.fa-font-awesome-alt {
  --fa: "\f35c"; }

.fa-atlassian {
  --fa: "\f77b"; }

.fa-linkedin-in {
  --fa: "\f0e1"; }

.fa-digital-ocean {
  --fa: "\f391"; }

.fa-nimblr {
  --fa: "\f5a8"; }

.fa-chromecast {
  --fa: "\f838"; }

.fa-evernote {
  --fa: "\f839"; }

.fa-hacker-news {
  --fa: "\f1d4"; }

.fa-creative-commons-sampling {
  --fa: "\f4f0"; }

.fa-adversal {
  --fa: "\f36a"; }

.fa-creative-commons {
  --fa: "\f25e"; }

.fa-watchman-monitoring {
  --fa: "\e087"; }

.fa-fonticons {
  --fa: "\f280"; }

.fa-weixin {
  --fa: "\f1d7"; }

.fa-shirtsinbulk {
  --fa: "\f214"; }

.fa-codepen {
  --fa: "\f1cb"; }

.fa-git-alt {
  --fa: "\f841"; }

.fa-lyft {
  --fa: "\f3c3"; }

.fa-rev {
  --fa: "\f5b2"; }

.fa-windows {
  --fa: "\f17a"; }

.fa-wizards-of-the-coast {
  --fa: "\f730"; }

.fa-square-viadeo {
  --fa: "\f2aa"; }

.fa-viadeo-square {
  --fa: "\f2aa"; }

.fa-meetup {
  --fa: "\f2e0"; }

.fa-centos {
  --fa: "\f789"; }

.fa-adn {
  --fa: "\f170"; }

.fa-cloudsmith {
  --fa: "\f384"; }

.fa-opensuse {
  --fa: "\e62b"; }

.fa-pied-piper-alt {
  --fa: "\f1a8"; }

.fa-square-dribbble {
  --fa: "\f397"; }

.fa-dribbble-square {
  --fa: "\f397"; }

.fa-codiepie {
  --fa: "\f284"; }

.fa-node {
  --fa: "\f419"; }

.fa-mix {
  --fa: "\f3cb"; }

.fa-steam {
  --fa: "\f1b6"; }

.fa-cc-apple-pay {
  --fa: "\f416"; }

.fa-scribd {
  --fa: "\f28a"; }

.fa-debian {
  --fa: "\e60b"; }

.fa-openid {
  --fa: "\f19b"; }

.fa-instalod {
  --fa: "\e081"; }

.fa-files-pinwheel {
  --fa: "\e69f"; }

.fa-expeditedssl {
  --fa: "\f23e"; }

.fa-sellcast {
  --fa: "\f2da"; }

.fa-square-twitter {
  --fa: "\f081"; }

.fa-twitter-square {
  --fa: "\f081"; }

.fa-r-project {
  --fa: "\f4f7"; }

.fa-delicious {
  --fa: "\f1a5"; }

.fa-freebsd {
  --fa: "\f3a4"; }

.fa-vuejs {
  --fa: "\f41f"; }

.fa-accusoft {
  --fa: "\f369"; }

.fa-ioxhost {
  --fa: "\f208"; }

.fa-fonticons-fi {
  --fa: "\f3a2"; }

.fa-app-store {
  --fa: "\f36f"; }

.fa-cc-mastercard {
  --fa: "\f1f1"; }

.fa-itunes-note {
  --fa: "\f3b5"; }

.fa-golang {
  --fa: "\e40f"; }

.fa-kickstarter {
  --fa: "\f3bb"; }

.fa-square-kickstarter {
  --fa: "\f3bb"; }

.fa-grav {
  --fa: "\f2d6"; }

.fa-weibo {
  --fa: "\f18a"; }

.fa-uncharted {
  --fa: "\e084"; }

.fa-firstdraft {
  --fa: "\f3a1"; }

.fa-square-youtube {
  --fa: "\f431"; }

.fa-youtube-square {
  --fa: "\f431"; }

.fa-wikipedia-w {
  --fa: "\f266"; }

.fa-wpressr {
  --fa: "\f3e4"; }

.fa-rendact {
  --fa: "\f3e4"; }

.fa-angellist {
  --fa: "\f209"; }

.fa-galactic-republic {
  --fa: "\f50c"; }

.fa-nfc-directional {
  --fa: "\e530"; }

.fa-skype {
  --fa: "\f17e"; }

.fa-joget {
  --fa: "\f3b7"; }

.fa-fedora {
  --fa: "\f798"; }

.fa-stripe-s {
  --fa: "\f42a"; }

.fa-meta {
  --fa: "\e49b"; }

.fa-laravel {
  --fa: "\f3bd"; }

.fa-hotjar {
  --fa: "\f3b1"; }

.fa-bluetooth-b {
  --fa: "\f294"; }

.fa-square-letterboxd {
  --fa: "\e62e"; }

.fa-sticker-mule {
  --fa: "\f3f7"; }

.fa-creative-commons-zero {
  --fa: "\f4f3"; }

.fa-hips {
  --fa: "\f452"; }

.fa-css {
  --fa: "\e6a2"; }

.fa-behance {
  --fa: "\f1b4"; }

.fa-reddit {
  --fa: "\f1a1"; }

.fa-discord {
  --fa: "\f392"; }

.fa-chrome {
  --fa: "\f268"; }

.fa-app-store-ios {
  --fa: "\f370"; }

.fa-cc-discover {
  --fa: "\f1f2"; }

.fa-wpbeginner {
  --fa: "\f297"; }

.fa-confluence {
  --fa: "\f78d"; }

.fa-shoelace {
  --fa: "\e60c"; }

.fa-mdb {
  --fa: "\f8ca"; }

.fa-dochub {
  --fa: "\f394"; }

.fa-accessible-icon {
  --fa: "\f368"; }

.fa-ebay {
  --fa: "\f4f4"; }

.fa-amazon {
  --fa: "\f270"; }

.fa-unsplash {
  --fa: "\e07c"; }

.fa-yarn {
  --fa: "\f7e3"; }

.fa-square-steam {
  --fa: "\f1b7"; }

.fa-steam-square {
  --fa: "\f1b7"; }

.fa-500px {
  --fa: "\f26e"; }

.fa-square-vimeo {
  --fa: "\f194"; }

.fa-vimeo-square {
  --fa: "\f194"; }

.fa-asymmetrik {
  --fa: "\f372"; }

.fa-font-awesome {
  --fa: "\f2b4"; }

.fa-font-awesome-flag {
  --fa: "\f2b4"; }

.fa-font-awesome-logo-full {
  --fa: "\f2b4"; }

.fa-gratipay {
  --fa: "\f184"; }

.fa-apple {
  --fa: "\f179"; }

.fa-hive {
  --fa: "\e07f"; }

.fa-gitkraken {
  --fa: "\f3a6"; }

.fa-keybase {
  --fa: "\f4f5"; }

.fa-apple-pay {
  --fa: "\f415"; }

.fa-padlet {
  --fa: "\e4a0"; }

.fa-amazon-pay {
  --fa: "\f42c"; }

.fa-square-github {
  --fa: "\f092"; }

.fa-github-square {
  --fa: "\f092"; }

.fa-stumbleupon {
  --fa: "\f1a4"; }

.fa-fedex {
  --fa: "\f797"; }

.fa-phoenix-framework {
  --fa: "\f3dc"; }

.fa-shopify {
  --fa: "\e057"; }

.fa-neos {
  --fa: "\f612"; }

.fa-square-threads {
  --fa: "\e619"; }

.fa-hackerrank {
  --fa: "\f5f7"; }

.fa-researchgate {
  --fa: "\f4f8"; }

.fa-swift {
  --fa: "\f8e1"; }

.fa-angular {
  --fa: "\f420"; }

.fa-speakap {
  --fa: "\f3f3"; }

.fa-angrycreative {
  --fa: "\f36e"; }

.fa-y-combinator {
  --fa: "\f23b"; }

.fa-empire {
  --fa: "\f1d1"; }

.fa-envira {
  --fa: "\f299"; }

.fa-google-scholar {
  --fa: "\e63b"; }

.fa-square-gitlab {
  --fa: "\e5ae"; }

.fa-gitlab-square {
  --fa: "\e5ae"; }

.fa-studiovinari {
  --fa: "\f3f8"; }

.fa-pied-piper {
  --fa: "\f2ae"; }

.fa-wordpress {
  --fa: "\f19a"; }

.fa-product-hunt {
  --fa: "\f288"; }

.fa-firefox {
  --fa: "\f269"; }

.fa-linode {
  --fa: "\f2b8"; }

.fa-goodreads {
  --fa: "\f3a8"; }

.fa-square-odnoklassniki {
  --fa: "\f264"; }

.fa-odnoklassniki-square {
  --fa: "\f264"; }

.fa-jsfiddle {
  --fa: "\f1cc"; }

.fa-sith {
  --fa: "\f512"; }

.fa-themeisle {
  --fa: "\f2b2"; }

.fa-page4 {
  --fa: "\f3d7"; }

.fa-hashnode {
  --fa: "\e499"; }

.fa-react {
  --fa: "\f41b"; }

.fa-cc-paypal {
  --fa: "\f1f4"; }

.fa-squarespace {
  --fa: "\f5be"; }

.fa-cc-stripe {
  --fa: "\f1f5"; }

.fa-creative-commons-share {
  --fa: "\f4f2"; }

.fa-bitcoin {
  --fa: "\f379"; }

.fa-keycdn {
  --fa: "\f3ba"; }

.fa-opera {
  --fa: "\f26a"; }

.fa-itch-io {
  --fa: "\f83a"; }

.fa-umbraco {
  --fa: "\f8e8"; }

.fa-galactic-senate {
  --fa: "\f50d"; }

.fa-ubuntu {
  --fa: "\f7df"; }

.fa-draft2digital {
  --fa: "\f396"; }

.fa-stripe {
  --fa: "\f429"; }

.fa-houzz {
  --fa: "\f27c"; }

.fa-gg {
  --fa: "\f260"; }

.fa-dhl {
  --fa: "\f790"; }

.fa-square-pinterest {
  --fa: "\f0d3"; }

.fa-pinterest-square {
  --fa: "\f0d3"; }

.fa-xing {
  --fa: "\f168"; }

.fa-blackberry {
  --fa: "\f37b"; }

.fa-creative-commons-pd {
  --fa: "\f4ec"; }

.fa-playstation {
  --fa: "\f3df"; }

.fa-quinscape {
  --fa: "\f459"; }

.fa-less {
  --fa: "\f41d"; }

.fa-blogger-b {
  --fa: "\f37d"; }

.fa-opencart {
  --fa: "\f23d"; }

.fa-vine {
  --fa: "\f1ca"; }

.fa-signal-messenger {
  --fa: "\e663"; }

.fa-paypal {
  --fa: "\f1ed"; }

.fa-gitlab {
  --fa: "\f296"; }

.fa-typo3 {
  --fa: "\f42b"; }

.fa-reddit-alien {
  --fa: "\f281"; }

.fa-yahoo {
  --fa: "\f19e"; }

.fa-dailymotion {
  --fa: "\e052"; }

.fa-affiliatetheme {
  --fa: "\f36b"; }

.fa-pied-piper-pp {
  --fa: "\f1a7"; }

.fa-bootstrap {
  --fa: "\f836"; }

.fa-odnoklassniki {
  --fa: "\f263"; }

.fa-nfc-symbol {
  --fa: "\e531"; }

.fa-mintbit {
  --fa: "\e62f"; }

.fa-ethereum {
  --fa: "\f42e"; }

.fa-speaker-deck {
  --fa: "\f83c"; }

.fa-creative-commons-nc-eu {
  --fa: "\f4e9"; }

.fa-patreon {
  --fa: "\f3d9"; }

.fa-avianex {
  --fa: "\f374"; }

.fa-ello {
  --fa: "\f5f1"; }

.fa-gofore {
  --fa: "\f3a7"; }

.fa-bimobject {
  --fa: "\f378"; }

.fa-brave-reverse {
  --fa: "\e63d"; }

.fa-facebook-f {
  --fa: "\f39e"; }

.fa-square-google-plus {
  --fa: "\f0d4"; }

.fa-google-plus-square {
  --fa: "\f0d4"; }

.fa-web-awesome {
  --fa: "\e682"; }

.fa-mandalorian {
  --fa: "\f50f"; }

.fa-first-order-alt {
  --fa: "\f50a"; }

.fa-osi {
  --fa: "\f41a"; }

.fa-google-wallet {
  --fa: "\f1ee"; }

.fa-d-and-d-beyond {
  --fa: "\f6ca"; }

.fa-periscope {
  --fa: "\f3da"; }

.fa-fulcrum {
  --fa: "\f50b"; }

.fa-cloudscale {
  --fa: "\f383"; }

.fa-forumbee {
  --fa: "\f211"; }

.fa-mizuni {
  --fa: "\f3cc"; }

.fa-schlix {
  --fa: "\f3ea"; }

.fa-square-xing {
  --fa: "\f169"; }

.fa-xing-square {
  --fa: "\f169"; }

.fa-bandcamp {
  --fa: "\f2d5"; }

.fa-wpforms {
  --fa: "\f298"; }

.fa-cloudversify {
  --fa: "\f385"; }

.fa-usps {
  --fa: "\f7e1"; }

.fa-megaport {
  --fa: "\f5a3"; }

.fa-magento {
  --fa: "\f3c4"; }

.fa-spotify {
  --fa: "\f1bc"; }

.fa-optin-monster {
  --fa: "\f23c"; }

.fa-fly {
  --fa: "\f417"; }

.fa-square-bluesky {
  --fa: "\e6a3"; }

.fa-aviato {
  --fa: "\f421"; }

.fa-itunes {
  --fa: "\f3b4"; }

.fa-cuttlefish {
  --fa: "\f38c"; }

.fa-blogger {
  --fa: "\f37c"; }

.fa-flickr {
  --fa: "\f16e"; }

.fa-viber {
  --fa: "\f409"; }

.fa-soundcloud {
  --fa: "\f1be"; }

.fa-digg {
  --fa: "\f1a6"; }

.fa-tencent-weibo {
  --fa: "\f1d5"; }

.fa-letterboxd {
  --fa: "\e62d"; }

.fa-symfony {
  --fa: "\f83d"; }

.fa-maxcdn {
  --fa: "\f136"; }

.fa-etsy {
  --fa: "\f2d7"; }

.fa-facebook-messenger {
  --fa: "\f39f"; }

.fa-audible {
  --fa: "\f373"; }

.fa-think-peaks {
  --fa: "\f731"; }

.fa-bilibili {
  --fa: "\e3d9"; }

.fa-erlang {
  --fa: "\f39d"; }

.fa-x-twitter {
  --fa: "\e61b"; }

.fa-cotton-bureau {
  --fa: "\f89e"; }

.fa-dashcube {
  --fa: "\f210"; }

.fa-42-group {
  --fa: "\e080"; }

.fa-innosoft {
  --fa: "\e080"; }

.fa-stack-exchange {
  --fa: "\f18d"; }

.fa-elementor {
  --fa: "\f430"; }

.fa-square-pied-piper {
  --fa: "\e01e"; }

.fa-pied-piper-square {
  --fa: "\e01e"; }

.fa-creative-commons-nd {
  --fa: "\f4eb"; }

.fa-palfed {
  --fa: "\f3d8"; }

.fa-superpowers {
  --fa: "\f2dd"; }

.fa-resolving {
  --fa: "\f3e7"; }

.fa-xbox {
  --fa: "\f412"; }

.fa-square-web-awesome-stroke {
  --fa: "\e684"; }

.fa-searchengin {
  --fa: "\f3eb"; }

.fa-tiktok {
  --fa: "\e07b"; }

.fa-square-facebook {
  --fa: "\f082"; }

.fa-facebook-square {
  --fa: "\f082"; }

.fa-renren {
  --fa: "\f18b"; }

.fa-linux {
  --fa: "\f17c"; }

.fa-glide {
  --fa: "\f2a5"; }

.fa-linkedin {
  --fa: "\f08c"; }

.fa-hubspot {
  --fa: "\f3b2"; }

.fa-deploydog {
  --fa: "\f38e"; }

.fa-twitch {
  --fa: "\f1e8"; }

.fa-flutter {
  --fa: "\e694"; }

.fa-ravelry {
  --fa: "\f2d9"; }

.fa-mixer {
  --fa: "\e056"; }

.fa-square-lastfm {
  --fa: "\f203"; }

.fa-lastfm-square {
  --fa: "\f203"; }

.fa-vimeo {
  --fa: "\f40a"; }

.fa-mendeley {
  --fa: "\f7b3"; }

.fa-uniregistry {
  --fa: "\f404"; }

.fa-figma {
  --fa: "\f799"; }

.fa-creative-commons-remix {
  --fa: "\f4ee"; }

.fa-cc-amazon-pay {
  --fa: "\f42d"; }

.fa-dropbox {
  --fa: "\f16b"; }

.fa-instagram {
  --fa: "\f16d"; }

.fa-cmplid {
  --fa: "\e360"; }

.fa-upwork {
  --fa: "\e641"; }

.fa-facebook {
  --fa: "\f09a"; }

.fa-gripfire {
  --fa: "\f3ac"; }

.fa-jedi-order {
  --fa: "\f50e"; }

.fa-uikit {
  --fa: "\f403"; }

.fa-fort-awesome-alt {
  --fa: "\f3a3"; }

.fa-phabricator {
  --fa: "\f3db"; }

.fa-ussunnah {
  --fa: "\f407"; }

.fa-earlybirds {
  --fa: "\f39a"; }

.fa-trade-federation {
  --fa: "\f513"; }

.fa-autoprefixer {
  --fa: "\f41c"; }

.fa-whatsapp {
  --fa: "\f232"; }

.fa-square-upwork {
  --fa: "\e67c"; }

.fa-slideshare {
  --fa: "\f1e7"; }

.fa-google-play {
  --fa: "\f3ab"; }

.fa-viadeo {
  --fa: "\f2a9"; }

.fa-line {
  --fa: "\f3c0"; }

.fa-google-drive {
  --fa: "\f3aa"; }

.fa-servicestack {
  --fa: "\f3ec"; }

.fa-simplybuilt {
  --fa: "\f215"; }

.fa-bitbucket {
  --fa: "\f171"; }

.fa-imdb {
  --fa: "\f2d8"; }

.fa-deezer {
  --fa: "\e077"; }

.fa-raspberry-pi {
  --fa: "\f7bb"; }

.fa-jira {
  --fa: "\f7b1"; }

.fa-docker {
  --fa: "\f395"; }

.fa-screenpal {
  --fa: "\e570"; }

.fa-bluetooth {
  --fa: "\f293"; }

.fa-gitter {
  --fa: "\f426"; }

.fa-d-and-d {
  --fa: "\f38d"; }

.fa-microblog {
  --fa: "\e01a"; }

.fa-cc-diners-club {
  --fa: "\f24c"; }

.fa-gg-circle {
  --fa: "\f261"; }

.fa-pied-piper-hat {
  --fa: "\f4e5"; }

.fa-kickstarter-k {
  --fa: "\f3bc"; }

.fa-yandex {
  --fa: "\f413"; }

.fa-readme {
  --fa: "\f4d5"; }

.fa-html5 {
  --fa: "\f13b"; }

.fa-sellsy {
  --fa: "\f213"; }

.fa-square-web-awesome {
  --fa: "\e683"; }

.fa-sass {
  --fa: "\f41e"; }

.fa-wirsindhandwerk {
  --fa: "\e2d0"; }

.fa-wsh {
  --fa: "\e2d0"; }

.fa-buromobelexperte {
  --fa: "\f37f"; }

.fa-salesforce {
  --fa: "\f83b"; }

.fa-octopus-deploy {
  --fa: "\e082"; }

.fa-medapps {
  --fa: "\f3c6"; }

.fa-ns8 {
  --fa: "\f3d5"; }

.fa-pinterest-p {
  --fa: "\f231"; }

.fa-apper {
  --fa: "\f371"; }

.fa-fort-awesome {
  --fa: "\f286"; }

.fa-waze {
  --fa: "\f83f"; }

.fa-bluesky {
  --fa: "\e671"; }

.fa-cc-jcb {
  --fa: "\f24b"; }

.fa-snapchat {
  --fa: "\f2ab"; }

.fa-snapchat-ghost {
  --fa: "\f2ab"; }

.fa-fantasy-flight-games {
  --fa: "\f6dc"; }

.fa-rust {
  --fa: "\e07a"; }

.fa-wix {
  --fa: "\f5cf"; }

.fa-square-behance {
  --fa: "\f1b5"; }

.fa-behance-square {
  --fa: "\f1b5"; }

.fa-supple {
  --fa: "\f3f9"; }

.fa-webflow {
  --fa: "\e65c"; }

.fa-rebel {
  --fa: "\f1d0"; }

.fa-css3 {
  --fa: "\f13c"; }

.fa-staylinked {
  --fa: "\f3f5"; }

.fa-kaggle {
  --fa: "\f5fa"; }

.fa-space-awesome {
  --fa: "\e5ac"; }

.fa-deviantart {
  --fa: "\f1bd"; }

.fa-cpanel {
  --fa: "\f388"; }

.fa-goodreads-g {
  --fa: "\f3a9"; }

.fa-square-git {
  --fa: "\f1d2"; }

.fa-git-square {
  --fa: "\f1d2"; }

.fa-square-tumblr {
  --fa: "\f174"; }

.fa-tumblr-square {
  --fa: "\f174"; }

.fa-trello {
  --fa: "\f181"; }

.fa-creative-commons-nc-jp {
  --fa: "\f4ea"; }

.fa-get-pocket {
  --fa: "\f265"; }

.fa-perbyte {
  --fa: "\e083"; }

.fa-grunt {
  --fa: "\f3ad"; }

.fa-weebly {
  --fa: "\f5cc"; }

.fa-connectdevelop {
  --fa: "\f20e"; }

.fa-leanpub {
  --fa: "\f212"; }

.fa-black-tie {
  --fa: "\f27e"; }

.fa-themeco {
  --fa: "\f5c6"; }

.fa-python {
  --fa: "\f3e2"; }

.fa-android {
  --fa: "\f17b"; }

.fa-bots {
  --fa: "\e340"; }

.fa-free-code-camp {
  --fa: "\f2c5"; }

.fa-hornbill {
  --fa: "\f592"; }

.fa-js {
  --fa: "\f3b8"; }

.fa-ideal {
  --fa: "\e013"; }

.fa-git {
  --fa: "\f1d3"; }

.fa-dev {
  --fa: "\f6cc"; }

.fa-sketch {
  --fa: "\f7c6"; }

.fa-yandex-international {
  --fa: "\f414"; }

.fa-cc-amex {
  --fa: "\f1f3"; }

.fa-uber {
  --fa: "\f402"; }

.fa-github {
  --fa: "\f09b"; }

.fa-php {
  --fa: "\f457"; }

.fa-alipay {
  --fa: "\f642"; }

.fa-youtube {
  --fa: "\f167"; }

.fa-skyatlas {
  --fa: "\f216"; }

.fa-firefox-browser {
  --fa: "\e007"; }

.fa-replyd {
  --fa: "\f3e6"; }

.fa-suse {
  --fa: "\f7d6"; }

.fa-jenkins {
  --fa: "\f3b6"; }

.fa-twitter {
  --fa: "\f099"; }

.fa-rockrms {
  --fa: "\f3e9"; }

.fa-pinterest {
  --fa: "\f0d2"; }

.fa-buffer {
  --fa: "\f837"; }

.fa-npm {
  --fa: "\f3d4"; }

.fa-yammer {
  --fa: "\f840"; }

.fa-btc {
  --fa: "\f15a"; }

.fa-dribbble {
  --fa: "\f17d"; }

.fa-stumbleupon-circle {
  --fa: "\f1a3"; }

.fa-internet-explorer {
  --fa: "\f26b"; }

.fa-stubber {
  --fa: "\e5c7"; }

.fa-telegram {
  --fa: "\f2c6"; }

.fa-telegram-plane {
  --fa: "\f2c6"; }

.fa-old-republic {
  --fa: "\f510"; }

.fa-odysee {
  --fa: "\e5c6"; }

.fa-square-whatsapp {
  --fa: "\f40c"; }

.fa-whatsapp-square {
  --fa: "\f40c"; }

.fa-node-js {
  --fa: "\f3d3"; }

.fa-edge-legacy {
  --fa: "\e078"; }

.fa-slack {
  --fa: "\f198"; }

.fa-slack-hash {
  --fa: "\f198"; }

.fa-medrt {
  --fa: "\f3c8"; }

.fa-usb {
  --fa: "\f287"; }

.fa-tumblr {
  --fa: "\f173"; }

.fa-vaadin {
  --fa: "\f408"; }

.fa-quora {
  --fa: "\f2c4"; }

.fa-square-x-twitter {
  --fa: "\e61a"; }

.fa-reacteurope {
  --fa: "\f75d"; }

.fa-medium {
  --fa: "\f23a"; }

.fa-medium-m {
  --fa: "\f23a"; }

.fa-amilia {
  --fa: "\f36d"; }

.fa-mixcloud {
  --fa: "\f289"; }

.fa-flipboard {
  --fa: "\f44d"; }

.fa-viacoin {
  --fa: "\f237"; }

.fa-critical-role {
  --fa: "\f6c9"; }

.fa-sitrox {
  --fa: "\e44a"; }

.fa-discourse {
  --fa: "\f393"; }

.fa-joomla {
  --fa: "\f1aa"; }

.fa-mastodon {
  --fa: "\f4f6"; }

.fa-airbnb {
  --fa: "\f834"; }

.fa-wolf-pack-battalion {
  --fa: "\f514"; }

.fa-buy-n-large {
  --fa: "\f8a6"; }

.fa-gulp {
  --fa: "\f3ae"; }

.fa-creative-commons-sampling-plus {
  --fa: "\f4f1"; }

.fa-strava {
  --fa: "\f428"; }

.fa-ember {
  --fa: "\f423"; }

.fa-canadian-maple-leaf {
  --fa: "\f785"; }

.fa-teamspeak {
  --fa: "\f4f9"; }

.fa-pushed {
  --fa: "\f3e1"; }

.fa-wordpress-simple {
  --fa: "\f411"; }

.fa-nutritionix {
  --fa: "\f3d6"; }

.fa-wodu {
  --fa: "\e088"; }

.fa-google-pay {
  --fa: "\e079"; }

.fa-intercom {
  --fa: "\f7af"; }

.fa-zhihu {
  --fa: "\f63f"; }

.fa-korvue {
  --fa: "\f42f"; }

.fa-pix {
  --fa: "\e43a"; }

.fa-steam-symbol {
  --fa: "\f3f6"; }
