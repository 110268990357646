th[sortable] { cursor: pointer; }
th[sortable]::before {
  font-family: 'Font Awesome 6 Pro';
  content: '\F0DC';
  margin-right: $spacer * 0.5;
  font-weight: 900;
}
th.asc[sortable]::before { content: '\F0DE'; }
th.desc[sortable]::before { content: '\F0DD'; }

.table > :not(:first-child) { border-top: 0; }

app-table-list .table-responsive { overflow: visible; }

app-table-filters form .filters { margin-bottom: -0.5rem; }
app-table-filters form .filters > * { margin-bottom: 0.5rem; }

table, .table {
  margin-bottom: 0;

  thead th { position: sticky; top: 0; }
  thead tr:nth-child(2) th { top: 2 * $table-cell-padding + $line-height-base; }

  td, tr:not(.align-bottom) th { vertical-align: middle; }
  tr.border-divider {
      border-bottom: 2px;
      border-bottom-style: solid;
      border-bottom-color: $black;
  }

  .btn-light,
  .btn-light:hover,
  .btn-light:focus,
  .btn-light:not(:disabled):not(.disabled):active {
    padding: 5px 5px;
    margin-right: 5px;
    font-size: initial; // FontAwesome
    border-color: transparent;
    background: none;
    color: $gray-600;
  }

  .btn-link {
    font-weight: normal;
    font-size: $input-btn-font-size;
  }
}
