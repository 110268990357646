.basket-block-content {
    td {
      vertical-align: middle;
    }
}

.number-helper {
  width: 75px;

  input { padding: 6px 8px; }

  .spinner-btns {
    .btn {
      padding: 1px .35rem !important;
      line-height: 0;
      height: 50%;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;

      &.btn-primary { border-bottom-right-radius: 0; }
      &.btn-secondary { border-top-right-radius: 0; }
    }
  }
}

cart-row {
  .inner {
    position: relative;
    margin-bottom: 1.5rem;

    &> .row {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }

    .btn-toggle {
      font-size: 22px;
      position: absolute;
      top: 0;
      right: 0.5625rem;
      z-index: 200;
    }
    .supplier-logo {
      max-width: 120px;
      max-height: 120px;
      display: inline-block;
      vertical-align: baseline;
    }
    .head {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      span {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.25rem;
      }
      .franco {
        i {
          //color: $color-primary;
          font-size: 1.625rem;
        }
        span {
          font-size: 0.9375rem;
        }
      }
      [class*="col"] {
        align-self: center;
        text-align: center;
      }
    }
  }

  .table-total {
    //border-top: 3px solid $blue-dark;
    font-size: 1rem;
    font-weight: bold;

    th {
      width: 100%;
      max-width: 50%;
      background: $gray-200;
      border-top: 1px solid white;
      border-bottom: 1px solid white;
    }
  }

  .supplier-pricing-date {
    display: inline-block;
    margin-top: 0.3125rem;
    padding: 0.3125rem;
    background-color: #fbf005;
    border: 2px #357ebd solid;
  }

  [cart-item] {
    .quantity {
      display: flex;
      align-items: center;
    }
    td {
      vertical-align: middle;
    }
  }
}
