notifications .dropdown {
  .fa-regular::before,
  .dropdown-toggle {
    color: $body-color;
  }

  .dropdown-menu {
    width: 300px;
    max-height: 235px;
    overflow-y: auto;

    .dropdown-item {
      white-space: normal;
      line-break: anywhere;
    }
  }

  .dropdown-toggle .fa-regular::after {
    content: attr(data-counter);
    position: absolute;
    font-size: $font-size-sm;
    font-weight: bold;
    background-color: $danger;
    border-radius: 50%;
    top: 6px;
    left: 13px;
    color: $white;
    font-family: $font-family-base;
    width: 23px;
    height: 23px;
    line-height: 23px;
  }
}
