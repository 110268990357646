@import 'bootstrap/scss/functions';

// All `!default` suffixed variables have the same value as in the original
// _variables.scss and are here to be able to build the theme.

// Color system
$white:                              #ffffff !default;
$gray-100:                           #F5F5F5;
$gray-200:                           #E9E9E9;
$gray-600:                           #959496;
$gray-500:                           #adb5bd !default;
$gray-800:                           #343a40;
$black:                              #2C292D;

$indigo:                             #2d3f65;
$purple:                             #B45396;
$orange:                             #FF6200;
$yellow:                             #F09100;
$green:                              #5DB12A;
$red:                                #dc3545 !default;
$teal:                               #00a693;
$cyan:                               #30A6B1;

$primary:                            $orange;
$secondary:                          $gray-500;

$primary-bg-subtle:                  tint-color($primary, 85%);
$secondary-bg-subtle:                tint-color($secondary, 85%);
$success-bg-subtle:                  tint-color($green, 85%);
$info-bg-subtle:                     tint-color($teal, 85%);
$warning-bg-subtle:                  tint-color($yellow, 85%);
$danger-bg-subtle:                   tint-color($red, 85%);

$min-contrast-ratio:                 2.39;
$color-contrast-dark:                $gray-600;

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3),
  6: ($spacer * 3.2),
  7: ($spacer * 4),
  8: ($spacer * 4.5),
);

$body-bg:                            $gray-100;
$body-color:                         $black;

$link-hover-color:                   $primary;
$link-decoration:                    none;

$border-radius:                     .5rem;
$border-radius-lg:                  .6rem;
$border-radius-sm:                  .3rem;

$box-shadow-sm:                     0 .003125rem .09375rem rgba($black, .075);
$box-shadow:                        0 .125rem .375rem rgba($black, .07);
$box-shadow-lg:                     0 .25rem 1.125rem rgba($black, .175);
$box-shadow-right:                  .125rem 0 .375rem rgba($black, .07);

// Typography
$font-family-sans-serif:            "Source Sans Pro", sans-serif;
$font-size-base:                    1rem !default;
$font-size-xs:                      $font-size-base * .70;
$font-size-lg:                      $font-size-base * 1.25 !default;
$font-weight-bold:                  700 !default;
$h1-font-size:                      $font-size-base * 1.875;
$h2-font-size:                      $font-size-base * 1.5625;
$h3-font-size:                      $font-size-base * 1.25;
$h4-font-size:                      $font-size-base * 1.125;
$h5-font-size:                      $font-size-base;
$h6-font-size:                      $font-size-base * .9;
$headings-margin-bottom:            $spacer * 1.25;
$headings-font-weight:              $font-weight-bold;

// Tables
$table-cell-padding:                .5rem;
$table-cell-padding-sm:             .25rem;
$table-dark-color:                  $body-color;
$table-dark-bg:                     $gray-200;
$table-dark-accent-bg:              rgba($table-dark-bg, .05);
$table-dark-hover-bg:               rgba($table-dark-bg, .075);
$table-striped-order:               even;
$table-bg:                          $white;
$table-bg-scale:                    -70%;

// Inputs
$input-bg:                          $white;
$input-btn-padding-y:               .6875rem;
$input-btn-padding-x:               1.25rem;
$input-btn-focus-box-shadow:        none;
$input-btn-padding-y-sm:            .315rem;
$input-btn-padding-x-sm:            1.25rem;
$input-btn-padding-y-lg:            .63rem;
$input-btn-padding-x-lg:            2.5rem;

// Forms
$input-disabled-bg:                 $white;

// Navs
$nav-link-padding-y:                .5rem !default;
$nav-link-padding-x:                1rem !default;

$nav-tabs-border-color:             $primary;
$nav-tabs-border-width:             2px;
$nav-tabs-border-radius:            0;

// Navbar
$navbar-padding-y:                  $spacer * 0.5;
$navbar-padding-x:                  null;

// Dropdowns
$dropdown-bg:                       $white;
$dropdown-box-shadow:               $box-shadow;
$dropdown-link-hover-bg:            $gray-100;
$dropdown-link-disabled-color:      $gray-600;

// Pagination
$pagination-padding-y:              .375rem;
$pagination-hover-color:            $gray-600;
$pagination-hover-bg:               lighten($primary, 42);
$pagination-focus-color:            $pagination-hover-color;
$pagination-focus-bg:               $pagination-hover-bg;
$pagination-bg:                     var(--bs-white);
$pagination-disabled-bg:            var(--bs-white);

// Cards
$card-spacer-x:                     1.4375rem;
$card-border-width:                 0;
$card-cap-bg:                       $white;
$card-bg:                           $white;
$card-inner-border-radius:          $border-radius;

// Toasts
$toast-color:                       $white;
$toast-background-color:            inherit;
$toast-border-width:                0;
$toast-header-color:                $white;
$toast-header-background-color:     inherit;
$toast-header-border-color:         rgba($white, .25);

// Badges
$badge-padding-y:                   .3125rem;
$badge-padding-x:                   .625rem;
$badge-border-radius:               1rem;

// Modals
$modal-content-bg:                  $white;
$modal-content-box-shadow-xs:       $box-shadow-sm;
$modal-content-box-shadow-sm-up:    $box-shadow;

// Breadcrumbs
$breadcrumb-bg:      none;
$breadcrumb-divider: quote('\f054');

// Breakpoints
$xs:            0 !default;
$sm:            576px !default;
$md:            768px !default;
$lg:            992px !default;
$xl:            1200px !default;
$xxl:           1400px !default;
$grid-breakpoints: (
    xs: $xs,
    sm: $sm,
    md: $md,
    lg: $lg,
    xl: $xl,
    xxl: $xxl
);

// Columns
$col-1:         8.333333% !default;
$col-2:         16.666667% !default;
$col-3:         25% !default;
$col-4:         33.333333% !default;
$col-5:         41.666667% !default;
$col-6:         50% !default;
$col-7:         58.333333% !default;
$col-8:         66.666667% !default;
$col-9:         75% !default;
$col-10:        83.333333% !default;
$col-11:        91.666667% !default;
$col-12:        100% !default;
