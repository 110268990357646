legend { float: none; }
label { font-weight: $font-weight-bold; }
label .required { color: $red; font-weight: $font-weight-bold; }

.form-check-list { columns: 2; }
.form-control-plaintext:focus { border-color: transparent; }
.form-control-plaintext ~ .input-group-text {
  background-color: transparent;
  border-color: transparent;
}
.form-control[readonly] { background-color: transparent; }
.form-control[type="file"]:disabled,
.form-control[type="file"][readonly] {
    opacity: 0;
}
ngb-timepicker input[readonly], ngb-timepicker input[readonly]:focus { border-color: transparent; }

input-number.fix-append .input-group-text {
    width: 6.2rem;
    overflow-wrap: anywhere;
    white-space: initial;
    line-height: 1;
    padding-top: .2rem;
    padding-bottom: .2rem;
    justify-content: center;
}
