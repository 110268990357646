.blockquote {
    q + .blockquote-footer {
        margin-top: 0;
    }
}

p.text {
    blockquote {
        border-left: 2px solid $gray-300;
        margin-left: 1rem;
        padding-left: 1rem;
    }
}
