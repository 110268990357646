.main-nav {
    background-color: $black;
    box-shadow: $box-shadow-right;

    h5 {
        margin: 0;
        padding: $nav-link-padding-y $nav-link-padding-x;
        a.nav-link { color: $white; padding: .75rem 1rem; }
    }

    .nav {
        border-bottom: 1px solid #373438;

        &-link {
            position: relative;
            color: $gray-600;
            text-decoration: none;

            &:hover { color: $white; }

            &.active{
                background-color: rgba(0, 0, 0, .2);
                color: $white;

                &::before { transform: scaleY(1); }
            }

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                display: block;
                width: 0.25rem;
                height: 100%;
                background-color: $primary;
                transform: scaleY(0);
                transform-origin: bottom center;
                transition: transform 0.5s ease-out;
            }
            &:hover::before {
                transform: scaleY(1);
                transform-origin: top center;
            }
        }
    }
}
