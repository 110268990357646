.main-nav {
  user-select: none;

  a > [class*="fa-"] {
      color: $primary;
      font-size: $font-size-lg;
  }
  [class*="fa-"] {
    vertical-align: middle;
    margin-right: 0.5rem;
    width: 1.3rem;
  }

  .nav {
    &-link {
        text-decoration: none;
        padding: .25rem 0 .25rem 1rem;
    }
  }
}
