html, body { height: 100%; }
a:not(.btn) { outline: none; }
.rotate-45 { transform: rotateZ(45deg); }
.no-transform { transform: none; }
table th.min, table td.min { width: 1%; white-space: nowrap; }
.cursor-pointer { cursor: pointer; }
.img-16x9 {
    position: relative;
    width: 100%;
    padding-top: 56%;
    background-color: rgba(grey, 0.1);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    overflow: hidden;
}
