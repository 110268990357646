.loader-backdrop {
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1050;
  background-color: $black;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1050;
}

.loader-content {
  background-color: $modal-content-bg;
  border: $modal-content-border-width solid $modal-content-border-color;
  border-radius: $modal-content-border-radius;
}
